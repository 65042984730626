export const TABSET_SINGLE_ASSET: any = {
  digitalSupplyChain: {
    vulnerabilities: {
      title: 'Vulnerabilities',
      key: 'vulnerabilities',
      link: '/pages/singleAsset',
      permissionOpen: true,
      active: false,
      showSummary: false,
      typeTab: 'grid',
      columnPickerPath: 'discovery/digital-supply-chain/vulnerabilities/columns/'
      // tabPath: PathNames.vulnerabilitiesSummary
    },
    tests: {
      title: 'Tests',
      key: 'tests',
      link: '/pages/singleAsset',
      permissionOpen: true,
      active: false,
      showSummary: false,
      typeTab: 'grid',
      columnPickerPath: 'discovery/digital-supply-chain/tests/columns/'
      // tabPath: PathNames.assessmentsSummary
    },
    connections: {
      title: 'Connections',
      key: 'connections',
      link: '/pages/singleAsset',
      permissionOpen: true,
      active: false,
      showSummary: true,
      typeTab: 'grid',
      columnPickerPath: 'discovery/digital-supply-chain/connections/columns/'
      // tabPath: PathNames.connectionsSummary
    },
    technologies: {
      title: 'Technologies',
      key: 'technologies',
      link: '/pages/singleAsset',
      permissionOpen: true,
      active: false,
      showSummary: false,
      typeTab: 'technologies',
      columnPickerPath: 'discovery/digital-supply-chain/technologies/columns/'
      // tabPath: PathNames.applicationsSummary
    }
  },
  internalAssets: {
    actionItems: {
      title: 'Action Items',
      key: 'action-items',
      link: '/pages/singleAsset',
      permissionOpen: true,
      active: false,
      showSummary: false,
      typeTab: 'actionItems',
      columnPickerPath: 'discovery/org-assets/action-items/columns/'
      // tabPath: PathNames.actionItemsSummary,
    },
    tests: {
      title: 'Tests',
      key: 'tests',
      link: '/pages/singleAsset',
      permissionOpen: true,
      active: false,
      showSummary: false,
      typeTab: 'grid',
      columnPickerPath: 'discovery/org-assets/tests/columns/'
      // tabPath: PathNames.assessmentsSummary
    },
    connections: {
      title: 'Connections',
      key: 'connections',
      link: '/pages/singleAsset',
      permissionOpen: true,
      active: false,
      showSummary: true,
      typeTab: 'grid',
      columnPickerPath: 'discovery/org-assets/connections/columns/'
      // tabPath: PathNames.connectionsSummary
    },
    technologies: {
      title: 'Technologies',
      key: 'technologies',
      link: '/pages/singleAsset',
      permissionOpen: true,
      active: false,
      showSummary: false,
      typeTab: 'technologies',
      columnPickerPath: 'discovery/org-assets/technologies/columns/'
      // tabPath: PathNames.applicationsSummary
    },
    evidence: {
      title: 'Discovery Evidence',
      key: 'evidence',
      link: '/pages/singleAsset',
      permissionOpen: true,
      active: false,
      showSummary: true,
      typeTab: 'grid',
      columnPickerPath: 'discovery/org-assets/%ASSET%/evidence/columns/',
      showConditionaly: true
    },
    protocols: {
      title: 'Protocols',
      key: 'protocols',
      link: '/pages/singleAsset',
      permissionOpen: true,
      active: false,
      showSummary: false,
      typeTab: 'grid',
      columnPickerPath: 'discovery/org-assets/%ASSET%/protocols/columns/',
      featureFlag: 'isProtocolTab'
    },
    graph: {
      title: 'Attack Surface Graph',
      key: 'graph',
      link: '/pages/singleAsset',
      permissionOpen: true,
      active: false,
      showSummary: false,
      typeTab: 'graph',
      columnPickerPath: '',
      featureFlag: 'isGraphTab'
      // permissionLevel: 'member'
    }
    // dnsTree: {
    //     title: "DNS Tree",
    //     key: "dns_tree",
    //     link: "/pages/singleAsset",
    //     permissionOpen: true,
    //     active: false,
    //     showSummary: false,
    //     typeTab: "grid",
    //     columnPickerPath: "discovery/org-assets/internal/dns_tree/columns/",
    //     // tabPath: PathNames.dnsTreeSummary
    // }
  },
  mobileAssets: {
    tests: {
      title: 'Tests',
      key: 'tests',
      link: '/pages/singleAsset',
      permissionOpen: true,
      active: false,
      showSummary: false,
      typeTab: 'grid',
      columnPickerPath: 'discovery/mobile/tests/columns'
    },
    connections: {
      title: 'Connections',
      key: 'connections',
      link: '/pages/singleAsset',
      permissionOpen: true,
      active: false,
      showSummary: true,
      typeTab: 'grid',
      columnPickerPath: 'discovery/mobile/connections/columns/'
    }
  }
};
