import { IconRisk } from 'common/components/Icons/IconRisk/IconRisk';
import { Checkbox } from 'common/components/Icons/IconCheckbox/IconCheckbox';
import './filterMultipleChoice.scss';
import { IFilters } from 'common/components/grid/components/gridUtilities/interface';
import { FiltersManage } from '../services/FiltersManage';
import { Common } from 'common/services/common.service';
import { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFields, addFilter, getRows, removeFilter } from 'features/GridSlice';
import { AppDispatch } from 'app/store';
import { DropdownHeader } from '../dropdownHeader/DropdownHeader';
import { IChoices } from 'common/interfaces/interface';
import { Flag } from '../../../../../Flag/Flag';
import { NoFilterOptions } from 'common/components/NoFilterOptions/NoFilterOptions';
import classNames from 'classnames';
import { Search } from 'common/components/Search/Search';
import { SelectAll } from 'common/components/SelectAll/SelectAll';
import { getFiltersServerFormat } from 'utils/filters';
import { selectView, setIsEdited } from 'features/ViewsSlice';
import { Toggle } from '@cyberpion/cyberpion-ui';

interface IFilterMultipleChoice {
  item: IFilters;
  onUpdateDataFilter: (item: IFilters) => void;
  activeFilters: any;
  isFilterToggle?: boolean;
  // onUpdatedFilterGrid: (params: any) => void;
}

export function FilterMultipleChoice(props: IFilterMultipleChoice) {
  const ref = useRef<HTMLDivElement>(null);
  const [item, setItem] = useState<IFilters>(props.item);
  const [isToggled, setIsToggled] = useState<boolean>(false);
  const [isFilterOut, setIsFilterOut] = useState<boolean>(false);
  const dispatch: AppDispatch = useDispatch();
  const { filters, loading } = useSelector((state: any) => state.grid);
  const [searchInput, setSearchInput] = useState<string>('');
  const [isBlankSeleted, setIsBlankSelected] = useState<boolean>(false);

  //@param: event
  //@description: Hides the component
  const handleClickOutside = (event: Event) => {
    if (
      ref.current &&
      !ref.current.contains(event.target as Node)
      // !FiltersManage.contains(event.target)
    ) {
      // const option = { ...item };
      // option.toggle_dropdown = false;
      setIsToggled(false);
      // props.onUpdateDataFilter(option);
      document.removeEventListener('click', handleClickOutside, true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  useEffect(() => {
    const copy = { ...props.item };
    copy.choices = copy.choices.map((option: IFilters) => {
      return { ...option, visible: option.label?.toLowerCase().includes(searchInput) };
    });
    setItem(copy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput]);

  useEffect(() => {
    if (props.item) {
      const copy = { ...props.item };
      if (
        filters[copy.name]?.type.includes('_ne') ||
        (filters[copy.name]?.type === '__isnull' && filters[copy.name]?.value === 'False')
      ) {
        setIsFilterOut(true);
      }
      if (filters[copy.name]) {
        copy.is_active_filter = true;
        const values = filters[copy.name].value.split(',');
        copy.choices.forEach((choice: IChoices) => {
          if (choice.name === 'blank' && filters[copy.name]?.type === '__isnull') {
            choice.is_toggled = true;
            setIsBlankSelected(true);
          } else if (!choice.name && values.includes(Common.getEmptyValue())) {
            choice.is_toggled = true;
          } else if (choice.name !== null && choice.name.toString() && values.includes(choice.name.toString())) {
            choice.is_toggled = true;
          } else {
            choice.is_toggled = false;
          }
        });
        let { labels } = FiltersManage.mapMultipleChoiceFilter(copy.choices);
        copy.sub_label = labels;
      } else if (props.activeFilters[copy.name].is_active_filter) {
        copy.is_active_filter = false;
        copy.is_toggled = false;
        copy.choices.forEach((choice: IChoices) => {
          choice.is_toggled = false;
        });
        copy.sub_label = '';
        props.onUpdateDataFilter(copy);
        //
      } else {
        setIsBlankSelected(false);
      }
      setItem(copy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  //@param: item = item of Filter
  //@description: Inserts an icon according to the type of information. Just in case you need to put an icon
  function typeIcon(item: IFilters) {
    switch (props.item.type) {
      case 'risk':
        return <IconRisk urgency={item.name} className="IconRisk-scope" />;
      case 'grade':
      case 'result':
        return <IconRisk color={Common.getColorByTestResult(item.label)} className="IconRisk-scope" />;
      case 'country':
        return <Flag value={item.name} />;
    }
  }

  //@param: item = item of Filter
  //@param: index = item of Filter
  //@description: Update the filter data
  function updateOption(option: IFilters, index: number, override?: boolean) {
    let copy = { ...option };
    if (((searchInput && option.visible) || !searchInput) && override !== undefined) {
      copy.is_toggled = override;
    } else if (override === undefined) {
      copy.is_toggled = !copy.is_toggled;
    }
    let allData = { ...item };
    allData.is_active_filter = true;
    allData.choices[index] = copy;
    let { labels, names } = FiltersManage.mapMultipleChoiceFilter(allData.choices);
    allData.sub_label = labels;

    const filter = getFiltersServerFormat('multiple_choice', item, names, null, isFilterOut);

    if (copy.is_toggled) {
      if (copy.name === 'blank') {
        setIsBlankSelected(true);
      }
      dispatch(addFilter(filter));
      dispatch(addFields([item.name]));
    } else {
      if (copy.name === 'blank') {
        setIsBlankSelected(false);
      }
      if (allData.choices.filter((c: IFilters) => c.is_toggled).length === 0) {
        dispatch(removeFilter(item.name));
        allData.is_active_filter = false;
        allData.sub_label = '';
      } else {
        dispatch(addFilter(filter));
      }
    }
    if (override === undefined || index === allData.choices.length - 1) {
      dispatch(getRows());
      props.onUpdateDataFilter(allData);
    }
    dispatch(selectView(null));
    dispatch(setIsEdited(true));
    // props.onUpdateDataFilter(allData);
    // props.onUpdatedFilterGrid(allData.choices);
  }

  const isSomeSelected = (): boolean => {
    return item.choices.some((choice: IFilters) => choice.is_toggled);
  };

  const isAllSelected = (): boolean => {
    return item.choices.every((choice: IFilters) => choice.is_toggled);
  };

  const selectAll = () => {
    item.choices.forEach((choice: IFilters, index: number) => {
      if (choice.name !== 'blank') {
        updateOption(choice, index, true);
      }
    });
  };

  const unselectAll = () => {
    item.choices.forEach((choice: IFilters, index: number) => {
      updateOption(choice, index, false);
    });
  };

  // if (!item.is_active_filter && !item.is_default_filter) {
  //   return null;
  // }

  const shouldShowNegativeFiltersToggle = () => {
    return (
      [
        'array',
        'array_string',
        'array_number',
        'discovery_investigation_asset_verdict',
        'domain_discovery_investigation_asset_status',
        'discovery_investigation_string'
      ].includes(item.type) ||
      (['tags'].includes(item.type) && !window.location.href.includes('actionItems'))
    );
  };

  return (
    <>
      {!props.isFilterToggle && (
        <DropdownHeader
          item={item}
          isToggled={isToggled}
          onToggle={() => setIsToggled(!isToggled)}
          onChangeData={props.onUpdateDataFilter}
          // onUpdateFilterGrid={props.onUpdateFilterGrid}
        />
      )}
      {(isToggled || !!props.isFilterToggle) && (
        <div ref={ref} className={classNames('scrollbar-common', { 'multipleChoice-scope': !props.isFilterToggle })}>
          {!!item.choices.length && !shouldShowNegativeFiltersToggle() && <Search onChange={setSearchInput} />}
          {shouldShowNegativeFiltersToggle() && (
            <Toggle
              options={['Is', 'Is Not']}
              onChange={(val: string) => {
                setIsFilterOut(val === 'Is Not');
                unselectAll();
              }}
            />
          )}
          {!!item.choices.length && !props.isFilterToggle && (
            <SelectAll
              selectAll={selectAll}
              unselectAll={unselectAll}
              isSomeSelected={isSomeSelected}
              isAllSelected={isAllSelected}
            />
          )}
          <div className={classNames('multipleChoice_body-scope', { blank_selected: isBlankSeleted })}>
            {item.choices.length ? (
              item.choices.map((option: IFilters, key: number) => (
                <div
                  className={classNames('multipleChoice-option-scope', {
                    hidden: !option.label?.toLowerCase().includes(searchInput),
                    inactive: loading
                  })}
                  key={key}
                  onClick={e => updateOption(option, key)}
                  data-testid={option.label}
                >
                  <Checkbox active={option.is_toggled} />
                  {typeIcon(option)}
                  <div className="value">{option.label}</div>
                </div>
              ))
            ) : (
              <NoFilterOptions />
            )}
          </div>
        </div>
      )}
    </>
  );
}
