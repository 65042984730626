import GroupsHeader from './GroupsHeader';
import './GroupsPopside.scss';
import GroupRules from './GroupRules/GroupRules';
import GroupButtons from './GroupButtons/GroupButtons';
import { useSelector } from 'react-redux';
import { IReduxState } from 'app/store';
import { AppLoading } from 'common/components/appLoading/appLoading';

const GroupsPopside = (props: any) => {
  const { filters, rules } = useSelector((state: IReduxState) => state.groups);

  const shouldDisplayLoading = () => {
    if (!filters.length || rules === null) {
      return true;
    }
    return false;
  };

  return (
    <div className="GroupsPopside">
      {shouldDisplayLoading() && <AppLoading />}
      <GroupsHeader type={props.source} />
      <GroupRules type={props.source} />
      <GroupButtons type={props.source} onClose={props.onHide} />
    </div>
  );
};

export default GroupsPopside;
