// import { Link } from 'react-router-dom';
import './CVESidePanel.scss';
import { Button } from '@cyberpion/cyberpion-ui';
import { AffectedAssets } from './AffectedAssets/AffectedAssets';
import { useEffect, useState } from 'react';
import { LinkNewTab } from 'common/components/LinkNewTab/LinkNewTab';
import { RestApi } from 'common/services/rest-api.service';
import { AppLoading } from 'common/components/appLoading/appLoading';
import { Common } from 'common/services/common.service';

export function CVESidePanel(props: any) {
  const [expandedElementsIds, setExpandedElementsIds] = useState<number[]>([]);
  const [data, setData] = useState<any>({});

  useEffect(() => {
    RestApi.getData(`assessments/cves/?cve=${props.id}`).subscribe((response: any) => {
      if (response.results) {
        setData(response.results[0]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const expand = (id: number) => {
    setExpandedElementsIds([...expandedElementsIds, id]);
  };

  const render = (string: string, type?: string) => {
    if (!string) {
      return 'NA';
    }
    if (type === 'percentage') {
      return (parseFloat(string) * 100).toFixed(2) + '%';
    }

    if (type === 'score') {
      return string.toString().substring(0, 7);
    }

    return string;
  };

  if (!Object.keys(data).length) {
    return (
      <div className="CVESidePanel">
        <AppLoading />
      </div>
    );
  }

  return (
    <div className="CVESidePanel">
      <h6>
        <img src="/assets/images/score.svg" alt={'CVE'} /> CVEs
      </h6>
      <h2>{props.id}</h2>

      <div className="scrollbar-common">
        <div className="main-data-area">
          <div style={{ height: '100%', overflow: 'auto' }}>
            <div className="data-wrapper">
              <section className="left">
                <div className="box">
                  <h4>CVE Details</h4>
                  {data.description && (
                    <>
                      <h5>Description</h5>
                      <p>
                        {expandedElementsIds.includes(1) || data.description.length <= 250
                          ? data.description
                          : `${data.description.substring(0, 250)}...`}
                      </p>
                      {!expandedElementsIds.includes(1) && data.description.length > 250 && (
                        <span className="read-more" onClick={() => expand(1)}>
                          Read More
                        </span>
                      )}
                    </>
                  )}
                  <h5>Reference</h5>
                  {/* <p> */}
                  <LinkNewTab
                    to={`https://nvd.nist.gov/vuln/detail/${props.id}`}
                    text={`https://nvd.nist.gov/vuln/detail/${props.id}`}
                  />
                  {/* </p> */}
                  {/* <h5>Public exploit links</h5>
                  <p>
                    {expandedElementsIds.includes(2)
                      ? LINKS.map((link: string) => <a href={link}>{link}</a>)
                      : LINKS[0]}
                  </p>
                  {LINKS.length > 1 && !expandedElementsIds.includes(2) && (
                    <span className="read-more" onClick={() => expand(2)}>
                      +2 More
                    </span>
                  )} */}
                </div>
                <div className="box">
                  <h4>CVSS Severity and metrics</h4>
                  <div className="info">
                    <div>
                      <h5>Base score</h5>
                      <p>{render(data.cvss, 'score')}</p>
                    </div>
                    <span></span>
                    <div>
                      <h5>Impact score</h5>
                      <p>{render(data.impact_score, 'score')}</p>
                    </div>
                    <span></span>
                    <div>
                      <h5>Exploitability score</h5>
                      <p>{render(data.exploitability_score, 'score')}</p>
                    </div>
                  </div>
                  {data.cvss_vector && (
                    <>
                      <h5>Vector</h5>
                      <p>{data.cvss_vector}</p>
                    </>
                  )}
                  <div className="wrapper">
                    {data.attack_vector && (
                      <div>
                        <h5>Attack vector</h5>
                        <p>{data.attack_vector}</p>
                      </div>
                    )}
                    {data.attack_complexity && (
                      <div>
                        <h5>Attack complexity</h5>
                        <p>{data.attack_complexity}</p>
                      </div>
                    )}
                    {data.privileges_required && (
                      <div>
                        <h5>Privileges Requires</h5>
                        <p>{data.privileges_required}</p>
                      </div>
                    )}
                    {data.user_interaction && (
                      <div>
                        <h5>User Interaction</h5>
                        <p>{data.user_interaction}</p>
                      </div>
                    )}
                    {data.scope && (
                      <div>
                        <h5>Scope</h5>
                        <p>{data.scope}</p>
                      </div>
                    )}
                    {data.confidentiality_impact && (
                      <div>
                        <h5>Confidentiality</h5>
                        <p>{data.confidentiality_impact}</p>
                      </div>
                    )}
                    {data.integrity_impact && (
                      <div>
                        <h5>Integrity</h5>
                        <p>{data.integrity_impact}</p>
                      </div>
                    )}
                    {data.availability_impact && (
                      <div>
                        <h5>Availability</h5>
                        <p>{data.availability_impact}</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="boxes-wrapper">
                  <div className="box" style={{ maxHeight: 120, maxWidth: '50%' }}>
                    <h4>EPSS</h4>
                    <div className="info">
                      <div>
                        <h5>Score</h5>
                        <p>{render(data.epss, 'percentage')}</p>
                      </div>
                      <span></span>
                      <div>
                        <h5>Percentile</h5>
                        <p>{render(data.epss_percentile, 'percentage')}</p>
                      </div>
                    </div>
                  </div>
                  {data.cwe && !!data.cwe.length && (
                    <div className="box">
                      <h4>CWE</h4>
                      <ul>
                        {data.cwe
                          .slice(0, !expandedElementsIds.includes(3) ? 3 : 200)
                          .map((cwe: number, index: number) => {
                            return <li>CWE-{cwe}</li>;
                          })}
                      </ul>
                      {!expandedElementsIds.includes(3) && data.cwe.length > 2 && (
                        <span className="read-more" onClick={() => expand(3)}>
                          Read More
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </section>
              <section className="right">
                <AffectedAssets
                  cve={props.id}
                  numOrgAssets={data.asset_counter || 0}
                  numExtAssets={data.digital_supply_chain_counter || 0}
                />
              </section>
            </div>
          </div>
        </div>
      </div>

      <div className="buttons-container">
        {/* <Link to="">Related Assets</Link> */}
        <div className="buttons-wrapper">
          <Button text="Close" buttonStyle="secondary" onClick={props.onClose} size="medium" type="button" />
          <Button
            text="View Action Items"
            buttonStyle="main"
            onClick={() => {
              window.open(
                `${Common.AddMsspAccountIfNeeded(
                  `/pages/actionItems/open?filters=%7B%22cves__intext%22%3A%22${props.id}%22%7D`
                )}`,
                '_blank'
              );
            }}
            size="medium"
            type="button"
          />
        </div>
      </div>
    </div>
  );
}
