import React, { useState, useRef, useEffect } from 'react';
import { IFilters } from 'common/components/grid/components/gridUtilities/interface';
import { IFilterGridOption } from 'common/components/grid/components/gridUtilities/interface';
import { Button } from '@mui/material';
import { Input } from 'common/components/Input/Input';
import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import { DropdownHeader } from '../dropdownHeader/DropdownHeader';
import { AppDispatch } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { addFields, addFilter, getRows } from 'features/GridSlice';
import { getFiltersServerFormat } from 'utils/filters';
import { Common } from 'common/services/common.service';
import classNames from 'classnames';
import { selectView, setIsEdited } from 'features/ViewsSlice';
import { MultiTextFields } from '@cyberpion/cyberpion-ui';
import './filterFreeText.scss';

interface IFilterFreeText {
  item: IFilters;
  onUpdateDataFilter: (item: IFilters, isApplyClicked?: boolean) => void;
  activeFilters: any;
  isFilterToggle?: boolean;
  onCancel?: () => void;
  // onUpdatedFilterGrid: (params: IFilterGridOption) => void;
}
export function FilterFreeText(props: IFilterFreeText) {
  const ref = useRef<HTMLDivElement>(null);
  const [item, setItem] = useState<IFilters>(props.item);
  const [isToggled, setIsToggled] = useState<boolean>(false);
  const dispatch: AppDispatch = useDispatch();
  const { filters } = useSelector((state: any) => state.grid);
  const [error, setError] = useState<boolean>(false);
  const [multiInputsValues, setMultiInputsValues] = useState('');
  const [multiInputsErrors, setMultiInputsErrors] = useState<boolean[]>([]);
  // const [disabledApply, setDisabledApply] = useState(false);

  //@param: event
  //@description: Hides the component
  const handleClickOutside = (event: Event) => {
    if (
      ref.current &&
      !ref.current.contains(event.target as Node)
      // !FiltersManage.contains(event.target)
    ) {
      const option = { ...item };
      option.toggle_dropdown = false;
      setItem(option);
      setIsToggled(false);
      // props.onUpdateDataFilter(option);
      document.removeEventListener('click', handleClickOutside, true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  let choices = checkChoices();
  const [value, setValue] = useState<string>(choices.value);
  const [isListOpen, setIsListOpen] = useState<boolean>(false);
  const [select, setSelect] = useState<IFilterGridOption>(choices);

  // useEffect(() => {
  //   if (!!select && select.label.toLowerCase() !== 'full match' && select.label.toLowerCase() !== 'equals') {
  //     setDisabledApply(false);
  //     return;
  //   }
  //   const item = multiInputsValues.split(',').find((item: any) => item.trim() !== '');
  //   setDisabledApply(!item);
  // }, [select, multiInputsValues]);

  useEffect(() => {
    if (choices) {
      setSelect(choices);
    }
  }, [choices]);

  useEffect(() => {
    if (!!select && select.label.toLowerCase() !== 'full match' && select.label.toLowerCase() !== 'equals') {
      // _setValue('', '__in');
      setMultiInputsValues('');
    }
    _setValue('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [select]);

  useEffect(() => {
    if (props.item) {
      const copy = { ...props.item };
      if (filters[copy.name]) {
        _setValue(filters[copy.name].value, filters[copy.name].type);
        const choice = copy.choices.filter((c: any) => {
          return (
            c.name === filters[copy.name].type || c.name === filters[copy.name].type + '=' + filters[copy.name].value
          );
        })[0];
        setSelect(choice);
        if (choice.label.includes('(Blank)')) {
          copy.sub_label = `: ${choice.label}`;
        } else {
          copy.sub_label = `: ${choice.label} ${filters[copy.name].value}`;
        }
        copy.is_active_filter = true;
      } else if (props.activeFilters[copy.name].is_active_filter) {
        copy.is_active_filter = false;
        copy.is_toggled = false;
        _setValue('');
        copy.sub_label = '';
        props.onUpdateDataFilter(copy);
      }
      setItem(copy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.item, filters]);

  //@description Check with have a selected item with a search value
  function checkChoices() {
    let choices = item.choices;
    for (let index = 0; index < choices.length; index++) {
      if (choices[index].value.trim() !== '') {
        return choices[index];
      }
    }
    return choices[0];
  }

  //@description Filter by value and type of filter. Then updates the grid
  function handleKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Enter') {
      onApply();
    }
  }

  function onApply() {
    if (select.label.toLowerCase() === 'full match' || select.label.toLowerCase() === 'equals') {
      if (!multiInputsValues.split(',').find((item: any) => item.trim() !== '')) {
        const arrErrors: boolean[] = multiInputsValues.split(',').map((value: string) => {
          return true;
        });
        setMultiInputsErrors(arrErrors);
        return;
      }
      const inputValues = multiInputsValues
        .split(',')
        .filter((item: any) => item.trim() !== '')
        .join(',');
      setMultiInputsValues(inputValues);

      if (item.type === 'array_string' && item.name === 'ips') {
        const isFullMatch = ['__intext', '__overlap', '__cidr_contains', '__cidr_contained_by'].includes(select.name);
        const arrErrors: boolean[] = multiInputsValues
          .split(',')
          .filter((item: any) => item.trim() !== '')
          .map((value: string) => {
            return !!isFullMatch && !Common.validateIp(value);
          });
        if (!!arrErrors.filter((item: boolean) => !!item).length) {
          setMultiInputsErrors(arrErrors);
          return;
        }
      }
      if (item.type === 'array_number') {
        const arrErrors: boolean[] = multiInputsValues.split(',').map((item: any) => {
          return !item.match(/^[0-9]+$/);
        });
        if (!!arrErrors.filter((item: boolean) => !!item).length) {
          setMultiInputsErrors(arrErrors);
          return;
        }
      }
      let copy = { ...item };
      copy.choices.forEach((choice: IFilterGridOption) => {
        if (choice.label === select.label) {
          choice.value = inputValues;
        } else {
          choice.value = '';
        }
      });
      // Added this to close the filter box after apply
      copy.toggle_dropdown = false;
      copy.sub_label = `: ${select.label} ${multiInputsValues}`;
      copy.is_active_filter = true;
      const filter = getFiltersServerFormat('stringAndNumber', item, select);
      setItem(copy);
      setIsToggled(false);
      dispatch(addFilter(filter));
      dispatch(addFields([item.name]));
      dispatch(getRows());
      dispatch(selectView(null));
      dispatch(setIsEdited(true));
      props.onUpdateDataFilter(copy, true);
      return;
    } else {
      if (value.trim() !== '' || select.name.includes('isnull')) {
        if (item.type === 'array_number') {
          if (
            value.split(',').filter((item: any) => !item.match(/^[0-9]+$/)).length &&
            !select.name.includes('isnull')
          ) {
            setError(true);
            return;
          }
        }

        if (['array_string', 'ip'].includes(item.type) && ['ips', 'ip_network'].includes(item.name)) {
          const isFullMatch = ['__intext', '__overlap', '__cidr_contains', '__cidr_contained_by'].includes(select.name);
          if (!!isFullMatch && !Common.validateIp(value)) {
            setError(true);
            return;
          }
        }
        let copy = { ...item };
        copy.choices.forEach((choice: IFilterGridOption) => {
          if (choice.label === select.label) {
            choice.value = value;
          } else {
            choice.value = '';
          }
        });
        // Added this to close the filter box after apply
        copy.toggle_dropdown = false;
        // filter = `${item.name}${data_dropdown.name}=${data_dropdown.type}`;
        // queryGrid.updateMoreQueryChain(item.name, filter);

        copy.sub_label = `: ${select.label} ${select.value}`;
        copy.is_active_filter = true;
        // const filterType = item.type === 'array_number' ? '__overlap' : select.name;
        // const filter = {
        //   [copy.name]: {
        //     type: filterType,
        //     value: select.value.trim()
        //   }
        // };
        const filter = getFiltersServerFormat('stringAndNumber', item, select);

        setItem(copy);
        setIsToggled(false);
        dispatch(addFilter(filter));
        dispatch(addFields([item.name]));
        dispatch(getRows());
        dispatch(selectView(null));
        dispatch(setIsEdited(true));
        props.onUpdateDataFilter(copy, true);
        // props.onUpdatedFilterGrid({ ...select, type: value });
      } else {
        setError(true);
      }
    }
  }

  //@description Closes the drop-down menu
  function onCancel() {
    const copy = { ...item };
    copy.toggle_dropdown = false;
    setItem(copy);
    setIsToggled(false);
    if (props.onCancel) {
      props.onCancel();
    }
    // props.onUpdateDataFilter(props.item);
  }

  function onselectItem(option: IFilterGridOption) {
    setSelect(option);
    let input: HTMLElement | null = document.getElementById('FilterGrid-input_text');
    input?.focus();
    setIsListOpen(!isListOpen);
  }

  const _setValue = (_value: string, type?: string) => {
    if (['number'].includes(props.item.type) && _value && !_value.match(/^[-0-9]+$/)) {
      return;
    }

    if (['array_number'].includes(props.item.type) && _value && !_value.match(/^[,0-9]+$/)) {
      return;
    }

    if (props.item.type === 'ip' && _value && !_value.match(/^[0-9]+[./]?/)) {
      return;
    }

    setError(false);
    if (!!type && (type === '__intext' || type === '__overlap')) {
      setMultiInputsValues(_value);
    } else {
      setValue(_value);
    }
  };

  const handleMultiInputsValues = (values: string) => {
    setMultiInputsValues(values);
    setMultiInputsErrors(multiInputsErrors.map(() => false));
  };

  return (
    <>
      {!props.isFilterToggle && (
        <DropdownHeader
          item={item}
          isToggled={isToggled}
          onToggle={() => setIsToggled(!isToggled)}
          onChangeData={props.onUpdateDataFilter}
        />
      )}
      {(isToggled || !!props.isFilterToggle) && (
        <div ref={ref} className={`${props.isFilterToggle ? '' : 'filterGrid_scope'}`}>
          <div className="filterGrid-header-scope" onClick={() => setIsListOpen(!isListOpen)}>
            <div className="">{select.label}</div>
            <IconArrow active={isListOpen} toggleRotate={isListOpen} />
          </div>

          {isListOpen && (
            <div className="filterGrid-body-scope">
              {item.choices.map((option: IFilterGridOption, key: number) => (
                <div className="filterGrid-item" key={key} onClick={() => onselectItem(option)}>
                  {option.label}
                </div>
              ))}
            </div>
          )}

          {!!select && (select.label.toLowerCase() === 'full match' || select.label.toLowerCase() === 'equals') ? (
            <div style={{ marginTop: 8, marginBottom: 8 }}>
              <MultiTextFields
                values={multiInputsValues}
                setValues={handleMultiInputsValues}
                className="filter-freetext-multi-inputs"
                areErrors={multiInputsErrors}
                onEnter={onApply}
              />
            </div>
          ) : (
            <div className="input-type">
              <Input
                className={classNames('filterFreeText-input', {
                  error: error,
                  disabled: select.name.includes('isnull')
                })}
                id="filterGrid-input_text"
                type={'text'}
                value={select.name.includes('isnull') ? '' : value}
                onKeyDown={handleKeyDown}
                onChange={value => _setValue(value)}
                placeholder={'Type..'}
                disabled={select.name.includes('isnull')}
              />
            </div>
          )}
          <div className="filterGrid-button-scope">
            <Button>
              <div className="filterGrid-button" onClick={onCancel}>
                Cancel
              </div>
            </Button>
            <Button>
              <div className={classNames('filterGrid-button', 'filterGrid-button-apply')} onClick={onApply}>
                Apply
              </div>
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
