import { Button, Text } from '@cyberpion/cyberpion-ui';

import './WarningDiscoveryPopup.scss';

const WarningDiscoveryPopup = (props: any) => {
  const handleCancelOnClick = () => {
    props.setVisible(false);
  };

  return (
    <div className="WarningDiscoveryPopup popup-inner-wrapper">
      <div className="popup-inner">
        <Text textSize={18} weight={500} style={{ marginBottom: 12 }}>
          Are you sure?
        </Text>
        <Text textSize={13} textColor="#8C909B" style={{ lineHeight: 1.35, marginBottom: 4 }}>
          {`You have <x > entries with Pending status and <y> with Maybe status.`}
        </Text>
        <Text textSize={13} textColor="#8C909B" style={{ lineHeight: 1.35, marginBottom: 16 }}>
          {`Are you sure you want to save the results? ${props.errorData?.message}`}
        </Text>

        <div className="buttons-wrapper">
          <Button
            buttonStyle="secondary"
            disabled={false}
            type="button"
            size="small"
            onClick={handleCancelOnClick}
            text="Cancel"
          />
          <Button buttonStyle="main" disabled={false} type="button" size="small" onClick={props.onSave} text="Save" />
        </div>
      </div>
    </div>
  );
};

export default WarningDiscoveryPopup;
