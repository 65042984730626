export const OPTIONS = [
  {
    id: 'copy',
    icon: 'clipboard',
    label: 'Copy FQDNs'
  },
  {
    id: 'open',
    icon: 'newTab',
    label: 'Navigate to FQDNs'
  },
  {
    id: 'comment',
    icon: 'comment',
    label: 'Add comment'
  }
];
