import React, { useEffect, useState } from 'react';
import { AppDispatch, IReduxState } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { Text, Button } from '@cyberpion/cyberpion-ui';
import classNames from 'classnames';
import { Grow, Popover } from '@mui/material';
import { IRootColumnPicker } from 'common/interfaces/interface';
import { AppLoading } from 'common/components/appLoading/appLoading';
import { RestApi } from 'common/services/rest-api.service';
import {
  setDiscoveryInvestigationCommentIds,
  setDiscoveryInvestigationToolReportStatus,
  setDiscoveryVerdictDropdown,
  setFields,
  setInitialPath,
  setShowAddCommentPopupDiscoveryPending,
  setSupportMultiLineColumn,
  updateRows
} from 'features/GridSlice';
import { ISettingsItems } from 'pages/Settings/Settings';
import { setColumnPicker, setShowColumnPicker } from 'features/columnPickerSlice';
import { Grid } from 'common/components/grid/Grid';
import { Common } from 'common/services/common.service';
import InvestigationLeftPanel from './InvestigationLeftPanel/InvestigationLeftPanel';
import { ColumnsManage } from 'common/components/grid/columnsManage';
import {
  DiscoveryScanReportContextProvider,
  DiscoveryScanReportContextType,
  useDiscoveryScanReport
} from './DiscoveryScanReportContext';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { SaveCustomView } from 'common/components/grid/components/SavedViews/SaveCustomView';
import { PopUp } from 'common/components/popUp/PopUp';
import AddDiscoveryCommentPopup from 'common/components/AddDiscoveryCommentPopup/AddDiscoveryCommentPopup';
import { showSuccess } from 'features/AlertSlice';
import { useComponentVisible } from 'common/hooks/useComponentVisible';
import ErrorDiscoveryPopup from 'pages/Settings/DiscoveryInvestigationTool/popups/ErrorDiscoveryPopup/ErrorDiscoveryPopup';
import WarningDiscoveryPopup from 'pages/Settings/DiscoveryInvestigationTool/popups/WarningDiscoveryPopup/WarningDiscoveryPopup';
import InfoDiscoveryPopup from '../popups/InfoDiscoveryPopup/InfoDiscoveryPopup';
import { CustomSwitch } from 'common/components/CustomSwitch/CustomSwitch';
import { OPTIONS } from './config';

import './DiscoveryScanReport.scss';

const iconCompleted = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <circle cx="6" cy="6.00391" r="6" fill="#4FA686" />
    <path d="M8 5.00391L5.5625 8.00391L4 6.71819" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

const iconInProgress = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <circle cx="6" cy="6.00391" r="6" fill="#DFB940" />
    <path
      d="M6 2.00391C7.06087 2.00391 8.07828 2.42533 8.82843 3.17548C9.57857 3.92562 10 4.94304 10 6.00391C10 7.06477 9.57857 8.08219 8.82843 8.83233C8.07828 9.58248 7.06087 10.0039 6 10.0039L6 6.00391L6 2.00391Z"
      fill="white"
    />
  </svg>
);

const iconQueued = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <circle cx="6" cy="6.00391" r="5.25" stroke="#5D6576" stroke-width="1.5" stroke-dasharray="2 2" />
  </svg>
);

const iconArrow = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      d="M3.66667 9.33366L7 6.00033L3.66667 2.66699"
      stroke="#5D6576"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const iconFailed = (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="6" cy="6" r="6" fill="#5D6576" />
    <path d="M8.25 3.75L3.75 8.25" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8.25 8.25L3.75 3.75" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

const iconClipboard = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <rect
      x="1.125"
      y="3.375"
      width="7.5"
      height="7.5"
      rx="0.75"
      stroke="#5D6576"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.375 3.375V1.875C3.375 1.46079 3.71079 1.125 4.125 1.125H10.125C10.5392 1.125 10.875 1.46079 10.875 1.875V7.875C10.875 8.28921 10.5392 8.625 10.125 8.625H8.625"
      stroke="#5D6576"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const iconNewTab = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path d="M7.52499 2.5H10.075V5.05" stroke="#5D6576" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10.075 2.5L6.24998 6.325" stroke="#5D6576" stroke-linecap="round" stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.82499 2H3.42499C2.59656 2 1.92499 2.67157 1.92499 3.5V9.15C1.92499 9.97843 2.59656 10.65 3.42499 10.65H9.07499C9.90342 10.65 10.575 9.97843 10.575 9.15V6.75H9.57499V9.15C9.57499 9.42614 9.35113 9.65 9.07499 9.65H3.42499C3.14885 9.65 2.92499 9.42614 2.92499 9.15V3.5C2.92499 3.22386 3.14885 3 3.42499 3H5.82499V2Z"
      fill="#5D6576"
    />
  </svg>
);

const iconComment = (
  <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.5 17.5H17.5001" stroke="#999999" stroke-linecap="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.6487 4.06243L15.8178 4.23152C16.2199 4.63365 16.2062 5.28975 15.7876 5.6747L15.0555 6.34792L13.4709 4.76338L14.2647 4.03346C14.6592 3.67063 15.2696 3.68341 15.6487 4.06243ZM12.7342 5.44087L3.42413 14.0025L2.60633 16.3923L5.03786 15.5602L14.3188 7.02542L12.7342 5.44087ZM13.5878 3.29739C14.3768 2.57173 15.5977 2.59729 16.3558 3.35532L16.5249 3.52441C17.3291 4.32868 17.3017 5.64087 16.4645 6.41078L5.71476 16.2963C5.61286 16.39 5.49261 16.4615 5.36163 16.5064L2.9301 17.3384C2.14348 17.6076 1.39101 16.8552 1.66019 16.0685L2.47799 13.6787C2.53197 13.521 2.62452 13.3793 2.74723 13.2664L13.5878 3.29739Z"
      fill="#999999"
    />
  </svg>
);

const initData = {
  rows: [],
  isMoreButton: true,
  isCSVDownloadable: false,
  count: 0,
  columns: [],
  selectTab: '',
  rootPath: '',
  identifier: ''
};

const optionsTime: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true
};

const options = [
  { label: 'Approved', name: 'approved', color: '#4FA686' },
  { label: 'Maybe', name: 'maybe', color: '#DFB940' },
  { label: 'Rejected', name: 'rejected', color: '#F46666' },
  { label: 'Pending', name: 'pending', color: '#B7BBC5' },
  { label: 'None', name: 'none', color: '#FFF' }
];
const iconMap = {
  Complete: iconCompleted,
  'In Progress': iconInProgress,
  Queued: iconQueued,
  Failed: iconFailed
};

const OptionsMap = {
  pending: 10,
  rejected: 20,
  maybe: 30,
  approved: 40,
  none: '_null_'
};

const mapActionMenuIcons = {
  clipboard: iconClipboard,
  newTab: iconNewTab,
  comment: iconComment
};

const DiscoveryScanReportModule = (props: any) => {
  const [data, setData] = useState<ISettingsItems>(initData);
  const [panelIsOpen, setPanelIsOpen] = useState(true);
  const [headerData, setHeaderData] = useState<any>({});
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isActionMenuOpen, setIsActionMenuOpen] = useState(false);
  const [isReportCompleted, setIsReportCompleted] = useState(false);
  const [shouldMultiline, setShouldMultiline] = useState(false);
  const [selectedGridRows, setSelectedGridRows] = useState<any>([]);
  const [showErrorPopupDiscovery, setShowErrorPopupDiscovery] = useState(false);
  const [showInfoPopupDiscovery, setShowInfoPopupDiscovery] = useState(false);
  const [showWarningPopupDiscovery, setShowWarningPopupDiscovery] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorData, setErrorData] = useState<any>(null);
  const [subMenuOptions, setSubMenuOptions] = useState<any>(null);
  const {
    saveCustomViewVisible,
    setSaveCustomViewVisible
  } = useDiscoveryScanReport() as DiscoveryScanReportContextType;
  const { columnPicker } = useSelector((state: IReduxState) => state.columnPicker);
  const { user, account } = useSelector((state: IReduxState) => state.login);
  const { showAddCommentPopupDiscoveryPending, rows } = useSelector((state: IReduxState) => state.grid);
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const params = queryString.parse(location.search);
  const { setIsComponentVisible } = useComponentVisible(false);

  // const [shouldOpenMask, setShouldOpenMask] = useState(false);

  const onTileClick = (params: any) => {
    console.log('XXX: ', params);
  };

  useEffect(() => {
    setIsActionMenuOpen(Boolean(anchorEl));
  }, [anchorEl]);

  useEffect(() => {
    if (!isActionMenuOpen) {
      setSubMenuOptions(null);
    }
  }, [isActionMenuOpen]);

  const fetchData = () => {
    setData(initData);

    RestApi.getData(`discovery/dpbs/${user.company_name}/scans/?id=${params.rowId}`).subscribe((results: any) => {
      const scanData = results.results[0];
      if (!!scanData) {
        setHeaderData({
          type: scanData.discovery_type,
          scan_status: scanData.scan_status,
          start: scanData.scan_start_time,
          end: scanData.scan_end_time,
          review_status: scanData.review_status
        });
        setIsReportCompleted(
          scanData.review_status?.toLowerCase() === 'complete' || scanData.review_status?.toLowerCase() === 'submitted'
        );
        dispatch(setDiscoveryInvestigationToolReportStatus(scanData.review_status));
      }
    });
    const url = Common.AddMsspAccountIfNeeded('discovery/dpbs/scans/domains/assets/columns');
    RestApi.getData(url).subscribe((columns: IRootColumnPicker) => {
      let fields = ColumnsManage.checkGridColumnsToDisplay(columns.data);
      dispatch(setFields(fields));
      dispatch(setInitialPath(`discovery/dpbs/${user.company_name}/scans/domains/${params.discoveryScanId}/assets`));

      dispatch(setColumnPicker(columns.data));
      dispatch(setShowColumnPicker(columns.is_displayable));

      setData({
        rows: [],
        isMoreButton: columns.is_more_button,
        isCSVDownloadable: columns.is_csv_downloadable,
        count: 0,
        selectTab: '',
        identifier: columns.identifier_field,
        columns: ColumnsManage.createColumns(
          columns.identifier_field,
          columns.data || [],
          {
            page: 'settings',
            category: 'organization-management',
            tab: 'manage-fqdns'
          },
          {
            cb: onTileClick
          }
        ),
        rootPath: `discovery/dpbs/${user.company_name}/scans/domains/${params.discoveryScanId}/assets`
      });
    });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSubmit = () => {
    setShowInfoPopupDiscovery(true);
  };

  const handleActionButtonOnClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    setAnchorEl(event.target as HTMLButtonElement);
  };

  const handleClose = () => {
    setSelectedGridRows([]);
    setAnchorEl(null);
  };

  const handleActionButtonItemOnClick = (item: any) => (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);

    if (item.label !== 'Pending') {
      const url = Common.AddMsspAccountIfNeeded(
        `discovery/dpbs/${user.company_name}/scans/domains/${params.discoveryScanId}/verdicts`
      );
      const payload = {
        ids: selectedGridRows,
        verdict: OptionsMap[item.name as keyof typeof OptionsMap],
        user: user.email
      };
      RestApi.setData(url, payload, 'POST').subscribe((response: any) => {
        const updatedRows = selectedGridRows?.reduce((all: any, id: any) => {
          const row = rows.find((row: any) => row.id === id);
          return [
            ...all,
            {
              ...row,
              verdict: item.label !== 'None' ? item.label : null
              // user_comments_count: row.user_comments_count + 1,
              // state_update_user: user.email,
              // state_update_timestamp: new Date().toISOString()
            }
          ];
        }, []);
        dispatch(updateRows({ rows: updatedRows, identifier: 'id' }));
        dispatch(showSuccess('Updated Successfully'));
      });
    } else {
      const statusOptions = columnPicker.find((col: any) => col.type === 'discovery_investigation_asset_verdict')
        .choices;
      const nextOption = statusOptions.find((opt: any) => opt.label === 'Pending');

      const listOptions = selectedGridRows?.reduce((all: any, id: any) => {
        const rowState = rows.find((row: any) => row.id === id).verdict;
        const option = !!rowState
          ? statusOptions.find((opt: any) => opt.label === rowState)
          : { label: 'Select', name: 'select', is_toggled: false };

        return {
          ...all,
          [id]: option
        };
      }, {});
      dispatch(
        setDiscoveryVerdictDropdown({
          listLastStatusOption: listOptions,
          nextStatusOption: nextOption
        })
      );

      const updatedRows = selectedGridRows?.reduce((all: any, id: any) => {
        const row = rows.find((row: any) => row.id === id);
        return [
          ...all,
          {
            ...row,
            verdict: 'Pending'
          }
        ];
      }, []);
      dispatch(updateRows({ rows: updatedRows, identifier: 'id' }));
      dispatch(setShowAddCommentPopupDiscoveryPending(true));
    }
    setIsComponentVisible(false);
  };

  const handlePopupVisible = (visible: boolean) => {
    dispatch(setShowAddCommentPopupDiscoveryPending(visible));
  };

  // const handleErrorPopupVisible = (visible: boolean) => {
  //   dispatch(setShowAddCommentPopupDiscoveryPending(visible));
  // };

  let timeHeader = '';
  if (!!Object.keys(headerData).length) {
    const time = new Intl.DateTimeFormat('en-US', optionsTime).format(
      new Date(headerData.end ? headerData.end : headerData.start)
    );
    const y = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
      new Date(headerData.end ? headerData.end : headerData.start)
    );
    const m = new Intl.DateTimeFormat('en', { month: 'short' }).format(
      new Date(headerData.end ? headerData.end : headerData.start)
    );
    const d = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(
      new Date(headerData.end ? headerData.end : headerData.start)
    );
    timeHeader = `${m} ${d}, ${y} ${time}`;
  }

  const handleSaveOnClick = () => {
    setShowInfoPopupDiscovery(false);
    // const url = Common.AddMsspAccountIfNeeded(
    //   `discovery/dpbs/${user.company_name}/inventory/domains/${params.discoveryScanId}?force_result=${
    //     shouldOpenMask ? '400' : '200'
    //   }`
    // );
    const url = Common.AddMsspAccountIfNeeded(
      `discovery/dpbs/${user.company_name}/inventory/domains/${params.discoveryScanId}`
    );
    try {
      setIsLoading(true);
      // setShowMaskOverlay(true);
      RestApi.setData(url, {}, 'POST').subscribe(
        (response: any) => {
          dispatch(showSuccess('Report Submitted Successfully!'));
          setIsReportCompleted(true);
          setIsLoading(false);
        },
        (error: any) => {
          if (error.status === 400) {
            if (!!error && !!error.message) {
              const err = JSON.parse(error.message);
              const errorMsg =
                !!err && !!err?.errors?.length ? err?.errors.map((e: any) => e.details) : ['Domain conflicts error'];
              setErrorData({ message: errorMsg, status: error.status });
            } else {
              setErrorData({ message: ['Domain conflicts error'], status: error.status });
            }
            setShowErrorPopupDiscovery(true);
          } else {
            const errorMsg =
              !!error && !!error?.errors?.length ? error?.errors.map((e: any) => e.details) : ['500 Generic Error'];
            setErrorData({ message: errorMsg, status: 500 });
            setShowErrorPopupDiscovery(true);
          }
          setIsLoading(false);
        }
      );
    } catch (error) {
      console.log('XXX: error', error);
    }
  };

  const handleSupportMultiline = () => {
    dispatch(setSupportMultiLineColumn(!shouldMultiline));
    setShouldMultiline(!shouldMultiline);
  };

  const handleBackOnClick = () => {
    setSubMenuOptions(null);
  };

  const onActionMenuChange = (item: any) => {
    if (item.isSubMenu) {
      setSubMenuOptions(options);
    } else if (item.id === 'copy') {
      const selectedRows = rows
        .filter((row: any) => selectedGridRows.includes(row.id))
        .map((row: any) => `${row.domain}\r\n`);

      navigator.clipboard.writeText(selectedRows.join(''));
      dispatch(showSuccess('Copied to clipboard'));
      setAnchorEl(null);
    } else if (item.id === 'open') {
      const selectedRows = rows.filter((row: any) => selectedGridRows.includes(row.id)).map((row: any) => row.domain);
      selectedRows.forEach((row: any) => {
        window.open(`http://www.${row}`, '_blank');
      });
      setAnchorEl(null);
    } else if (item.id === 'comment') {
      setAnchorEl(null);
      dispatch(setDiscoveryInvestigationCommentIds(selectedGridRows));
      dispatch(setShowAddCommentPopupDiscoveryPending(true));
    }
  };

  // const open = Boolean(anchorEl);

  return !!headerData && !!Object.keys(headerData).length ? (
    <div className={classNames('DiscoveryScanReport', { 'support-multiline': shouldMultiline })}>
      {isLoading && <AppLoading />}
      <div className="header">
        <Text textSize={16} weight={500} textColor="#5F6575">
          Investigation Tool
        </Text>
        {!!headerData && !!Object.keys(headerData).length && (
          <>
            <div style={{ backgroundColor: '#C5C8CE', width: 1, height: '100%', margin: '0 16px' }} />
            <Text textSize={16} weight={500} textColor="#5F6575">
              {headerData.type}
            </Text>
            <div style={{ backgroundColor: '#C5C8CE', width: 1, height: '100%', margin: '0 16px' }} />
            <div className="icon-date">
              <div style={{ marginRight: 12 }}>{iconMap[headerData.scan_status as keyof typeof iconMap]}</div>
              {!!timeHeader && (
                <Text textSize={16} textColor="#5F6575" weight={500}>
                  {timeHeader}
                </Text>
              )}
            </div>
            {!!account && (
              <>
                <div style={{ backgroundColor: '#C5C8CE', width: 1, height: '100%', margin: '0 16px' }} />
                <Text textSize={15} weight={400} textColor="#5F6575">
                  Account: {account}
                </Text>
              </>
            )}
            <div style={{ backgroundColor: '#C5C8CE', width: 1, height: '100%', margin: '0 16px' }} />
            <Text textSize={15} weight={400} textColor="#5F6575">
              Scan ID: {params.discoveryScanId}
            </Text>
          </>
        )}
        <div style={{ flexGrow: 1 }} />
        <div style={{ display: 'flex', alignItems: 'center', marginRight: 16 }}>
          <Text style={{ marginRight: 8 }}>Support Multiline</Text>
          <CustomSwitch checked={shouldMultiline} onChange={handleSupportMultiline} />
        </div>
        {/* <div style={{ display: 'flex', alignItems: 'center', marginRight: 24 }}>
          <Text style={{ marginRight: 8 }}>status code 200 / 400</Text>
          <CustomSwitch checked={shouldOpenMask} onChange={() => setShouldOpenMask(!shouldOpenMask)} />
        </div> */}
        {/* {!isReportCompleted && ( */}
        <div style={{ marginRight: 24 }}>
          <Button
            text="Submit"
            onClick={handleOnSubmit}
            type="button"
            buttonStyle="main"
            size={'small'}
            className="submit"
            disabled={!rows.length || isReportCompleted}
          />
        </div>
        {/* )} */}
      </div>
      {/* <div className={classNames('investigation-tool-content', { 'no-events': isReportCompleted && !shouldOpenMask })}> */}
      <div className={classNames('investigation-tool-content')}>
        <div className="grid-wrapper">
          {!!data.columns.length && (
            <div style={{ height: '100%', zIndex: 1, display: 'flex' }}>
              <div className={classNames('drawer-wrapper', { open: panelIsOpen }, { closed: !panelIsOpen })}>
                <InvestigationLeftPanel
                  isOpen={panelIsOpen}
                  setPanelIsOpen={setPanelIsOpen}
                  scanId={params.discoveryScanId}
                />
              </div>
              <Grid
                columns={data.columns}
                rows={data.rows}
                count={data.count}
                path={data.rootPath}
                isMoreButton={data.isMoreButton}
                isCSVDownloadable={data.isCSVDownloadable}
                addedActions={(props: any) => {
                  setSelectedGridRows(props.ids);
                  return (
                    <Button
                      text="Action"
                      onClick={handleActionButtonOnClick}
                      type="button"
                      buttonStyle="secondary"
                      size={'small'}
                      disabled={!props.ids.length}
                      className={classNames({ disabled: !props.ids.length })}
                    />
                  );
                }}
                identifier={data.identifier}
                initialState={{
                  pinnedColumns: { right: ['actions'] }
                }}
              />
            </div>
          )}
        </div>
      </div>

      <Popover
        open={isActionMenuOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        className="discovery-investigation-action-dropdown"
        disableScrollLock={true}
      >
        {!!isActionMenuOpen && !subMenuOptions?.length && (
          <Grow in={isActionMenuOpen} timeout={200}>
            <div className="sub-menu-wrapper">
              <Text textSize={13} weight={400} style={{ padding: '12px 12px 4px 12px' }}>
                Update Verdicts
              </Text>
              {options.map((option: any) => (
                <div
                  key={option.name}
                  className={classNames('option-item')}
                  onClick={handleActionButtonItemOnClick(option)}
                >
                  <div
                    style={{
                      width: 10,
                      height: 10,
                      borderRadius: '50%',
                      backgroundColor: option.color,
                      marginRight: 12,
                      border: option.label === 'None' ? '1px dashed #B7BBC5' : 'none'
                    }}
                  />
                  <Text textColor="#5D6576" textSize={13}>
                    {option.label}
                  </Text>
                </div>
              ))}
              <div style={{ borderTop: '1px solid #ecedff' }} />
              <div className="more-actions" onClick={() => setSubMenuOptions(OPTIONS)}>
                <Text textSize={13} textColor="#5D6576">
                  More
                </Text>
                <div className="submenu-icon">{iconArrow}</div>
              </div>
            </div>
          </Grow>
        )}
        {!!isActionMenuOpen && !!subMenuOptions?.length && (
          <Grow in={isActionMenuOpen} timeout={200}>
            <div className="main-menu-wrapper">
              <div className="sub-menu-title" onClick={handleBackOnClick}>
                <div className="submenu-icon">{iconArrow}</div>
                <Text textSize={13} weight={500} style={{ padding: '12px 12px 4px 12px' }}>
                  More
                </Text>
              </div>
              {OPTIONS.map((item: any, key: number) => {
                return (
                  <div className={classNames('main-menu-item')} key={key}>
                    {item.icon && (
                      <div style={{ marginRight: item.icon === 'comment' ? 5 : 8, position: 'relative', top: 2 }}>
                        {mapActionMenuIcons[item.icon as keyof typeof mapActionMenuIcons]}
                      </div>
                    )}
                    <div className={classNames('main-menu-label')} onClick={() => onActionMenuChange(item)}>
                      <Text textSize={13} textColor="#5D6576">
                        {item.label}
                      </Text>
                    </div>
                  </div>
                );
              })}
            </div>
          </Grow>
        )}
      </Popover>

      {/* <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        className="discovery-investigation-action-dropdown"
        disableScrollLock={true}
      >
        <>
          <Text textSize={13} weight={500} style={{ padding: '12px 12px 8px 12px' }}>
            Status
          </Text>
          {options.map((option: any) => (
            <div
              key={option.name}
              className={classNames('option-item')}
              onClick={handleActionButtonItemOnClick(option)}
              style={{ borderTop: option.label === 'None' ? '1px solid #ecedff' : 'none' }}
            >
              <div
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: '50%',
                  backgroundColor: option.color,
                  marginRight: 12,
                  border: option.label === 'None' ? '1px dashed #B7BBC5' : 'none'
                }}
              />
              <Text textColor="#5D6576" textSize={13}>
                {option.label}
              </Text>
            </div>
          ))}
        </>
      </Popover> */}

      <PopUp setVisible={handlePopupVisible} visible={showAddCommentPopupDiscoveryPending}>
        <AddDiscoveryCommentPopup setVisible={handlePopupVisible} />
      </PopUp>
      <PopUp setVisible={setShowInfoPopupDiscovery} visible={showInfoPopupDiscovery}>
        <InfoDiscoveryPopup setVisible={setShowInfoPopupDiscovery} onSave={handleSaveOnClick} />
      </PopUp>
      <PopUp setVisible={setShowErrorPopupDiscovery} visible={showErrorPopupDiscovery}>
        <ErrorDiscoveryPopup setVisible={setShowErrorPopupDiscovery} errorData={errorData} />
      </PopUp>
      <PopUp setVisible={setShowWarningPopupDiscovery} visible={showWarningPopupDiscovery}>
        <WarningDiscoveryPopup setVisible={setShowWarningPopupDiscovery} onSave={handleSaveOnClick} />
      </PopUp>
      {saveCustomViewVisible && <SaveCustomView onClose={() => setSaveCustomViewVisible(false)} />}
    </div>
  ) : null;
};

const DiscoveryScanReport = () => {
  return (
    <DiscoveryScanReportContextProvider>
      <DiscoveryScanReportModule />
    </DiscoveryScanReportContextProvider>
  );
};

export default DiscoveryScanReport;
