import { RestApi } from 'common/services/rest-api.service';
import { lookupHighlights } from './ThreatsReportContext';
import queryString from 'query-string';

const rules = {
  action_items: {
    singleAsset: 'discovery/org-assets/[ASSET_NAME]/detailed/',
    details: 'remediation/action-items/open/[ID]/'
  },
  active_protection: {
    singleAsset: 'discovery/org-assets/[ASSET_NAME]/detailed/',
    details: 'remediation/action-items/open/[ID]/'
  },
  discovery: {
    singleAsset: 'discovery/org-assets/[ASSET_NAME]/detailed/',
    action_items:
      'discovery/org-assets/[ASSET_NAME]/action-items/?fields=id,title,summary,type,urgency,first_opened_at,last_closed_at,is_open',
    dependent: {
      details: 'remediation/action-items/open/[ACTION_ITEM_ID]/'
    }
  },
  network_assessment: {
    singleAsset: 'discovery/org-assets/[ASSET_NAME]/detailed/',
    tests: 'discovery/org-assets/[ASSET_NAME]/tests/?fields=id,name,category,info,grade',
    dependent: {
      test: 'tests/[TEST_ID]/'
    }
  },
  cti: {
    details: 'assessments/drps/compromised-devices/[ID]/'
  }
};

const getRule = (item: any, nextRow: any) => {
  const { section } = item;
  const rule = rules[section as keyof typeof rules];
  const params = queryString.parse(window.location.search);

  return Object.entries(rule).reduce((all: any, [key, value]: any) => {
    let group = '';
    if (key === 'dependent') {
      if (!!params.global_filter) {
        group = value[Object.keys(value)[0]].includes('/?')
          ? `&global_filter=${params.global_filter}`
          : `?global_filter=${params.global_filter}`;
      }
      return {
        ...all,
        dependent: {
          ...(all.dependent ? all.dependent : {}),
          [Object.keys(value)[0]]: `${value[Object.keys(value)[0]]}${group}`
        }
      };
    }
    if (!!params.global_filter) {
      group = value.includes('/?')
        ? `&global_filter=${params.global_filter}`
        : `?global_filter=${params.global_filter}`;
    }
    return {
      ...all,
      call: {
        ...(all.call ? all.call : {}),
        [key]: `${value}${group}`.replace('[ASSET_NAME]', nextRow.asset).replace('[ID]', nextRow.id)
      }
    };
  }, {});
};

const sortField = (rule: string, field: string) => (a: any, b: any) => {
  const A = a[field].toUpperCase();
  const B = b[field].toUpperCase();
  if (A < B) {
    return rule === 'lowest' ? -1 : 1;
  }
  if (A > B) {
    return rule === 'lowest' ? 1 : -1;
  }

  return 0;
};

const fetchHightlightData = async (jsonHighlightData: any, nextRow: any) => {
  const { call, dependent } = getRule(jsonHighlightData, nextRow);
  let fetchedData: any = await RestApi.getPromiseAll(call);
  let laterCallData: any = {};
  let laterCallUrls: any;

  if (!!dependent) {
    if (jsonHighlightData.section === 'discovery') {
      laterCallUrls = {
        details: dependent.details.replace('[ACTION_ITEM_ID]', fetchedData.action_items.results[0]?.id)
      };
    } else if (jsonHighlightData.section === 'network_assessment') {
      let test: any;
      const testsResults = fetchedData.tests.results;
      const { used_tests: usedTests } = lookupHighlights;

      // console.log('XXX: lookupHighlights: ', lookupHighlights);
      if (!!jsonHighlightData.rules) {
        const rules = jsonHighlightData.rules.split(':');
        if (rules[0] === 'includes') {
          test = testsResults.find((t: any) => {
            const name = t.name.toLowerCase();
            return rules[1].split(',').includes(name) && !usedTests.includes(name);
          });
        } else if (rules[0] === 'not_includes') {
          test = testsResults.find((t: any) => {
            const name = t.name.toLowerCase();
            // const name = nextRow.selected_failed_test;
            // return !notIncluded.includes(name) && !usedTests.includes(name);
            return name === nextRow.selected_failed_test.toLowerCase() && !usedTests.includes(name);
          });
        } else if (rules[0] === 'lowest' || rules[0] === 'highest') {
          const field = rules[1];
          const rule = rules[0];
          const filtered = fetchedData.tests.results
            .filter((f: any) => rules[2].split(',').includes(f.name.toLowerCase()))
            .sort(sortField(rule, field));
          test = filtered[0];
        }
      } else {
        test = fetchedData.tests.results.find(
          (t: any) => nextRow.network_failed_tests.includes(t.name) && !usedTests.includes(t.name.toLowerCase())
        );
      }
      laterCallUrls = {
        test: dependent.test.replace('[TEST_ID]', test?.id)
      };
      if (!!test) {
        lookupHighlights.used_tests.push(test.name.toLowerCase());
      }
    }
    laterCallData = await RestApi.getPromiseAll(laterCallUrls);
  }
  fetchedData = {
    ...fetchedData,
    ...laterCallData,
    type: jsonHighlightData.section,
    pdf_page_type: jsonHighlightData.pdf_page_type,
    pdf_should_display: jsonHighlightData.pdf_should_display,
    id: jsonHighlightData.id,
    row: nextRow,
    title: jsonHighlightData.title
  };
  return fetchedData;
};

export { fetchHightlightData };
