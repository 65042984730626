import { useDispatch } from 'react-redux';
import './NoRules.scss';
import { Button } from '@cyberpion/cyberpion-ui';
import { addRulesGroup } from 'features/GroupsSlice';

const RuleWrapper = (props: any) => {
  const dispatch = useDispatch<any>();

  return (
    <div className="NoRules">
      <div>
        <img src="/assets/images/empty-state-panel.svg" alt="Empty" />
        <p>Your rulebook is empty. Add your first rule to automate your workflow.</p>
        <Button
          text="+ Add Rule"
          onClick={() => {
            dispatch(addRulesGroup());
          }}
          size="small"
          type="button"
          buttonStyle="secondary"
        />
      </div>
    </div>
  );
};

export default RuleWrapper;
