import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { GridUtilities } from 'common/components/grid/components/gridUtilities/GridUtilities';
import { ITechnology, ISwitchTabs } from 'pages/inventory/singleAsset/interface';
import { ManageNotData } from 'common/components/manageNotData/ManageNotData';
import { makeStyles } from '@material-ui/core/styles';
import { getRows } from 'features/GridSlice';
import { AppDispatch, IReduxState } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '@cyberpion/cyberpion-ui';
import { PopSide } from 'common/components/PopSide/PopSide';
import { Popover } from '@mui/material';
import { TechnicalDetails } from 'common/components/TechnicalDetails/TechnicalDetails';
import CvesPopSide from './CvesPopSide';
import { AppLoading } from 'common/components/appLoading/appLoading';

import './tabTechnologies.scss';

const iconEOL = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      d="M1.8335 4.66667C1.8335 2.36548 3.69898 0.5 6.00016 0.5C8.30135 0.5 10.1668 2.36548 10.1668 4.66667V11.5H1.8335V4.66667Z"
      stroke="#F7981F"
    />
    <line y1="11.5" x2="12" y2="11.5" stroke="#F7981F" />
  </svg>
);

const useStyles = makeStyles({
  popoverRoot: {
    marginTop: '10px',
    overflow: 'hidden',
    border: '1px solid #ECECEC',
    backgroundColor: '#f9faff',
    boxShadow:
      '0px 77.291px 61.833px 0px rgba(108, 73, 172, 0.07), 0px 32.29px 25.832px 0px rgba(108, 73, 172, 0.05), 0px 17.264px 13.811px 0px rgba(108, 73, 172, 0.04), 0px 9.678px 7.742px 0px rgba(108, 73, 172, 0.04), 0px 5.14px 4.112px 0px rgba(108, 73, 172, 0.03), 0px 2.139px 1.711px 0px rgba(108, 73, 172, 0.02)',

    '& .technologies-tab-technical-details': {
      margin: '10px !important',
      '& .react-json-view': { fontSize: '11px !important', backgroundColor: '#f9faff !important' }
    },
    '& .technical-details-header-scope': {
      marginBottom: '0px !important'
    }
  }
});

export function TabTechnologies(props: ISwitchTabs) {
  const [showPopSide, setShowPopSide] = useState(false);
  const [technicalDetails, setTechnicalDetails] = useState<any>(null);
  const [itemData, setItemData] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const { rows, nextPath, loading } = useSelector((state: IReduxState) => state.grid);
  const dispatch: AppDispatch = useDispatch();

  const classes = useStyles();

  useEffect(() => {
    dispatch(getRows());
  }, [dispatch]);

  function handleOnRowsScrollEnd() {
    if (nextPath) {
      dispatch(getRows());
    }
  }

  function manageMessages() {
    return <h4>Loading...</h4>;
  }

  const getShortenAppName = (appName: string): string => {
    if (!appName) {
      return '';
    }
    const appNameAsArray = appName.split(' ');

    if (appName.length <= 3) {
      return appName;
    }

    if (appNameAsArray.length > 1) {
      return `${appNameAsArray[0].charAt(0)}${appNameAsArray[1].charAt(0)}`;
    }

    return appName.substring(0, 2);
  };

  const getAppImage = (item: ITechnology): JSX.Element => {
    if (item.logo_url) {
      return <div className="app-logo-image" style={{ backgroundImage: `url('${item.logo_url}')` }} />;
    }

    return <div className="app-custom-logo">{getShortenAppName(item.label)}</div>;
  };

  const handleOnHide = (isHide: boolean) => {
    setShowPopSide(isHide);
  };

  const handleTechnicalDetailsOnClick = (item: any) => (event: React.MouseEvent<HTMLElement>) => {
    setTechnicalDetails(item.detection_details);
    setAnchorEl(event.target as HTMLDivElement);
  };

  const handleTechnicalDetailsClose = () => {
    setAnchorEl(null);
  };

  const handleCVEsOnClick = (item: any) => () => {
    setItemData(item);
    setShowPopSide(true);
  };

  const showTechnicalDetails = Boolean(anchorEl);

  return (
    <div className="tabTechnologies">
      <GridUtilities isMoreButton={props.isMoreButton} isCSVDownloadable={props.isCSVDownloadable} />
      <div className="scrollbar-common tabTechnologies-scope">
        <div id="scrollableDiv" style={{ height: '100%', overflow: 'auto' }}>
          {loading && <AppLoading />}
          {!loading && rows.length === 0 && <ManageNotData type={props.selectTab} />}
          {!loading && !!rows.length && (
            <InfiniteScroll
              dataLength={rows.length}
              next={handleOnRowsScrollEnd}
              hasMore={!!nextPath}
              loader={manageMessages()}
              scrollableTarget="scrollableDiv"
              className="tabTechnologies-items"
            >
              {rows.map((item: any, index: number) => {
                let eol;
                if (item.end_of_life) {
                  const y = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(new Date(item.end_of_life));
                  const m = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(new Date(item.end_of_life));
                  const d = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(new Date(item.end_of_life));
                  eol = `${m}/${d}/${y}`;
                }

                return (
                  <div key={index} className={`tabTechnologies-item`}>
                    <div className="tech-content">
                      <div className="tech-name-version">
                        <Text textSize={14} weight={500} style={{ marginLeft: 8 }}>
                          {item.label}
                        </Text>
                        {item.version && (
                          <Text textSize={14} textColor="#8E8F94" style={{ paddingLeft: 4 }}>{`V${item.version}`}</Text>
                        )}
                        <div style={{ flexGrow: 1 }} />
                        {item.end_of_life && (
                          <div className="eol-badge">
                            <div className="icon">{iconEOL}</div>
                            <Text textSize={13} textColor="#F7981F" style={{ paddingLeft: 6 }}>
                              {`EOL ${eol}`}
                            </Text>
                          </div>
                        )}
                      </div>
                      <div className="tech-details">
                        <div className="tech-image">{getAppImage(item)}</div>
                        <div className="tech-row-details">
                          <Text textSize={14} textColor="#8C909A">
                            Detection Method
                          </Text>
                          <Text textSize={14} textColor="#8C909A" style={{ marginTop: 4 }}>
                            {!!item.detection_method ? item.detection_method : 'N/A'}
                          </Text>
                          {item.detection_details && (
                            <div className="technical-details" onClick={handleTechnicalDetailsOnClick(item)}>
                              <Text textSize={14} textColor="#3659DE" style={{ marginTop: 8 }}>
                                Technical Details
                              </Text>
                            </div>
                          )}
                        </div>
                        <div style={{ flexGrow: 1 }} />
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div
                            onClick={handleCVEsOnClick(item)}
                            className="cves-count"
                            style={{
                              padding: '0px 8px',
                              borderRadius: 4,
                              backgroundColor: `${item.cves?.length ? '#F8DCE0' : '#F9FAFF'}`,
                              pointerEvents: `${item.cves?.length ? 'auto' : 'none'}`
                            }}
                          >
                            <Text textSize={36} family="Rajdhani" weight={600}>
                              {item.cves?.length ? item.cves?.length : 0}
                            </Text>
                          </div>
                          <Text
                            textColor="#898995"
                            style={{ whiteSpace: 'break-spaces', width: 'min-content', marginLeft: 8 }}
                          >
                            Known CVEs
                          </Text>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </InfiniteScroll>
          )}
        </div>
      </div>
      <Popover
        open={showTechnicalDetails}
        anchorEl={anchorEl}
        onClose={handleTechnicalDetailsClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        classes={{ paper: classes.popoverRoot }}
        disableScrollLock={true}
      >
        <div className="technologies-tab-technical-details">
          <TechnicalDetails data={technicalDetails} isActionItemsPDF={true} />
        </div>
      </Popover>
      {showPopSide && (
        <div className="technologies-cves-popside">
          <PopSide visible={setShowPopSide} onHide={handleOnHide}>
            <CvesPopSide data={itemData} onHide={handleOnHide} />
          </PopSide>
        </div>
      )}
    </div>
  );
}
