import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  clearAllFilters,
  getRows,
  inputSearch,
  removeAllRows,
  setFields,
  setfiltersResetDefault,
  sort
} from 'features/GridSlice';
import { selectView, setIsEdited } from 'features/ViewsSlice';

import './SavedViews.scss';
import { IconMenu } from 'common/components/Icons/IconMenu/IconMenu';
import { SavedViewOptionsMenu } from './SavedViewOptionsMenu';
import { EditSavedView } from './EditSavedView';
import { Common } from 'common/services/common.service';
import { NoIssuesIcon } from 'common/components/manageNotData/components/NoIssuesIcon/NoIssuesIcon';
import { TABSET as inventoryTabs } from 'pages/inventory/config';
import { TABSET as actionItemsTabs } from 'pages/action-items/config';
import { TABSET as assesmentsTabs } from 'pages/assesments/config';

const TABS: any = {
  'Action Items': actionItemsTabs,
  Discovery: inventoryTabs,
  Assesments: assesmentsTabs
};

export function SavedViews(props: any) {
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useDispatch<any>();
  const { viewSelected, genericViews, defaultViews, userCreatedViews, editModeId, isEdited } = useSelector(
    (state: any) => state.views
  );
  const [showMenu, setShowMenu] = useState<string | boolean>(false);

  const history = useHistory();

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  // useEffect(() => {
  //   return () => {
  //     // dispatch(selectView(null));
  //   };
  // }, [dispatch]);

  const viewOnClick = (view: any, index: number) => {
    if (viewSelected === view.id) {
      return;
    }

    dispatch(sort(''));
    dispatch(clearAllFilters());
    dispatch(inputSearch(''));
    dispatch(setFields([]));
    // dispatch(setGlobalFilter(''));
    dispatch(selectView(view.id));
    history.push(Common.getDecodedViewPath(view.path));
    dispatch(setIsEdited(false));
  };

  const backToDefault = (ev: React.MouseEvent<HTMLElement>) => {
    dispatch(removeAllRows());
    dispatch(sort(''));
    dispatch(clearAllFilters());
    dispatch(inputSearch(''));
    dispatch(setFields([]));

    if (
      defaultViews[props.page] &&
      defaultViews[props.page][props.tab] &&
      defaultViews[props.page][props.tab].views[0].path
    ) {
      history.push(Common.getDecodedViewPath(defaultViews[props.page][props.tab].views[0].path));
      dispatch(selectView(defaultViews[props.page][props.tab].views[0].id));
    } else {
      const tabs = TABS[props.page];
      const tab: any = Object.values(tabs).filter((tab: any) => window.location.pathname.includes(tab.link))[0];
      if (tab && tab.link) {
        dispatch(setfiltersResetDefault(true));
        history.push(Common.getDecodedViewPath(tab.defaultView));
        dispatch(selectView(null));
        dispatch(getRows());
      }
    }
    dispatch(setIsEdited(false));
  };

  const getItemHTML = (view: any, index: number, type: 'user' | 'generic') => {
    const isDefault = defaultViews[props.page] && defaultViews[props.page][props.tab]?.views[0].id === view.id;
    return (
      <li
        key={type + index}
        onClick={() => viewOnClick(view, index)}
        className={classNames({ selected: viewSelected === view.id })}
      >
        <div className="view-content-wrapper">
          <div>
            <div>
              {isDefault && <img className="default-icon" src={`/assets/images/star-yellow.svg`} alt="Default" />}
              {type === 'generic' && <img className="generic-icon" src={`/assets/images/logo-ionix.svg`} alt="Ionix" />}
              {type === 'user' && <img className="user-icon" src={`/assets/images/user.svg`} alt="User" />}
            </div>
            <div>{type === 'user' && editModeId === view.id ? <EditSavedView view={view} /> : view.label}</div>
          </div>
          <div
            className="menu"
            onClick={e => {
              e.stopPropagation();
              setShowMenu(showMenu === view.id ? false : view.id);
            }}
          >
            <IconMenu active={false} />
            {showMenu === view.id && (
              <SavedViewOptionsMenu
                type={type}
                isDefault={isDefault}
                view={view}
                onClose={() => {
                  setShowMenu(false);
                }}
              />
            )}
          </div>
        </div>
        {/* {viewSelected === index && isEdited(view.path) && (
          <div className="buttons">
            {viewSelected === index && isEdited(view.path) && <div className="edited-badge">Edited</div>}
            <span className="reset" onClick={resetOnClick(view)}>
              Reset
            </span>
          </div>
        )} */}
      </li>
    );
  };

  // if (!genericViews[props.page] || !genericViews[props.page][props.tab]) {
  //   return null;
  // }

  const isEmpty = () => {
    if (
      (userCreatedViews[props.page] && userCreatedViews[props.page][props.tab]?.views.length) ||
      (genericViews[props.page] && genericViews[props.page][props.tab]?.views.length)
    ) {
      return false;
    }
    return true;
  };

  const getNumOfViews = () => {
    return (
      (userCreatedViews[props.page] ? userCreatedViews[props.page][props.tab]?.views.length || 0 : 0) +
      (genericViews[props.page] ? genericViews[props.page][props.tab]?.views.length || 0 : 0)
    );
  };

  const getStyle = () => {
    if (props.source === 'grid') {
      return { overflow: 'scroll' };
    } else {
      return undefined;
    }
  };

  return (
    <div
      className={classNames('SavedViews', {
        open: open
      })}
    >
      <div className="header">
        <span>Saved Views</span>
        <span className={viewSelected || isEdited ? 'active' : ''} onClick={backToDefault}>
          Reset to default
        </span>
      </div>
      {/* <div className="scrollbar-common"> */}
      <div className="layer" style={getStyle()}>
        <ul className={classNames('views-list', `${getNumOfViews() > 5 ? 'long-list' : ''}`, { open: open })}>
          {userCreatedViews[props.page] &&
            userCreatedViews[props.page][props.tab]?.views.map((view: any, index: number) => {
              return getItemHTML(view, index, 'user');
            })}
          {genericViews[props.page] &&
            genericViews[props.page][props.tab]?.views.map((view: any, index: number) => {
              return getItemHTML(view, index, 'generic');
            })}
        </ul>
        {isEmpty() && (
          <div className="empty">
            <NoIssuesIcon subType={'card'} text="There are currenly no saved views for this page." />
          </div>
        )}
      </div>
    </div>
    // </div>
  );
}
