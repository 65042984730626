import React, { useState, useRef, useEffect } from 'react';
import { IFilters } from 'common/components/grid/components/gridUtilities/interface';

import { DropdownHeader } from '../dropdownHeader/DropdownHeader';
import { AppDispatch } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { getRows, removeFilter } from 'features/GridSlice';
import { Common } from 'common/services/common.service';
import { FilterFreeText } from '../filterFreeText/FilterFreeText';
import { ToggleButtons } from 'common/components/ToggleButtons/ToggleButtons';
import { FilterMultipleChoice } from '../filterMultipleChoice/FilterMultipleChoice';
import { IChoices } from 'common/interfaces/interface';
import { FiltersManage } from '../services/FiltersManage';
import './filterToggle.scss';

interface IFilterToggle {
  item: IFilters;
  onUpdateDataFilter: (item: IFilters) => void;
  activeFilters: any;
}
const FilterToggle = (props: IFilterToggle) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isToggled, setIsToggled] = useState<boolean>(false);
  const [view, setView] = useState('free text');
  const [listFilter, setListFilter] = useState<any>({});
  const [textFilter, setTextFilter] = useState<any>({});
  const { filters } = useSelector((state: any) => state.grid);
  const dispatch: AppDispatch = useDispatch();

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsToggled(false);
      document.removeEventListener('click', handleClickOutside, true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  useEffect(() => {
    const textItem = Common.cloneData(props.item);
    textItem.choices = textItem.text_choices || [];
    textItem.is_active_filter = false;
    setTextFilter(textItem);

    const listItem = Common.cloneData(props.item);
    listItem.choices = listItem.list_choices || [];

    setListFilter(listItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const copy = { ...props.item };
    if (filters[copy.name]) {
      copy.is_active_filter = true;
      const values = filters[copy.name].value.split(',');
      if (filters[copy.name].type === '__in') {
        copy.choices = copy.list_choices;
        copy.choices.forEach((choice: IChoices) => {
          if (choice.name !== null && choice.name.toString() && values.includes(choice.name.toString())) {
            choice.is_toggled = true;
          } else {
            choice.is_toggled = false;
          }
        });
        let { labels } = FiltersManage.mapMultipleChoiceFilter(copy.choices);
        copy.sub_label = !!labels?.length ? `: Select from list: ${labels?.length} selected` : '';
        setView('list');
        setListFilter(copy);
      } else {
        let selectedChoice: any;
        copy.choices = copy.text_choices;
        copy.choices.forEach((choice: any) => {
          if (choice.name === filters[copy.name].type) {
            choice.value = filters[copy.name].value;
            selectedChoice = choice;
          } else {
            choice.value = '';
          }
        });
        if (filters[copy.name].type === '__isnull') {
          copy.sub_label = filters[copy.name].value?.toLowerCase() === 'true' ? ': (Blank)' : ': Not (Blank)';
        } else {
          copy.sub_label = `: ${selectedChoice?.label} ${filters[copy.name].value}`;
        }
        setView('free text');
        setTextFilter(copy);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  // MUST CLEAR THE FILTERS otherwise it is in the url -> the switched filter will not work with the previous format
  // LIKE: freetext with __in format or multi choices with __contains format
  const onToggle = (option: string) => {
    dispatch(removeFilter(props.item.name));
    setView(option.toLowerCase());
    if (option === 'Free Text') {
      const choices = textFilter.choices.map((choice: any) => {
        choice.value = '';
        return choice;
      });
      setTextFilter({ ...textFilter, choices, is_active_filter: false, sub_label: '' });
    } else {
      const choices = listFilter.choices.map((choice: any) => {
        choice.is_toggled = false;
        return choice;
      });
      setListFilter({ ...listFilter, choices, sub_label: '', is_active_filter: false });
    }
    dispatch(getRows());
  };

  const handleHeaderOnChange = (item: IFilters) => {
    const cloneList = Common.cloneData(listFilter);
    dispatch(removeFilter(props.item.name));
    const choices = cloneList.choices.map((choice: any) => {
      choice.is_toggled = false;
      return choice;
    });
    cloneList.sub_label = '';
    setListFilter({ ...cloneList, choices, is_active_filter: false });

    const cloneText = Common.cloneData(textFilter);
    dispatch(removeFilter(props.item.name));
    const textChoices = cloneText.choices.map((choice: any) => {
      choice.value = '';
      return choice;
    });
    cloneText.sub_label = '';
    setTextFilter({ ...cloneText, textChoices, is_active_filter: false });

    setView('free text');
    props.onUpdateDataFilter(item);
  };

  const handleMultiChoiceOnChange = (item: IFilters) => {
    const clone = Common.cloneData(item);
    const areSelected = clone.choices.filter((choice: any) => choice.is_toggled)?.length;
    clone.sub_label = !!areSelected ? `: Select from list: ${areSelected} selected` : '';
    setListFilter(clone);
    props.onUpdateDataFilter(item);
  };

  const handleFreeTextOnChange = (item: IFilters, isApplyClicked: any) => {
    const clone = Common.cloneData(item);
    clone.sub_label = '';
    setTextFilter(clone);
    if (isApplyClicked) {
      setIsToggled(false);
    }
    props.onUpdateDataFilter(item);
  };

  // console.log('XXX: ', view);
  return (
    <>
      <DropdownHeader
        item={view === 'free text' ? textFilter : listFilter}
        isToggled={isToggled}
        onToggle={() => setIsToggled(!isToggled)}
        onChangeData={handleHeaderOnChange}
        isFilterToggle={true}
      />
      {isToggled && (
        <div ref={ref} className="filterGrid_scope">
          <div className="actions">
            <ToggleButtons options={['Free Text', 'List']} onToggle={onToggle} isToggle={view === 'list'} />
          </div>
          {view === 'free text' && (
            <FilterFreeText
              item={textFilter}
              onUpdateDataFilter={handleFreeTextOnChange}
              activeFilters={props.activeFilters}
              isFilterToggle={true}
              onCancel={() => setIsToggled(false)}
            />
          )}
          {view === 'list' && (
            <FilterMultipleChoice
              item={listFilter}
              onUpdateDataFilter={handleMultiChoiceOnChange}
              activeFilters={props.activeFilters}
              isFilterToggle={true}
            />
          )}
        </div>
      )}
    </>
  );
};

export default FilterToggle;
