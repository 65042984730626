import { useEffect, useState } from 'react';
import { IRootColumnPicker } from 'common/interfaces/interface';
import { RestApi } from 'common/services/rest-api.service';
import { Paths } from 'common/constants/paths';
import { TABSET } from './config';
import { ColumnsManage } from 'common/components/grid/columnsManage';
import { Grid } from 'common/components/grid/Grid';
import {
  captureState,
  returnToLastState,
  removeAllRows,
  resetQueryString,
  setFields,
  setInitialPath,
  clearAllFilters
} from 'features/GridSlice';
import { AppDispatch } from 'app/store';
import { useDispatch } from 'react-redux';
import { GridRowId, GridRowParams } from '@mui/x-data-grid-pro';
import { PopUp } from 'common/components/popUp/PopUp';
import { AlertPopup } from './AlertPopup/AlertPopup';
import { NotFound } from 'notFound/NotFound';
import { PopSide } from 'common/components/PopSide/PopSide';
import { SideAlert } from './SideAlert/SideAlert';
import { setColumnPicker, setShowColumnPicker } from 'features/columnPickerSlice';
import { CVESidePanel } from './CVESidePanel/CVESidePanel';

export interface IAssesments {
  rows?: any;
  path?: string;
  // columnPicker: IColumnPicker[];
  // showColumnPicker: boolean;
  isMoreButton: boolean;
  isCSVDownloadable: boolean;
  count: number;
  columns: Array<any>;
  rootPath: string;
  identifier: string;
}

export function AssesmentsRouting(props: any) {
  const [showGrid, setShowGrid] = useState<boolean>(false);
  const [assesmentsData, setAssesmentsData] = useState<IAssesments>(initData());
  const [alertPopupVisible, setAlertPopupVisible] = useState<boolean>(false);
  const [alertPopupParams, setAlertPopupParams] = useState<{}>({});
  const [CVEId, setCVEId] = useState<{}>({});
  const [noPermission, setNoPermission] = useState<boolean>(false);
  const [showSideAlert, setShowSideAlert] = useState<boolean>(false);
  const [showCVEPopside, setShowCVEPopside] = useState<boolean>(false);
  const [sideAlertId, setSideAlertId] = useState<GridRowId>();
  const [abortController, setAbortController] = useState<AbortController>();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(removeAllRows());
    getData(props.selectTab, props.subTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectTab, props.subTab]);

  useEffect(() => {
    dispatch(resetQueryString());
    return () => {
      dispatch(removeAllRows());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function initData(): IAssesments {
    return {
      rows: [],
      // columnPicker: [],
      // showColumnPicker: false,
      isMoreButton: true,
      isCSVDownloadable: false,
      count: 0,
      columns: [],
      rootPath: '',
      identifier: ''
    };
  }
  // @description : ColumnPicker data request. And checking which columns to display
  function getData(tab: string, subTab: string | undefined) {
    setShowGrid(false);
    setNoPermission(false);
    const columnPickerPath = props.subTab
      ? TABSET[tab].subTabs[props.subTab].columnPickerPath
      : TABSET[tab].columnPickerPath;
    if (abortController) {
      abortController.abort();
    }
    const abort_controller = new AbortController();
    setAbortController(abort_controller);
    RestApi.getData(Paths[columnPickerPath], true, abort_controller.signal).subscribe(
      (columns: IRootColumnPicker) => {
        // queryGrid.moreQueryChain = [];
        let fields = ColumnsManage.checkGridColumnsToDisplay(columns.data);
        dispatch(setFields(fields));
        const gridPath = props.subTab ? TABSET[tab].subTabs[props.subTab].gridPath : TABSET[tab].gridPath;
        dispatch(setInitialPath(Paths[gridPath]));
        dispatch(setColumnPicker(columns.data));
        dispatch(setShowColumnPicker(columns.is_displayable));

        setAssesmentsData({
          rows: [],
          // columnPicker: columns.data || [],
          // showColumnPicker: columns.is_displayable,
          isMoreButton: columns.is_more_button,
          isCSVDownloadable: columns.is_csv_downloadable,
          // path: path,
          count: 0,
          identifier: columns.identifier_field,
          columns: ColumnsManage.createColumns(
            columns.identifier_field,
            columns.data || [],
            {
              page: 'assesments',
              tab: props.selectTab
            },
            {
              cb: (row: any) => {
                if (props.subTab === 'cveIntelligence') {
                  setCVEId(row.id);
                  setShowCVEPopside(true);
                } else if (props.subTab === 'compromisedDevices') {
                  setSideAlertId(row.id);
                  setShowSideAlert(true);
                }
              }
            }
          ),
          rootPath: Paths[TABSET[tab].gridPath]
        });
        setShowGrid(true);
      },
      err => {
        if (err.status === 403) {
          setNoPermission(true);
        }
      }
    );
  }

  function onRowDoubleClick(params: GridRowParams) {
    if (props.selectTab === 'drps' && props.subTab === 'deepDarkWebMonitoring') {
      dispatch(captureState());
      dispatch(clearAllFilters());
      setAlertPopupParams({
        isMultiAlerts: !!params.row.sub_alerts_count,
        alertId: params.row.alert_id,
        name: params.row.name
      });
      setAlertPopupVisible(true);
    } else if (props.selectTab === 'drps' && props.subTab === 'compromisedDevices') {
      setSideAlertId(params.id);
      setShowSideAlert(true);
    }
  }

  const _setAlertPopupVisible = (open: boolean) => {
    if (!open) {
      // dispatch(removeAllRows())
      dispatch(returnToLastState());
      // getData(props.selectTab, props.subTab);
    }
    setAlertPopupVisible(open);
  };

  if (noPermission) {
    return <NotFound type="403" />;
  }

  return showGrid ? (
    <>
      <div style={{ height: '100%', zIndex: 1 }}>
        <Grid
          columns={assesmentsData.columns}
          rows={assesmentsData.rows}
          count={assesmentsData.count}
          path={assesmentsData.path}
          // columnPicker={assesmentsData.columnPicker}
          // showColumnPicker={assesmentsData.showColumnPicker}
          isMoreButton={assesmentsData.isMoreButton}
          isCSVDownloadable={assesmentsData.isCSVDownloadable}
          identifier={assesmentsData.identifier}
          pageTab={TABSET[props.selectTab].gridPath || TABSET[props.selectTab]?.subTabs[props.subTab]?.gridPath}
          onRowDoubleClick={onRowDoubleClick}
          page={props.page}
          savedViews={TABSET[props.selectTab].savedViews || TABSET[props.selectTab].subTabs[props.subTab].savedViews}
        />
        <PopUp setVisible={_setAlertPopupVisible} visible={alertPopupVisible}>
          <AlertPopup data={alertPopupParams} setVisible={_setAlertPopupVisible} />
        </PopUp>
        {showSideAlert && (
          <PopSide visible={showSideAlert} onHide={() => setShowSideAlert(false)}>
            <SideAlert id={sideAlertId} />
          </PopSide>
        )}
        {showCVEPopside && (
          <PopSide className="wide" visible={showCVEPopside} onHide={() => setShowCVEPopside(false)}>
            <CVESidePanel id={CVEId} onClose={() => setShowCVEPopside(false)} />
          </PopSide>
        )}
      </div>
    </>
  ) : (
    <div className="grid-loading"></div>
  );
}
