import React, { useState } from 'react';
import { Text } from '@cyberpion/cyberpion-ui';
import { Popover } from '@mui/material';
import { useSelector } from 'react-redux';
import { IReduxState } from 'app/store';
import classNames from 'classnames';

import './DiscoveryPopoverListWithChips.scss';

const DiscoveryPopoverListWithChips = (props: any) => {
  const { supportMultiLineColumn } = useSelector((state: any) => state.grid);
  const { columnPicker } = useSelector((state: IReduxState) => state.columnPicker);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const isMultilineField = columnPicker.find((item: any) => item.name === props.field)?.is_multiline_support;

  const handlePopoverOpen = (event: any) => {
    if (!anchorEl) setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className={classNames('DiscoveryPopoverListWithChips', { 'multiline-column': isMultilineField })}>
      {!supportMultiLineColumn || !isMultilineField ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div onClick={handlePopoverOpen} className="grid-link-field" style={{ whiteSpace: 'nowrap' }}>
            <Text textColor="#6D6D7A" isText>
              {!!props.value?.length ? props.value[0].value : ''}
            </Text>
          </div>
          {!!props.value?.length &&
            !!props.value[0]?.tags?.length &&
            props.value[0].tags.map((item: string, index: number) => (
              <div
                key={index}
                className="popover-chip-item"
                style={{
                  border: '1px solid #D0D1D6',
                  backgroundColor: item !== 'blacklisted' ? '#a5bcf880' : '#CCCCCC',
                  whiteSpace: 'nowrap'
                }}
              >
                <Text textColor="#6D6D7A">{item}</Text>
              </div>
            ))}
          {!!props.value?.length && props.value?.length > 1 && (
            <div className="more-chip info-panel-count" onClick={handlePopoverOpen}>
              <Text textColor="#4A79E7">+{`${props.value?.length - 1}`}</Text>
            </div>
          )}
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', padding: '8px 0' }}>
          {!!props.value?.length &&
            props.value.slice(0, 10).map(({ value, tags }: { value: string; tags: any }) => (
              <div
                key={value}
                className="option-item"
                style={{ display: 'flex', alignItems: 'center', padding: '2px 0', whiteSpace: 'nowrap' }}
              >
                <Text textColor="#5D6576" textSize={13} style={{ whiteSpace: 'nowrap', marginRight: 12 }}>
                  {value}
                </Text>
                <div style={{ flexGrow: 1 }}></div>
                {!!tags?.length &&
                  tags.map((item: string) => (
                    <div
                      className="popover-chip-item"
                      style={{
                        border: '1px solid #D0D1D6',
                        backgroundColor: item !== 'blacklisted' ? '#a5bcf880' : '#CCCCCC'
                      }}
                    >
                      <Text textColor="#6D6D7A" style={{ whiteSpace: 'nowrap' }}>
                        {item}
                      </Text>
                    </div>
                  ))}
              </div>
            ))}
          {!!props.value?.length && props.value?.length > 10 && (
            <div className="more-chip info-panel-count" onClick={handlePopoverOpen}>
              <Text textColor="#4A79E7">+{`${props.value?.length - 10}`}</Text>
            </div>
          )}
        </div>
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        // classes={{ paper: classes.popoverRoot }}
        disableScrollLock={true}
        className="discovery-integration-whois-org"
      >
        <div className="options-wrapper">
          {!!props.value?.length &&
            props.value.map(({ value, tags }: { value: string; tags: any }) => (
              <div key={value} className="option-item">
                <Text textColor="#5D6576" textSize={13} style={{ whiteSpace: 'nowrap', marginRight: 12 }} isText>
                  {value}
                </Text>
                <div style={{ flexGrow: 1 }}></div>
                {!!tags?.length &&
                  tags.map((item: string, index: number) => (
                    <div
                      key={index}
                      className="popover-chip-item"
                      style={{
                        border: '1px solid #D0D1D6',
                        backgroundColor: item !== 'blacklisted' ? '#a5bcf880' : '#CCCCCC'
                      }}
                    >
                      <Text textColor="#6D6D7A">{item}</Text>
                    </div>
                  ))}
              </div>
            ))}
        </div>
      </Popover>
    </div>
  );
};

export default DiscoveryPopoverListWithChips;
