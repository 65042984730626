import { Text } from '@cyberpion/cyberpion-ui';
import { IReduxState } from 'app/store';
import classNames from 'classnames';
import { DropdownWithAction } from 'common/components/DropdownWithAction/DropdownWithAction';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';

import './PopSideTabs.scss';

const iconDetails = (color: string) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M13 13.8536V5.5L9.50017 2H4V11.5V13.5C4 13.6326 4.05268 13.7598 4.14644 13.8536C4.2402 13.9473 4.36737 14 4.49998 14H13"
      stroke={color}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M9.5 2V5.5H13" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10.5 8.5H6.5" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8.5 10.5H6.5" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

const iconCVEs = (color: string) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M2.23398 12.763L7.55976 2.87221C7.74855 2.52161 8.25145 2.52161 8.44023 2.87221L13.766 12.7629C13.9454 13.0961 13.7041 13.5 13.3258 13.5L2.67422 13.5C2.29588 13.5 2.05461 13.0961 2.23398 12.763Z"
      stroke={color}
    />
    <path d="M8 6.5V9.5" stroke={color} />
    <path d="M8 10.5V11.5" stroke={color} />
  </svg>
);

const iconComment = (color: string) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.49003 12.8668C5.47696 13.5798 6.68941 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 9.2893 2.40666 10.4836 3.09871 11.4617L2.70601 13.2948L4.49003 12.8668ZM2.03316 11.6618C1.37798 10.5961 1 9.34099 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C6.64169 15 5.37162 14.6122 4.2972 13.9414L1.39566 14.6377L2.03316 11.6618Z"
      fill={color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.5 7C11.5 6.72386 11.2761 6.5 11 6.5H6C5.72386 6.5 5.5 6.72386 5.5 7C5.5 7.27614 5.72386 7.5 6 7.5H11C11.2761 7.5 11.5 7.27614 11.5 7Z"
      fill={color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.5 9C9.5 8.72386 9.27614 8.5 9 8.5H6C5.72386 8.5 5.5 8.72386 5.5 9C5.5 9.27614 5.72386 9.5 6 9.5H9C9.27614 9.5 9.5 9.27614 9.5 9Z"
      fill={color}
    />
  </svg>
);

const iconGraph = (color: string) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <rect
      x="8.05859"
      y="5.7657"
      width="3.36057"
      height="3.36057"
      rx="1.68028"
      transform="rotate(45 8.05859 5.7657)"
      stroke={color}
    />
    <line x1="8.18262" y1="3.1709" x2="8.18262" y2="6.34737" stroke={color} stroke-linecap="round" />
    <line x1="13.0293" y1="8.18262" x2="9.85283" y2="8.18262" stroke={color} stroke-linecap="round" />
    <path d="M11.9012 4.43292L9.35327 6.84739" stroke={color} stroke-linecap="round" />
    <rect
      x="0.5"
      y="-0.5"
      width="1.50588"
      height="1.50588"
      rx="0.752941"
      transform="matrix(1 0 0 -1 6.84668 2.50586)"
      stroke={color}
    />
    <rect
      x="13.4473"
      y="4.25879"
      width="1.50588"
      height="1.50588"
      rx="0.752941"
      transform="rotate(-180 13.4473 4.25879)"
      stroke={color}
    />
    <rect
      x="-0.5"
      y="0.5"
      width="1.50588"
      height="1.50588"
      rx="0.752941"
      transform="matrix(-1 0 0 1 14.2002 6.84668)"
      stroke={color}
    />
    <line
      x1="0.5"
      y1="-0.5"
      x2="3.67647"
      y2="-0.5"
      transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 7.68262 13.5293)"
      stroke={color}
      stroke-linecap="round"
    />
    <path d="M11.9012 11.7673L9.35327 9.3528" stroke={color} stroke-linecap="round" />
    <rect x="7.34668" y="13.1943" width="1.50588" height="1.50588" rx="0.752941" stroke={color} />
    <rect
      x="-0.5"
      y="0.5"
      width="1.50588"
      height="1.50588"
      rx="0.752941"
      transform="matrix(-1 0 0 1 12.9473 11.4414)"
      stroke={color}
    />
    <line
      x1="0.5"
      y1="-0.5"
      x2="3.67647"
      y2="-0.5"
      transform="matrix(1 0 0 -1 2.6709 7.68262)"
      stroke={color}
      stroke-linecap="round"
    />
    <path d="M4.29898 4.43292L6.84693 6.84739" stroke={color} stroke-linecap="round" />
    <rect
      x="0.5"
      y="-0.5"
      width="1.50588"
      height="1.50588"
      rx="0.752941"
      transform="matrix(1 0 0 -1 2.25293 3.75879)"
      stroke={color}
    />
    <rect x="1.5" y="7.34668" width="1.50588" height="1.50588" rx="0.752941" stroke={color} />
    <path d="M4.29898 11.7673L6.84693 9.3528" stroke={color} stroke-linecap="round" />
    <rect x="2.75293" y="11.9414" width="1.50588" height="1.50588" rx="0.752941" stroke={color} />
  </svg>
);

interface IPopSideTabs {
  data: any;
  tabActive: string;
  setTabActive: (tab: string) => void;
}
const PopSideTabs: React.FC<IPopSideTabs> = ({ data, tabActive, setTabActive }) => {
  const flags = useFlags();
  const { commentsTabCount } = useSelector((state: IReduxState) => state.popSide);

  return (
    <div className="PopSideTabs">
      <div className={classNames('ai-popside-tab')} style={{ marginRight: 8 }} onClick={() => setTabActive('details')}>
        <div className={classNames('icon', { active: tabActive === 'details' })}>
          {iconDetails(tabActive === 'details' ? '#3455DD' : '#6D6D7A')}
        </div>
        <Text textSize={14} textColor={tabActive === 'details' ? '#3455DD' : '#6D6D7A'} weight={500}>
          Details
        </Text>
        {/* <div style={{ height: 1, width: '100%', backgroundColor: '#3455DD' }}></div> */}
      </div>
      {!!data?.cves?.length && (
        <div className={classNames('ai-popside-tab')} style={{ margin: '0 8px' }} onClick={() => setTabActive('cves')}>
          <div className={classNames('icon', { active: tabActive === 'cves' })}>
            {iconCVEs(tabActive === 'cves' ? '#3455DD' : '#6D6D7A')}
          </div>
          <Text textSize={14} textColor={tabActive === 'cves' ? '#3455DD' : '#6D6D7A'} weight={500}>{`CVEs (${data?.cves
            ?.length || 0})`}</Text>
        </div>
      )}
      <div
        className={classNames('ai-popside-tab')}
        style={{ margin: '0 8px' }}
        onClick={() => setTabActive('comments')}
      >
        <div className={classNames('icon', { active: tabActive === 'comments' })}>
          {iconComment(tabActive === 'comments' ? '#3455DD' : '#6D6D7A')}
        </div>
        <Text textSize={14} textColor={tabActive === 'comments' ? '#3455DD' : '#6D6D7A'} weight={500}>
          {!!commentsTabCount ? `Comments (${commentsTabCount})` : 'Comments'}
        </Text>
      </div>
      {flags.isGraphTab && (
        <div className={classNames('ai-popside-tab')} onClick={() => setTabActive('graph')}>
          <div className={classNames('icon', { active: tabActive === 'graph' })}>
            {iconGraph(tabActive === 'graph' ? '#3455DD' : '#6D6D7A')}
          </div>
          <Text textSize={14} textColor={tabActive === 'graph' ? '#3455DD' : '#6D6D7A'} weight={500}>
            Attack Surface Graph
          </Text>
        </div>
      )}
      {!data.last_closed_at && (
        <>
          <div style={{ flexGrow: 1, paddingRight: 8 }} />
          <DropdownWithAction row={data} />
        </>
      )}
    </div>
  );
};

export default PopSideTabs;
