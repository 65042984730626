import { useEffect, useRef, useState } from 'react';
import './AddBulk.scss';
import { Button } from '@cyberpion/cyberpion-ui';
import { useClickOutside } from 'hooks/useClickOutside';
import classNames from 'classnames';

const AddBulk = (props: any) => {
  const [input, setInput] = useState<string>(props.values || '');
  const [visible, setVisible] = useState<boolean>(false);

  const ref = useRef(null);

  useClickOutside(ref, () => {
    if (visible) {
      props.onClose();
    }
  });

  useEffect(() => {
    setTimeout(() => setVisible(true), 100);
    // setTimeout(() => setVisible(true), 1000);
    return () => {
      setVisible(false);
    };
  }, []);

  return (
    <div className={classNames('AddBulk', { visible: visible })} ref={ref}>
      <h6>Add Items</h6>
      <p>Each item should appear in a separate line</p>
      <textarea placeholder="Type here..." onChange={e => setInput(e.target.value)}>
        {input}
      </textarea>
      <div className="buttons-wrapper">
        <Button text="Cancel" size="small" buttonStyle="secondary" onClick={props.onClose} type="button" />
        <Button
          disabled={!input.length}
          text="Add"
          size="small"
          buttonStyle="main"
          onClick={() => {
            props.onAdd(input);
          }}
          type="button"
        />
      </div>
    </div>
  );
};

export default AddBulk;
