import { useState, useEffect } from 'react';
import { RestApi } from 'common/services/rest-api.service';
import './TabGraph.scss';
import { ConnectionsGraph, Popup } from '@cyberpion/cyberpion-ui';
import { QuickViewPanel } from 'common/components/quickViewPanel/QuickViewPanel';
// import { ActionItemsInfo } from 'common/components/popUp/components/actionItemsInfo/ActionItemsInfo';
// import { PopUp } from 'common/components/popUp/PopUp';
import { env } from 'env';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from 'app/store';
import { setGraphFullScreen } from 'features/GeneralSlice';
import { AppLoading } from 'common/components/appLoading/appLoading';
import { showCustomSuccess } from 'features/AlertSlice';
import { PopSide } from 'common/components/PopSide/PopSide';
import ActionItemsPopside from 'pages/action-items/popside/ActionItemsPopside';
import { useHistory, useLocation } from 'react-router-dom';

interface ITabGraph {
  asset: string;
}

export function TabGraph({ asset }: ITabGraph) {
  const [dataQuickViewPanel, setDataQuickViewPanel] = useState<any>({});
  const [showQuickViewPanel, setShowQuickViewPanel] = useState<boolean>(false);
  const [showConnection, setShowConnection] = useState<boolean>(false);
  const [connectionDate, setConnectionData] = useState<any>({});
  const [actionItemData, setActionItemData] = useState<any>({});
  const [actionItemVisible, setActionItemVisible] = useState<boolean>(false);
  const [isInternalAsset, setIsInternalAsset] = useState<any>(null);
  const [activeProtectionList, setActiveProtectionList] = useState<string[]>([]);
  const [activeProtectionReady, setActiveProtectionReady] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch<any>();
  const location = useLocation();
  const history = useHistory();

  const { isGraphFullScreen } = useSelector((state: IReduxState) => state.general);
  const getData = async (asset: string, isIncoming?: boolean) => {
    if (isIncoming) {
      const data = await RestApi.simpleAsyncGet(`discovery/org-assets/${asset}/graph/connections-incoming/`);
      setLoading(false);
      if (data.relations.length < 2) {
        displayError();
      }
      return data;
    } else {
      const data = await RestApi.simpleAsyncGet(`discovery/org-assets/${asset}/graph/`);
      setLoading(false);
      return data;
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('open_popside') && queryParams.get('open_popside') === 'true' && queryParams.has('ai_id')) {
      const url = `remediation/action-items/open/${queryParams.get('ai_id')}/`;
      RestApi.getData(url).subscribe(
        (response: any) => {
          setActionItemData(response);
          queryParams.delete('open_popside');
          queryParams.delete('is_open');
          queryParams.delete('ai_id');
          history.replace({
            search: queryParams.toString()
          });
          setActionItemVisible(true);
        },
        error => {}
      );
    }

    RestApi.getData(
      `remediation/active-protection/?fields=protected_host&is_actively_protected=1&asset=${asset}&limit=1000`
    ).subscribe(res => {
      const activeProtectionList = res.results.map((row: any) => row.protected_host);
      setActiveProtectionList(activeProtectionList);
      setActiveProtectionReady(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const displayError = () => {
    dispatch(showCustomSuccess({ title: ' ', text: 'No additional information to show' }));
  };

  const onDisplay = (asset: string, isInternal: boolean) => {
    RestApi.getData(`discovery/${isInternal ? 'org-assets' : 'digital-supply-chain'}/${asset}/`).subscribe(
      (response: any) => {
        if (!response?.asset) {
          displayError();
        } else {
          setIsInternalAsset(isInternal);
          setDataQuickViewPanel(response);
          setShowQuickViewPanel(true);
        }
      },
      err => {
        displayError();
      }
    );
  };

  const onClickLinkWithActionItem = (id: string) => {
    let url = `remediation/action-items/open/${id}/`;
    RestApi.getData(url).subscribe(
      (response: any) => {
        setActionItemData(response);
        setActionItemVisible(true);
      },
      err => {
        displayError();
      }
    );
  };

  const onClickLink = (from: string, to: string, type: string) => {
    let url = `connections/?source=${from}&target=${to}&type=${type}`;
    RestApi.getData(url).subscribe(
      (response: any) => {
        if (response?.results?.length === 0) {
          displayError();
        } else {
          setConnectionData({
            data: response.results[0],
            title: 'Connections',
            subtitle: response.results[0].from_source,
            metadata: {}
          });
          setShowConnection(true);
        }
      },
      error => {
        displayError();
      }
    );
  };

  if (!activeProtectionReady) {
    return null;
  }

  return (
    <div className={classNames('TabGraph', { 'full-screen': isGraphFullScreen })}>
      <QuickViewPanel
        setVisible={setShowQuickViewPanel}
        visible={showQuickViewPanel}
        nameTab={isInternalAsset ? 'internalAssets' : 'digitalSupplyChain'}
        data={dataQuickViewPanel}
        identifier={'asset'}
      />
      {actionItemVisible && (
        <div className="action-items-popside">
          <PopSide visible={actionItemVisible} onHide={setActionItemVisible}>
            {/* <ActionItemsInfo data={dataRow} setVisible={setVisible} /> */}
            <ActionItemsPopside data={actionItemData} onHide={setActionItemVisible} showActions={false} />
          </PopSide>
        </div>
      )}
      {/* <PopUp setVisible={setActionItemVisible} visible={actionItemVisible}>
        <ActionItemsInfo data={actionItemData} setVisible={setActionItemVisible} />
      </PopUp> */}
      <Popup
        title={connectionDate.title}
        subtitle={connectionDate.subtitle}
        onClose={() => setShowConnection(false)}
        open={showConnection}
        list={connectionDate.data}
        metadata={connectionDate.metadata}
      />
      <div id="GRAPH" style={{ backgroundImage: `url('/assets/images/pixel.svg')` }}>
        <div className={classNames('top-gradient', { show: !loading })}></div>
        {loading && <AppLoading />}
        <ConnectionsGraph
          size={{
            width: window.innerWidth - (isGraphFullScreen ? 0 : 380),
            height: window.innerHeight - (isGraphFullScreen ? 50 : 150)
          }}
          onFullScreen={() => {
            dispatch(setGraphFullScreen(!isGraphFullScreen));
          }}
          isFullScreen={isGraphFullScreen}
          asset={asset}
          getAssetGraph={getData}
          urlPrefix={env.REACT_APP_FRONTEND_URL}
          onClickLink={(from: string, to: string, type: string) => {
            onClickLink(from, to, type);
          }}
          onClickLinkWithActionItem={(id: string) => {
            onClickLinkWithActionItem(id);
          }}
          onDisplay={onDisplay}
          activeProtectionList={activeProtectionList}
        />
      </div>
    </div>
  );
}
