import './GroupsHeader.scss';
import { DropdownList, FlagsDropdown, IconActionItems, Text } from '@cyberpion/cyberpion-ui';
import './GroupsHeader.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setGroupName } from 'features/GroupsSlice';
import { IReduxState } from 'app/store';
import {
  setSubsidiaryCountry,
  setSubsidiaryDomain,
  setSubsidiaryName,
  setSubsidiaryParent
} from 'features/SubsidiariesSlice';
import classNames from 'classnames';

const GroupsHeader = (props: any) => {
  const { name } = useSelector((state: IReduxState) => state.groups);
  const {
    subsidiaryName,
    subsidiaryDomain,
    subsidiariesEditMode,
    subsidiaryCountry,
    allSubsidiaries,
    subsidiaryParent
  } = useSelector((state: IReduxState) => state.subsidiaries);
  const dispatch = useDispatch<any>();

  const renderName = () => {
    if (props.type === 'Group' && name) {
      return name;
    }

    if (props.type === 'Subsidiary' && subsidiaryName) {
      return subsidiaryName;
    }

    return `Create New ${props.type}`;
  };

  return (
    <div
      className={classNames('GroupsPopsideHeader', { disabled: subsidiariesEditMode && props.type === 'Subsidiary' })}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconActionItems color="#3455dd" />
        <Text textSize={13} weight={500} style={{ marginLeft: 6, paddingRight: 8 }}>
          {props.type.replace('Subsidiary', 'Subsidiaries').replace('Group', 'Groups')}
        </Text>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: 12 }}>
        <Text textSize={18} weight={500} style={{ marginRight: 10 }}>
          {renderName()}
        </Text>

        <Text textSize={13} style={{ marginRight: 10, marginTop: 10 }} textColor="#9A9EA7">
          Fill the {props.type.toLowerCase()} information below. Creation or changes of a {props.type.toLowerCase()} may
          take several minutes to apply.
        </Text>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
        {props.type === 'Subsidiary' && (
          <div className="FlagsDropdown-wrapper" style={{ display: 'flex', alignItems: 'center' }}>
            <FlagsDropdown
              onChange={(val: any) => {
                dispatch(setSubsidiaryCountry(val.key));
              }}
              placeholder={subsidiaryCountry || 'Country'}
              className="groups-popside-flags-dropdown"
              isSearch
            />
          </div>
        )}
        <div className="input-name" style={{ marginLeft: props.type === 'Group' ? 0 : 10 }}>
          <input
            type="text"
            value={props.type === 'Group' ? name : subsidiaryName}
            maxLength={50}
            placeholder={`${props.type} Name`}
            readOnly={subsidiariesEditMode}
            autoFocus
            onChange={(e: any) => {
              dispatch(props.type === 'Group' ? setGroupName(e.target.value) : setSubsidiaryName(e.target.value));
            }}
          />
        </div>
        {props.type === 'Subsidiary' && (
          <div className="input-domain" style={{ marginLeft: 10 }}>
            <input
              type="text"
              readOnly={subsidiariesEditMode}
              value={subsidiaryDomain}
              maxLength={70}
              placeholder="Main Domain"
              onChange={(e: any) => dispatch(setSubsidiaryDomain(e.target.value))}
            />
          </div>
        )}
        {props.type === 'Subsidiary' && (
          <div className="DropdownList-wrapper" style={{ display: 'flex', alignItems: 'center', marginLeft: 10 }}>
            <DropdownList
              onChange={(val: string) => {
                dispatch(setSubsidiaryParent(val));
              }}
              placeholder={subsidiaryParent || 'Parent company (optional)'}
              options={allSubsidiaries.map((s: any) => {
                return {
                  value: s.name,
                  label: s.name
                };
              })}
              title={subsidiaryParent || ''}
              className="parent-company-dropdown"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default GroupsHeader;
