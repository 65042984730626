import './GroupRules.scss';
import RuleWrapper from './RuleWrapper/RuleWrapper';
import { IReduxState } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { addRulesGroup, getGroupsFilters, setGroupName, setRules } from 'features/GroupsSlice';
import NoRules from './NoRules/NoRules';
import { useEffect, useRef } from 'react';
import { RestApi } from 'common/services/rest-api.service';
import { Paths } from 'common/constants/paths';
import { PathNames } from 'common/constants/pathNames';
import { Common } from 'common/services/common.service';
import {
  setSubsidiaryCountry,
  setSubsidiaryDomain,
  setSubsidiaryName,
  setSubsidiaryParent
} from 'features/SubsidiariesSlice';

export const getRulesFormat = (rules: any, filters: any) => {
  if (!rules) {
    return {};
  }
  let _rules: any = {};
  rules.forEach((group: any) => {
    const groupId = Common.generateRandomId();
    _rules[groupId] = {};
    for (let rule in group) {
      const ruleId = Common.generateRandomId();
      const split = rule.split('__');
      _rules[groupId][ruleId] = {
        parameter: split[0],
        operator: split[1] ? `__${split[1]}` : '',
        values: group[rule].map((val: string) => {
          const filter = filters.filter((f: any) => {
            return f.name === rule.split('__')[0];
          })[0];
          if (filter.choices.length) {
            const choice = filter.choices.filter((c: any) => {
              return c.label === val || c.name === val;
            })[0];
            return { value: choice.name, label: choice.label };
          } else {
            return { value: val, label: val };
          }
        })
      };
    }
  });
  return _rules;
};

const GroupRules = (props: any) => {
  const { rules, editMode, filters, id, scroll } = useSelector((state: IReduxState) => state.groups);
  const { subsidiariesEditMode, subsidiarySelectedId } = useSelector((state: IReduxState) => state.subsidiaries);
  const dispatch = useDispatch<any>();
  const ref: any = useRef();

  useEffect(() => {
    if (ref.current) {
      setTimeout(() => ref.current.scrollTo({ left: 0, top: 10000, behavior: 'smooth' }), 100);
    }
  }, [scroll, ref]);

  useEffect(() => {
    dispatch(getGroupsFilters());
  }, [dispatch]);

  useEffect(() => {
    if (editMode && filters.length) {
      RestApi.getData(`${Paths[PathNames.groups]}${id}`).subscribe((response: any) => {
        const rules: any = getRulesFormat(response.filter_rules, filters);
        dispatch(setRules(rules));
        dispatch(setGroupName(response.name));
      });
    } else if (subsidiariesEditMode && filters.length) {
      RestApi.getData(`${Paths[PathNames.subsidiaries]}${subsidiarySelectedId}`).subscribe((response: any) => {
        const rules: any = getRulesFormat(response.filter_rules, filters);
        dispatch(setRules(rules));
        dispatch(setSubsidiaryDomain(response.main_domain));
        dispatch(setSubsidiaryName(response.name));
        dispatch(setSubsidiaryParent(response.direct_parent));
        dispatch(setSubsidiaryCountry(response.country?.toUpperCase()));
      });
    } else if (!subsidiariesEditMode && !editMode) {
      dispatch(setRules({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.length]);

  return (
    <div className="GroupRules">
      <div className="scrollbar-common">
        <div className="popside-content" ref={ref}>
          {rules && Object.keys(rules).length ? (
            <div className="rules-wrapper">
              {Object.keys(rules).map((rule: any, index: number) => {
                return (
                  <RuleWrapper
                    id={rule}
                    key={`group-${index}`}
                    onAdd={() => {
                      dispatch(addRulesGroup());
                    }}
                  />
                );
              })}
            </div>
          ) : (
            <NoRules />
          )}
        </div>
      </div>
    </div>
  );
};

export default GroupRules;
