import { AppLoading } from 'common/components/appLoading/appLoading';
import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showSuccess } from 'features/AlertSlice';
import { Button, Text } from '@cyberpion/cyberpion-ui';
import { RestApi } from 'common/services/rest-api.service';
import { Common } from 'common/services/common.service';
import { IReduxState } from 'app/store';
import {
  setDiscoveryInvestigationCommentIds,
  setDiscoveryVerdictDropdown,
  setShowAddCommentPopupDiscoveryPending,
  updateRows
} from 'features/GridSlice';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import './AddDiscoveryCommentPopup.scss';

const AddDiscoveryCommentPopup = (props: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(true);
  const refTextArea = useRef<HTMLTextAreaElement>(null);
  const { discoveryVerdictDropdown, rows, discoveryInvestigationCommentIds } = useSelector(
    (state: IReduxState) => state.grid
  );
  const { user } = useSelector((state: IReduxState) => state.login);
  const dispatch = useDispatch<any>();
  const location = useLocation();
  const params = queryString.parse(location.search);

  const onApply = (mode: string) => () => {
    setLoading(true);
    const url = !discoveryInvestigationCommentIds?.length
      ? Common.AddMsspAccountIfNeeded(
          `discovery/dpbs/${user.company_name}/scans/domains/${params.discoveryScanId}/verdicts`
        )
      : Common.AddMsspAccountIfNeeded(
          `discovery/dpbs/${user.company_name}/scans/domains/${params.discoveryScanId}/comment`
        );

    const payload =
      mode === 'submit'
        ? {
            ...(!!discoveryInvestigationCommentIds?.length
              ? { ids: discoveryInvestigationCommentIds }
              : { ids: Object.keys(discoveryVerdictDropdown.listLastStatusOption) }),
            ...(!!!!discoveryVerdictDropdown.nextStatusOption
              ? { verdict: discoveryVerdictDropdown.nextStatusOption?.name }
              : {}),
            comment: refTextArea.current?.value,
            user: user.email
          }
        : {
            ...(!!discoveryInvestigationCommentIds?.length
              ? { ids: discoveryInvestigationCommentIds }
              : { ids: Object.keys(discoveryVerdictDropdown.listLastStatusOption) }),
            ...(!!!!discoveryVerdictDropdown.nextStatusOption
              ? { verdict: discoveryVerdictDropdown.nextStatusOption?.name }
              : {}),
            user: user.email
          };

    const comment = refTextArea.current?.value;
    RestApi.setData(url, payload, 'POST').subscribe((response: any) => {
      if (!!discoveryVerdictDropdown.listLastStatusOption) {
        const updatedRows = Object.keys(discoveryVerdictDropdown.listLastStatusOption)?.reduce((all: any, id: any) => {
          const row = rows.find((row: any) => row.id === id);
          return [
            ...all,
            {
              ...row,
              verdict: discoveryVerdictDropdown.nextStatusOption?.label
            }
          ];
        }, []);
        dispatch(updateRows({ rows: updatedRows, identifier: 'id' }));
      }
      if (!!discoveryInvestigationCommentIds?.length) {
        const updatedRows = discoveryInvestigationCommentIds?.reduce((all: any, id: any) => {
          const row = rows.find((row: any) => row.id === id);
          return [
            ...all,
            {
              ...row,
              comments: [
                { user: user.email, content: comment, date: new Date().toISOString() },
                ...(!!row.comments ? row.comments : [])
              ]
            }
          ];
        }, []);
        dispatch(updateRows({ rows: updatedRows, identifier: 'id' }));
      }

      dispatch(setShowAddCommentPopupDiscoveryPending(false));
      dispatch(
        setDiscoveryVerdictDropdown({
          listLastStatusOption: null,
          nextStatusOption: null
        })
      );
      dispatch(setDiscoveryInvestigationCommentIds([]));
      setLoading(false);
      dispatch(showSuccess('Updated Successfully'));
    });

    props.setVisible(false);
  };

  const handleCancelOnClick = () => {
    if (!!discoveryVerdictDropdown.listLastStatusOption) {
      const updatedRows = Object.keys(discoveryVerdictDropdown.listLastStatusOption)?.reduce((all: any, id: any) => {
        const row = rows.find((row: any) => row.id === id);
        return [
          ...all,
          {
            ...row,
            verdict: discoveryVerdictDropdown.listLastStatusOption[id].label
          }
        ];
      }, []);
      dispatch(updateRows({ rows: updatedRows, identifier: 'id' }));
      dispatch(
        setDiscoveryVerdictDropdown({
          listLastStatusOption: null,
          nextStatusOption: null
        })
      );
      dispatch(setDiscoveryInvestigationCommentIds([]));
    }
    props.setVisible(false);
  };

  const handleIsConfirmDisabled = () => {
    setIsConfirmDisabled(!refTextArea.current?.value);
  };

  return (
    <div className="AddDiscoveryCommentPopup popup-inner-wrapper">
      {loading && <AppLoading />}
      <div className="popup-inner">
        <Text textSize={18} weight={500} style={{ marginBottom: 12 }}>
          Add Comment
        </Text>
        <Text textSize={13} textColor="#8C909B" style={{ lineHeight: 1.35, marginBottom: 16 }}>
          Please provide Comment
        </Text>

        <div className="text-area">
          <textarea
            ref={refTextArea}
            className="add-evidence-textarea"
            placeholder="Add Comment"
            onChange={handleIsConfirmDisabled}
          />
        </div>

        <div className="buttons-wrapper">
          {!!discoveryVerdictDropdown.listLastStatusOption && (
            <Button
              buttonStyle="secondary"
              disabled={false}
              type="button"
              size="small"
              onClick={onApply('skip')}
              text="Skip"
            />
          )}
          <div style={{ flexGrow: 1 }} />
          <Button
            buttonStyle="secondary"
            disabled={false}
            type="button"
            size="small"
            onClick={handleCancelOnClick}
            text="Cancel"
          />
          <Button
            buttonStyle="main"
            disabled={isConfirmDisabled}
            type="button"
            size="small"
            onClick={onApply('submit')}
            text="Submit"
          />
        </div>
      </div>
    </div>
  );
};

export default AddDiscoveryCommentPopup;
