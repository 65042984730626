import { useState } from 'react';
import { Collapse } from './components/collapse/Collapse';
import PanelSideHeader from './components/PanelSideHeader/PanelSideHeader';
import PanelSideBody from './components/PanelSideBody/PanelSideBody';
import MobilePanelSideBody from './components/MobilePanelSideBody/MobilePanelSideBody';
import './panelSide.scss';

const PanelSide = ({ data: panelData, isMobileAsset }: any) => {
  const [togglePanel, setTogglePanel] = useState(true);
  const { type } = panelData;

  function onTogglePanel() {
    setTogglePanel(!togglePanel);
  }

  function isPanelOpen() {
    let closed: string = 'panelSide-closed';
    let open: string = 'panelSide-open';
    return togglePanel ? open : closed;
  }

  return panelData && ((!isMobileAsset && panelData[type]) || isMobileAsset) ? (
    <div className={`panelSide ${isPanelOpen()}`}>
      <div className="panelSide-head">
        <div className="panelSide-collapse-tabs">
          <Collapse toggle={!togglePanel} onClick={onTogglePanel} />
        </div>
        <PanelSideHeader panelData={panelData} />
      </div>
      <div className="panelSide-body scrollbar-common">
        <div style={{ height: '100%', overflow: 'auto' }}>
          {!!isMobileAsset ? (
            <MobilePanelSideBody panelData={panelData} />
          ) : (
            <PanelSideBody
              asset={panelData.asset}
              panelData={panelData[type]}
              type={type}
              tags={panelData.tags}
              groups={panelData.groups}
              hosting={panelData.hosting_provider}
            />
          )}
        </div>
      </div>
    </div>
  ) : null;
};

export default PanelSide;
