import React, { useEffect } from 'react';
import { Grid } from 'common/components/grid/Grid';
import { RestApi } from 'common/services/rest-api.service';
import { IRootColumnPicker } from 'common/interfaces/interface';
import { Paths } from 'common/constants/paths';
import { ColumnsManage } from 'common/components/grid/columnsManage';
import { TABSET, OPTIONS } from './config';
import { GridRowParams } from '@mui/x-data-grid-pro';
import { QuickViewPanel } from 'common/components/quickViewPanel/QuickViewPanel';
import { AppDispatch, IReduxState } from 'app/store';
import { removeAllRows, resetQueryString, setFields, setInitialPath } from 'features/GridSlice';
import { useDispatch, useSelector } from 'react-redux';
import { PopUp } from 'common/components/popUp/PopUp';
import { UnlinkedAssets } from 'common/components/popUp/components/UnlinkedAssets/UnlinkedAssets';
import { NotFound } from 'notFound/NotFound';
import useShowToggle from 'common/hooks/useShowToggle';
import TopPanel from 'common/components/TopPanel/TopPanel';
import TOP_PANEL_PAGES from 'common/constants/topPanelPages';
import { Grid as MUIGrid } from '@mui/material';
import { TopPanelLoadingState } from 'common/components/TopPanel/TopPanelLoadingState/TopPanelLoadingState';
import { setColumnPicker, setShowColumnPicker } from 'features/columnPickerSlice';

import '../pages.scss';

export interface IInventory {
  rows?: any;
  path?: string;
  // columnPicker: IColumnPicker[];
  // showColumnPicker: boolean;
  isMoreButton: boolean;
  isCSVDownloadable: boolean;
  count: number;
  columns: Array<any>;
  rootPath: string;
  identifier: string;
}

export function InventoryRouting(props: any) {
  const [showGrid, setShowGrid] = React.useState<boolean>(false);
  const [dataQuickViewPanel, setDataQuickViewPanel] = React.useState<any>();
  const [showQuickViewPanel, setShowQuickViewPanel] = React.useState<boolean>(false);
  const [unlinkedAssetsData, setUnlinkedAssetsData] = React.useState<{}>({});
  const [inventoryData, setInventoryData] = React.useState<IInventory>(initData());
  const [selectTab, setSelectTab] = React.useState<string>(props.selectTab);
  const [showUnlinkedAssets, setShowUnlinkedAssets] = React.useState<boolean>(false);
  const [noPermission, setNoPermission] = React.useState<boolean>(false);
  const [summaryData, setSummaryData] = React.useState<any>(null);
  const [abortControllerGrid, setAbortControllerGrid] = React.useState<AbortController>();
  const [abortControllerTopPanel, setAbortControllerTopPanel] = React.useState<AbortController>();
  const [isSummaryLoading, setIsSummaryLoading] = React.useState(true);
  const { isOpen: isTopPanelOpen, setToggle: setTopPanelToggle } = useShowToggle(true);
  const { globalFilter } = useSelector((state: IReduxState) => state.general);

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    fetchGridData(props.selectTab);
    fetchTopPanelData(props.selectTab);
    if (props.selectTab !== selectTab) {
      dispatch(removeAllRows());
      dispatch(resetQueryString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectTab]);

  useEffect(() => {
    dispatch(resetQueryString());
    return () => {
      dispatch(removeAllRows());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchTopPanelData(props.selectTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  useEffect(() => {
    setShowQuickViewPanel(false);
  }, [selectTab]);

  function initData(): IInventory {
    return {
      rows: [],
      // columnPicker: [],
      // showColumnPicker: false,
      isMoreButton: true,
      isCSVDownloadable: false,
      count: 0,
      columns: [],
      rootPath: '',
      identifier: ''
    };
  }

  const fetchTopPanelData = (tab: string) => {
    if (abortControllerTopPanel) {
      abortControllerTopPanel.abort();
    }
    const abort_controller = new AbortController();
    setAbortControllerTopPanel(abort_controller);

    const url = !!globalFilter ? `${Paths[TABSET[tab].tabPath]}?global_filter=${globalFilter}` : Paths[TABSET[tab].tabPath];
    setIsSummaryLoading(true);
    RestApi.getData(url, true, abort_controller.signal).subscribe((response: any) => {
      setSummaryData({ ...response, inContext: tab });
      setIsSummaryLoading(false);
    });
  };

  function fetchGridData(tab: string) {
    setSelectTab(props.selectTab);
    setShowGrid(false);
    setNoPermission(false);
    if (abortControllerGrid) {
      abortControllerGrid.abort();
    }
    const abort_controller = new AbortController();
    setAbortControllerGrid(abort_controller);
    RestApi.getData(Paths[TABSET[tab].columnPickerPath], true, abort_controller.signal).subscribe(
      (columns: IRootColumnPicker) => {
        let fields = ColumnsManage.checkGridColumnsToDisplay(columns.data);
        dispatch(setFields(fields));
        dispatch(setInitialPath(Paths[TABSET[tab].gridPath]));
        dispatch(setColumnPicker(columns.data));
        dispatch(setShowColumnPicker(columns.is_displayable));

        setInventoryData({
          rows: [],
          // columnPicker: columns.data || [],
          // showColumnPicker: columns.is_displayable,
          isMoreButton: columns.is_more_button,
          isCSVDownloadable: columns.is_csv_downloadable,
          count: 0,
          identifier: columns.identifier_field,
          columns: ColumnsManage.createColumns(columns.identifier_field, columns.data || [], {
            page: 'discovery',
            tab: props.selectTab
          }),
          rootPath: Paths[TABSET[tab].gridPath]
        });
        setShowGrid(true);
      },
      err => {
        if (err.status === 403) {
          setNoPermission(true);
        }
      }
    );
  }

  function onRowDoubleClick(params: GridRowParams) {
    if (selectTab === 'digitalSupplyChain' || selectTab === 'internalAssets') {
      RestApi.getData(`${inventoryData.rootPath}${params.row[inventoryData.identifier]}/`).subscribe(
        (response: any) => {
          setDataQuickViewPanel(response);
          setShowQuickViewPanel(true);
        }
      );
    } else if (selectTab === 'unlinkedAssets') {
      setUnlinkedAssetsData({
        host: params.row.asset
      });
      setShowUnlinkedAssets(true);
    }
  }

  const getElementSelect = () => {
    if (props.selectTab === 'internalAssets') {
      return {
        header: 'Actions',
        options: OPTIONS,
        apiPath: 'discovery/org-assets/'
      };
    }
    return undefined;
  };

  if (noPermission) {
    return <NotFound type="403" />;
  }

  const showSummary =
    !!summaryData &&
    ((props.selectTab === 'internalAssets' && summaryData.inContext === 'internalAssets') ||
      (props.selectTab === 'digitalSupplyChain' && summaryData.inContext === 'digitalSupplyChain') ||
      (props.selectTab === 'loginAssets' && summaryData.inContext === 'loginAssets'));

  return (
    <>
      {!!TABSET[props.selectTab] &&
        TABSET[props.selectTab].showSummary &&
        (!isSummaryLoading && showSummary ? (
          <TopPanel
            page={TOP_PANEL_PAGES.DISCOVERY}
            tab={props.selectTab}
            data={summaryData}
            isLoading={isSummaryLoading}
            isOpen={isTopPanelOpen}
            setToggle={setTopPanelToggle}
          />
        ) : (
          <MUIGrid container direction="column">
            <MUIGrid item className="top-panel-loading-state">
              <TopPanelLoadingState page={TOP_PANEL_PAGES.DISCOVERY} tab={props.selectTab} isOpen={isTopPanelOpen} />
            </MUIGrid>
            <MUIGrid item sx={{ height: 10 }}></MUIGrid>
          </MUIGrid>
        ))}
      {showGrid ? (
        <>
          <div style={{ height: '100%', zIndex: 1 }}>
            <Grid
              elementSelect={getElementSelect()}
              onRowDoubleClick={onRowDoubleClick}
              columns={inventoryData.columns}
              rows={inventoryData.rows}
              count={inventoryData.count}
              path={inventoryData.path}
              // columnPicker={inventoryData.columnPicker}
              // showColumnPicker={inventoryData.showColumnPicker}
              isMoreButton={inventoryData.isMoreButton}
              isCSVDownloadable={inventoryData.isCSVDownloadable}
              identifier={inventoryData.identifier}
              pageTab={TABSET[props.selectTab].gridPath}
              page={props.source}
              savedViews={TABSET[props.selectTab].savedViews}
            />
          </div>
          {showQuickViewPanel && (
            <QuickViewPanel
              setVisible={setShowQuickViewPanel}
              visible={showQuickViewPanel}
              nameTab={selectTab}
              data={dataQuickViewPanel}
              identifier={inventoryData.identifier}
            />
          )}
          {showUnlinkedAssets && (
            <PopUp setVisible={setShowUnlinkedAssets} visible={showUnlinkedAssets}>
              <UnlinkedAssets setVisible={setShowUnlinkedAssets} data={unlinkedAssetsData} />
            </PopUp>
          )}
        </>
      ) : (
        <div className="grid-loading">Loading...</div>
      )}
    </>
  );
}
