import { useEffect, useState } from 'react';
import { Popover } from '@mui/material';
import { Text } from '@cyberpion/cyberpion-ui';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from 'app/store';
import { makeStyles } from '@material-ui/core/styles';
import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import classNames from 'classnames';
import { RestApi } from 'common/services/rest-api.service';
import { showSuccess } from 'features/AlertSlice';
import { Common } from 'common/services/common.service';
import { setDiscoveryVerdictDropdown, setShowAddCommentPopupDiscoveryPending, updateRows } from 'features/GridSlice';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
// import { setCommentsTabCount } from 'features/popSideSlice';
import './DiscoveryVerdictDropdown.scss';

const useStyles = makeStyles({
  popoverRoot: {
    marginTop: '10px',
    maxHeight: 400,
    minHeight: 105,
    width: '110px !important',
    padding: '6px',
    border: '1px solid #ecedff',
    '& .option-item': {
      color: '#5D6576',
      padding: '8px 10px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: '#f6f8ff'
      },
      '&.selected': {
        backgroundColor: '#f6f8ff80 !important'
      },
      '& .cyberpion-ui-text': {
        borderBottom: 'none'
      }
    }
  }
});

const colors = {
  Select: '#FFF',
  None: '#FFF',
  Approved: '#50A686',
  Maybe: '#DFB941',
  Rejected: '#F46666',
  Pending: '#B7BBC5'
};

const DiscoveryVerdictDropdown = (props: any) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { columnPicker } = useSelector((state: IReduxState) => state.columnPicker);
  const { rows, discoveryInvestigationToolReportStatus } = useSelector((state: IReduxState) => state.grid);
  const { user } = useSelector((state: IReduxState) => state.login);
  const [isDisable, setIsDisable] = useState(false);
  // const { commentsTabCount } = useSelector((state: IReduxState) => state.popSide);
  const dispatch = useDispatch<any>();
  const location = useLocation();
  const params = queryString.parse(location.search);

  const classes = useStyles();
  const verdict = !!props.row.verdict ? props.row.verdict : 'Select';

  let options = !!columnPicker?.length
    ? columnPicker
        ?.find((col: any) => col.type === 'discovery_investigation_asset_verdict')
        ?.choices.filter((item: any) => !item.label.includes('empty'))
        .map((item: any) => ({ label: item.label, name: item.name, is_toggled: item.is_toggled }))
    : [];
  options = [...options, { label: 'None', name: '_null_', is_toggled: false }];

  useEffect(() => {
    setIsDisable(
      !!discoveryInvestigationToolReportStatus &&
        (discoveryInvestigationToolReportStatus.toLowerCase() === 'complete' ||
          discoveryInvestigationToolReportStatus?.toLowerCase() === 'submitted')
    );
  }, [discoveryInvestigationToolReportStatus]);

  const handleOnClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleItemOnClick = (option: any) => (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);

    if (option.label !== 'Pending') {
      const url = Common.AddMsspAccountIfNeeded(
        `discovery/dpbs/${user.company_name}/scans/domains/${params.discoveryScanId}/verdicts`
      );

      const payload = {
        ids: [props.row.id],
        verdict: option.name,
        user: user.email
      };
      RestApi.setData(url, payload, 'POST').subscribe(
        (response: any) => {
          setAnchorEl(null);

          const row = rows.find((row: any) => row.id === props.row.id);
          const updatedRow = [
            {
              ...row,
              verdict: option.label !== 'None' ? option.label : null
            }
          ];
          dispatch(updateRows({ rows: updatedRow, identifier: 'id' }));
          dispatch(showSuccess('Updated Successfully'));
        },
        error => {
          setAnchorEl(null);
        }
      );
    } else {
      setAnchorEl(null);
      dispatch(
        setDiscoveryVerdictDropdown({
          listLastStatusOption: { [props.row.id]: { label: verdict, name: verdict?.toLowerCase(), is_toggled: false } },
          nextStatusOption: option
        })
      );

      const row = rows.find((row: any) => row.id === props.row.id);
      const updatedRow = [
        {
          ...row,
          verdict: 'Pending'
        }
      ];
      dispatch(updateRows({ rows: updatedRow, identifier: 'id' }));
      dispatch(setShowAddCommentPopupDiscoveryPending(true));
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return props.row.status?.toLowerCase() !== 'seed domain' ? (
    <div className={classNames('DiscoveryVerdictDropdown', { 'is-disabled': isDisable })}>
      <div className="dropdown" onClick={handleOnClick}>
        <div
          style={{
            borderRadius: '50%',
            backgroundColor: colors[verdict as keyof typeof colors],
            width: 8,
            height: 8,
            marginRight: 8,
            border: verdict === 'Select' || verdict === 'None' ? '1px dashed #B7BBC5' : 'none'
          }}
        ></div>
        <div>{verdict}</div>

        <div className="chevron-icon">
          <IconArrow toggleRotate={open} />
        </div>
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        classes={{ paper: classes.popoverRoot }}
      >
        {!!options?.length &&
          options.map((option: any) => (
            <div
              key={option.name}
              className={classNames('option-item', { selected: option.label === verdict })}
              onClick={handleItemOnClick(option)}
              style={{ borderTop: option.label === 'None' ? '1px solid #ecedff' : 'none' }}
            >
              <div
                style={{
                  borderRadius: '50%',
                  backgroundColor: colors[option.label as keyof typeof colors],
                  width: 8,
                  height: 8,
                  marginRight: 10,
                  border: verdict === 'Select' || option.label === 'None' ? '1px dashed #B7BBC5' : 'none'
                }}
              ></div>
              <Text textColor="#5D6576" textSize={13}>
                {option.label}
              </Text>
            </div>
          ))}
      </Popover>
    </div>
  ) : null;
};

export default DiscoveryVerdictDropdown;
