import React, { useEffect, useState } from 'react';
import { ConnectionsGraph, Text } from '@cyberpion/cyberpion-ui';
import { env } from 'env';
import { RestApi } from 'common/services/rest-api.service';
import { AppLoading } from 'common/components/appLoading/appLoading';
import { Common } from 'common/services/common.service';

const linkIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path d="M7.5249 2.5H10.0749V5.05" stroke="#3659DE" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10.075 2.5L6.25 6.325" stroke="#3659DE" stroke-linecap="round" stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.8248 2H3.4248C2.59638 2 1.9248 2.67157 1.9248 3.5V9.15C1.9248 9.97843 2.59638 10.65 3.42481 10.65H9.0748C9.90323 10.65 10.5748 9.97843 10.5748 9.15V6.75H9.5748V9.15C9.5748 9.42614 9.35095 9.65 9.0748 9.65H3.42481C3.14866 9.65 2.9248 9.42614 2.9248 9.15V3.5C2.9248 3.22386 3.14866 3 3.4248 3H5.8248V2Z"
      fill="#3659DE"
    />
  </svg>
);

const iconEmptyState = (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="80" height="80" rx="13.3333" fill="#F0F2F6" />
    <path
      d="M31.9998 59.9989L24.0004 59.9995C22.939 59.9995 21.9218 59.5777 21.1719 58.8277C20.4219 58.0777 20 57.0604 20 55.9991V36.0006H31.9998L31.9998 59.9989ZM55.9995 59.9989L36.0009 59.9995V35.9999H60.0006V56.0002V55.9995C60.0006 57.0609 59.5787 58.078 58.8287 58.828C58.0788 59.578 57.0615 59.9999 56.0002 59.9999L55.9995 59.9989ZM59.9992 31.9998H20.0003V24.0004C20.0003 22.939 20.4222 21.9218 21.1722 21.1719C21.9222 20.4219 22.9394 20 24.0007 20H56.0009H56.0002C57.0616 20 58.0787 20.4219 58.8287 21.1719C59.5787 21.9218 60.0006 22.9391 60.0006 24.0004L59.9992 31.9998Z"
      fill="white"
    />
  </svg>
);
const emptyState = (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      transform: 'translateY(200px)'
    }}
  >
    {iconEmptyState}
    <Text textSize={16} textColor="#A0A4AC" style={{ paddingTop: 16 }}>
      The Attack Surface Graph could not be generated at this time
    </Text>
  </div>
);

const PopSideGraphTab = ({ asset, id, type }: { asset: string; id: string; type: string }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showEmptyState, setShowEmptyState] = useState(false);
  const [activeProtectionList, setActiveProtectionList] = useState<string[]>([]);
  const [activeProtectionReady, setActiveProtectionReady] = useState<boolean>(false);

  const getData = async (asset: string, isIncoming?: boolean) => {
    const response = await RestApi.simpleAsyncGet(`discovery/org-assets/${asset}/graph/`);
    setShowEmptyState(!response?.nodes?.length);
    if (!!response?.nodes?.length && type.toLowerCase() === 'risky connection') {
      const relations = response.relations.filter((rel: any) => rel.properties.action_item === id);
      const nodes = relations.reduce((all: any, curr: any) => {
        const filteredNodes = response.nodes.filter(
          (node: any) => node.nid === curr.to_id || node.nid === curr.from_id
        );
        let objNodes = {};
        if (!!filteredNodes.length) {
          filteredNodes.forEach((node: any) => {
            objNodes = { ...objNodes, [node.nid]: node };
          });
          return { ...all, ...objNodes };
        } else {
          return { ...all };
        }
      }, {});

      setIsLoading(false);
      return !!relations.length
        ? {
            nodes: Object.values(nodes),
            relations
          }
        : response;
    }

    setIsLoading(false);
    return response;
  };

  useEffect(() => {
    RestApi.getData(
      `remediation/active-protection/?fields=protected_host&is_actively_protected=1&asset=${asset}&limit=1000`
    ).subscribe(res => {
      const activeProtectionList = res.results.map((row: any) => row.protected_host);
      setActiveProtectionList(activeProtectionList);
      setActiveProtectionReady(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGoToGraph = (ev: any) => {
    ev.stopPropagation();
    window.open(Common.AddMsspAccountIfNeeded(`/pages/singleAsset/internalAssets/${asset}/graph`), '_blank');
  };

  if (!activeProtectionReady) {
    return null;
  }

  return isLoading ? (
    <AppLoading />
  ) : showEmptyState ? (
    emptyState
  ) : (
    <div className="PopSideGraphTab">
      <ConnectionsGraph
        size={{
          width: 880,
          height: 550
        }}
        onFullScreen={() => {}}
        isFullScreen={false}
        asset={asset}
        getAssetGraph={getData}
        urlPrefix={env.REACT_APP_FRONTEND_URL}
        onClickLink={(from: string, to: string, type: string) => {}}
        onClickLinkWithActionItem={(id: string) => {}}
        onDisplay={() => {}}
        showControls={false}
        activeProtectionList={activeProtectionList}
      />
      <div className="asset-graph-button" onClick={handleGoToGraph}>
        <Text textSize={13} style={{ paddingRight: 4 }}>
          See full asset graph
        </Text>
        {linkIcon}
      </div>
    </div>
  );
};

export default PopSideGraphTab;
