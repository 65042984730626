import { Button, Text } from '@cyberpion/cyberpion-ui';

import './InfoDiscoveryPopup.scss';

const InfoDiscoveryPopup = (props: any) => {
  const handleCancelOnClick = () => {
    props.setVisible(false);
  };

  return (
    <div className="InfoDiscoveryPopup popup-inner-wrapper">
      <div className="popup-inner">
        <Text textSize={18} weight={500} style={{ marginBottom: 12 }}>
          Before saving
        </Text>
        <Text textSize={13} textColor="#8C909B" style={{ lineHeight: 1.35, marginBottom: 4 }}>
          {`Submitting the report will lock this report from future changes. Please check the following:`}
        </Text>
        <ul>
          <li>
            <Text textSize={13} textColor="#8C909B" style={{ lineHeight: 1.35, marginBottom: 16 }}>
              {`Are there assets that the algorithm confirmed but the user didn’t set a verdict or set it to Maybe?`}
            </Text>
          </li>
          <li>
            <Text textSize={13} textColor="#8C909B" style={{ lineHeight: 1.35, marginBottom: 16 }}>
              {`Are there assets in the account that the algorithm classified as Rejected or Filtered?`}
            </Text>
          </li>
        </ul>

        <div className="buttons-wrapper">
          <Button
            buttonStyle="secondary"
            disabled={false}
            type="button"
            size="small"
            onClick={handleCancelOnClick}
            text="Cancel"
          />
          <Button
            buttonStyle="main"
            disabled={false}
            type="button"
            size="small"
            onClick={props.onSave}
            text="Save & Lock"
          />
        </div>
      </div>
    </div>
  );
};

export default InfoDiscoveryPopup;
