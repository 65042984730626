import React, { createContext, useContext, useState, useEffect } from 'react';
import { RestApi } from 'common/services/rest-api.service';
import { Common } from 'common/services/common.service';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
// import { ThreatsCenter_Mock } from 'common/mocks/threatsCenter';

export type ThreatsCenterContextType = {
  allData: any;
  filteredData: any;
  dependentData: any;
  timeLineData: any;
  isLoading: boolean;
  search: string;
  showOnlyFindings: boolean;
  setTimelineSelected: (selected: string | null) => void;
  setShowOnlyFindings: (show: boolean) => void;
  timelineSelected: string | null;
  setSearch: (pattern: string) => void;
  isLoadingDependentData: boolean;
  isLoadingAggregatedFindings: boolean;
  setIsLoadingAggregatedFindings: (isLoading: boolean) => void;
};

interface ThreatsCenterProviderProps {
  children: JSX.Element | JSX.Element[];
}

const ThreatsCenterContext = createContext<ThreatsCenterContextType | null>(null);

export const ThreatsCenterProvider: React.FC<ThreatsCenterProviderProps> = ({ children }) => {
  const [allData, setAllData] = useState<any>(null);
  const [filteredData, setFilteredData] = useState<any>(null);
  const [dependentData, setDependentData] = useState<any>(null);
  const [timeLineData, setTimeLineData] = useState<any>(null);
  const [timelineSelected, setTimelineSelected] = useState<any>(null);
  const [showOnlyFindings, setShowOnlyFindings] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingDependentData, setIsLoadingDependentData] = useState(true);
  const [search, setSearch] = useState('');
  const [abortControllerThreatCenter, setAbortControllerThreatCenter] = useState<AbortController>();
  const [isLoadingAggregatedFindings, setIsLoadingAggregatedFindings] = useState(true);
  const { globalFilter } = useSelector((state: any) => state.general);

  const flags = useFlags();

  useEffect(() => {
    if (!!allData && !isLoading) {
      let data: any;
      if (search) {
        data = allData.filter((item: any) => {
          return (
            item.title.toLowerCase().includes(search.toLowerCase()) ||
            item.description.toLowerCase().includes(search.toLowerCase())
          );
        });
      } else {
        data = allData;
      }
      if (showOnlyFindings) {
        data = data.filter((item: any) => {
          return (
            !!dependentData[item.uuid] &&
            (!!dependentData[item.uuid].findings || !!dependentData[item.uuid].potential_affected)
          );
        });
      }
      setFilteredData(data);
    }
  }, [allData, search, isLoading, dependentData, showOnlyFindings]);

  const buildTimeLineData = (data: any) => {
    let timeline: any = {};
    data
      .sort((a: any, b: any) => new Date(b.creation_time).getTime() - new Date(a.creation_time).getTime())
      .forEach((item: any) => {
        const y = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(new Date(item.creation_time));
        const m = new Intl.DateTimeFormat('en', { month: 'short' }).format(new Date(item.creation_time));
        const monthNumber = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(new Date(item.creation_time));
        const d = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(new Date(item.creation_time));
        const urgency = Common.getUrgencyBySeverity(item.severity);

        timeline = {
          ...timeline,
          [y]: {
            ...timeline[y],
            [monthNumber]: {
              label: m,
              days: [...(!!timeline[y] && !!timeline[y][monthNumber] ? timeline[y][monthNumber].days : []), d],
              urgency: [
                ...(!!timeline[y] && !!timeline[y][monthNumber] ? timeline[y][monthNumber].urgency : []),
                urgency.type
              ],
              uuid: [...(!!timeline[y] && !!timeline[y][monthNumber] ? timeline[y][monthNumber].uuid : []), item.uuid]
            }
          }
        };
      });
    setTimeLineData(timeline);
  };

  const fetchData = async () => {
    try {
      const path = 'threat-center/';
      RestApi.getData(path).subscribe((response: any) => {
        // INFO: Real data
        setAllData(response.results);
        setFilteredData(response.results);
        buildTimeLineData(response.results);

        // // INFO: Mock data
        // setAllData(ThreatsCenter_Mock);
        // setFilteredData(ThreatsCenter_Mock);
        // buildTimeLineData(ThreatsCenter_Mock);
        if (!response.results?.length) {
          setIsLoading(false);
        }
      });
    } catch (err) {
      console.log('#ERR: fetch data');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();

    const abort_controller = new AbortController();
    setAbortControllerThreatCenter(abort_controller);

    return () => {
      if (abort_controller) {
        abort_controller.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllTags = (open: any, closed: any) => {
    const selectedRowsTags = new Set();
    open.forEach((row: any) => {
      if (!!row.tags) {
        row.tags.forEach((tag: any) => {
          selectedRowsTags.add(tag.name);
        });
      }
    });
    if (!!closed) {
      closed.forEach((row: any) => {
        if (!!row.tags) {
          row.tags.forEach((tag: any) => {
            selectedRowsTags.add(tag.name);
          });
        }
      });
    }

    return [...selectedRowsTags];
  };

  const getPromisesAPIs = () => {
    return allData.reduce((all: any, curr: any) => {
      let closed: any;
      if (!!curr.findings_api_url && curr.findings_api_url.includes('/action-items/open/')) {
        closed = curr.findings_api_url.replace('/api/v1/', '').replace('/action-items/open/', '/action-items/closed/');
        closed = `${closed}&limit=1000`;
      }
      return curr.findings_api_url || curr.potential_affected_api_url
        ? {
            ...all,

            ...(!!curr.findings_api_url
              ? {
                  [`${curr.uuid}_findings`]: (!!globalFilter
                    ? `${curr.findings_api_url}&global_filter=${globalFilter}`
                    : curr.findings_api_url
                  ).replace('/api/v1/', '')
                }
              : {}),
            ...(!!curr.potential_affected_api_url
              ? {
                  [`${curr.uuid}_potential_affected`]: (!!globalFilter
                    ? `${curr.potential_affected_api_url}&global_filter=${globalFilter}`
                    : curr.potential_affected_api_url
                  ).replace('/api/v1/', '')
                }
              : {}),
            ...(!!closed ? { [`${curr.uuid}_closed`]: closed } : {})
          }
        : { ...all };
    }, {});
  };

  const buildDataByUUID = (responsePromisesAll: any) => {
    let responses: any = {};
    Object.entries(responsePromisesAll).forEach(([key, value]: any) => {
      const uuid = key.split('_')[0];
      responses = {
        ...responses,
        [uuid]: {
          ...responses[uuid],
          ...(key === `${uuid}_findings`
            ? {
                findings: value !== 'err' ? value.count : 'err',
                tags:
                  value !== 'err' ? getAllTags(value.results, responsePromisesAll[`${uuid}_closed`]?.results) : 'err'
              }
            : {}),
          ...(key === `${uuid}_potential_affected`
            ? { potential_affected: value !== 'err' ? value.count : 'err' }
            : {}),
          ...(key === `${uuid}_closed` ? { closed: value !== 'err' ? value.count : 'err' } : {})
        }
      };
    });
    return responses;
  };

  const fetchDependentData = async () => {
    const promisesAPIs = getPromisesAPIs();
    let responses: any = {};

    const chunksList = Common.getSubArraysFromArrayBySize(
      Object.keys(promisesAPIs),
      flags.isThreatCenterPerformanceUpdate || 1
    );
    const promisesAPIsChunks = chunksList.map((chunked: any) => {
      return chunked.reduce((all: any, curr: any) => {
        return { ...all, [curr]: promisesAPIs[curr] };
      }, {});
    });
    let responsePromisesAll: any;

    let count = 0;
    let totalCount = 0;
    for (const chunk of promisesAPIsChunks) {
      const chunkResponse: any = await RestApi.getPromiseAll(chunk, abortControllerThreatCenter?.signal);
      count += 1;
      totalCount += 1;
      if (!chunkResponse.isError) {
        responsePromisesAll = { ...responsePromisesAll, ...chunkResponse };
      } else {
        responsePromisesAll = { ...responsePromisesAll, [Object.keys(chunk)[0]]: 'err' };
      }
      if (count === 3) {
        count = 0;
        responses = buildDataByUUID(responsePromisesAll);
        setDependentData(responses);
      }
    }

    if (count > 0) {
      responses = buildDataByUUID(responsePromisesAll);
      setDependentData(responses);
    }
    if (totalCount === promisesAPIsChunks.length) {
      setIsLoadingDependentData(false);
    }
  };

  useEffect(() => {
    if (!!allData?.length) {
      setIsLoading(false);
      fetchDependentData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allData]);

  return (
    <ThreatsCenterContext.Provider
      value={{
        allData,
        filteredData,
        dependentData,
        timeLineData,
        search,
        showOnlyFindings,
        isLoading,
        setSearch,
        setTimelineSelected,
        setShowOnlyFindings,
        timelineSelected,
        isLoadingDependentData,
        isLoadingAggregatedFindings,
        setIsLoadingAggregatedFindings
      }}
    >
      {children}
    </ThreatsCenterContext.Provider>
  );
};

export const useThreatsCenter = () => {
  return useContext(ThreatsCenterContext);
};
