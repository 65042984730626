import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { IconNotificationsActiveProtection } from 'common/components/Icons/IconNotificationsActiveProtection/IconNotificationsActiveProtection';
import { IconNotificationsActionItems } from 'common/components/Icons/IconNotificationsActionItems/IconNotificationsActionItems';
import { IconNotificationsDiscovery } from 'common/components/Icons/IconNotificationsDiscovery/IconNotificationsDiscovery';
import { IconNotificationsCertificates } from 'common/components/Icons/IconNotificationsCertificates/IconNotificationsCertificates';
import { IconNotificationsPortalAccess } from 'common/components/Icons/IconNotificationsPortalAccess/IconNotificationsPortalAccess';
import { IconNotificationsAssetManagement } from 'common/components/Icons/IconNotificationsAssetManagement/IconNotificationsAssetManagement';
import { IconNotificationsThreadCenter } from 'common/components/Icons/IconNotificationsThreadCenter/IconNotificationsThreadCenter';
import { RestApi } from 'common/services/rest-api.service';
import { addHeaders } from 'common/services/rest-api.service';
import { showError, showSuccess } from 'features/AlertSlice';
import { useDispatch } from 'react-redux';
import { env } from 'env';

// import { RestApi_MOCKS } from 'common/services/rest-api.service_MOCKS';
// import { notificationsList_MOCK } from './mocks';

export const getCategoryFromType = (type: number) => {
  switch (Math.floor(type / 100)) {
    case 0:
      return 'action_items';
    case 10:
    case 11:
      return 'portal_access';
    case 12:
    case 13:
      return 'asset_management';
    case 20:
    case 40:
    case 41:
    case 50:
    case 70:
    case 100:
      return 'discovery';
    case 30:
    case 31:
      return 'certificates';
    case 60:
      return 'active_protection';
    case 90:
      return 'threat_center';
    default:
      return '';
  }
};

export const NOTIFICATIONS_LIMIT = 200;
export const NOTIFICATIONS_TYPE = {
  ACTION_ITEM: 'action_items',
  // ACTIVE_PROTECTION: 'active_protection',
  DISCOVERY: 'discovery',
  CERTIFICATES: 'certificates',
  PORTAL_ACCESS: 'portal_access',
  ASSET_MANAGEMENT: 'asset_management',
  THREAT_CENTER: 'threat_center'
};

export const CATEGORIES_ENUM = {
  action_items: 1,
  active_protection: 2,
  discovery: 3,
  portal_access: 4,
  certificates: 5,
  asset_management: 6,
  threat_center: 7
};

export const NOTIFICATION_OPTIONS = {
  action_items: {
    icon: <IconNotificationsActionItems active />,
    title: 'Action Items',
    newTitle: 'Action Item Notification',
    desc: 'Notify on risk related issues'
  },
  active_protection: {
    icon: <IconNotificationsActiveProtection active />,
    title: 'Active Protection',
    newTitle: 'Active Protection Notification',
    desc: 'Notify on assets under Active Protection'
  },
  discovery: {
    icon: <IconNotificationsDiscovery active />,
    title: 'Discovery',
    newTitle: 'Discovery Notification',
    desc: 'Notify on changes in the inventory'
  },
  certificates: {
    icon: <IconNotificationsCertificates active />,
    title: 'Certificates',
    newTitle: 'Certificates Notification',
    desc: 'Notify on the org certificates'
  },
  portal_access: {
    icon: <IconNotificationsPortalAccess active />,
    title: 'Portal Access',
    newTitle: 'Portal Access Notification',
    desc: 'Notify on user activity'
  },
  asset_management: {
    icon: <IconNotificationsAssetManagement active />,
    title: 'Asset Management',
    newTitle: 'Asset Management Notification',
    desc: 'Notify on asset management'
  },
  threat_center: {
    icon: <IconNotificationsThreadCenter active />,
    title: 'Threat Center Updates',
    newTitle: 'Threat Center Updates Notification',
    desc: 'Notify on threats management'
  }
};

export type TActionMode = 'view' | 'add' | 'edit' | 'new' | null;
export type TOrder = 'asc' | 'desc';

export type TSort = 'date' | 'type' | 'az';
export const SORT_TYPE = {
  DATE: 'date',
  TYPE: 'type',
  AZ: 'az'
};
export const DROPDOWNBOX_TYPE = {
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
  SLIDER: 'slider',
  IMPORTANCE: 'importance',
  TEXT_FILTER: 'textFilter',
  NUMBER_FILTER: 'numberFilter',
  NUMBERS_LIST_FILTER: 'numbersListFilter',
  STRINGS_LIST_FILTER: 'stringsListFilter',
  INPUT_CHIPS: 'inputChips',
  DATES: 'dates'
};

export type NotificationsContextType = {
  allNotifications: any;
  filteredNotifications: any;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  search: string | null;
  onSearch: (search: string) => void;
  onSort: (type: TSort) => void;
  setSelectedNotification: (notification: any) => void;
  onNotificationClick: (notification: any) => void;
  onNotificationItemMenuClick: (menuId: string, notification: any) => void;
  selectedNotification: any;
  setActionMode: (mode: TActionMode) => void;
  setNotificationType: (type: string) => void;
  notificationType: string | null;
  actionMode: TActionMode;
  isLoading: boolean;
  onSwitchNotificationItem: (id: string, isActivate: boolean) => void;
  onDeleteNotification: (id: string) => void;
  activatedIds: string[];
  patchNotification: (id: string, data: any) => void;
  fetchNotifications: () => void;
  notificationsAmount: number | null;
  sortBy: any;
};
interface NotificationsProviderProps {
  children: JSX.Element | JSX.Element[];
}

const NotificationsContext = createContext<NotificationsContextType | null>(null);

export const NotificationsProvider: React.FC<NotificationsProviderProps> = ({ children }) => {
  const [allNotifications, setAllNotifications] = useState<any>(null);
  const [filteredNotifications, setFilteredNotifications] = useState<any>(null);
  const [notificationsAmount, setNotificationsAmount] = useState<number | null>(null);
  const [search, setSearch] = useState<string | null>(null);
  const [selectedNotification, setSelectedNotification] = useState<any>(null);
  const [sortBy, setSortBy] = useState({ by: 'date', order: 'desc' });
  const [actionMode, setActionMode] = useState<TActionMode | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [notificationType, setNotificationType] = useState<string | null>(null);
  const [activatedIds, setActivatedIds] = useState<any>(null);
  const dispatch = useDispatch<any>();

  const fetchNotifications = async () => {
    try {
      // MOCK: TESTING USAGE ***************************************
      // const data: any = await RestApi_MOCKS.getPromise_Mocks(notificationsList_MOCK);
      // const results = data.results.map((notification: any) => ({
      //   ...notification,
      //   notification_type: getCategoryFromType(notification.notification_type.notification_type)
      // }));
      // setAllNotifications(results);
      // setFilteredNotifications(results);
      // setActivatedIds(results.filter((f: any) => f.status.status === 0).map((notification: any) => notification.id));
      // setNotificationsAmount(data.count || 0);
      // MOCK: ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

      // PROD: PROD DATA ***************************************
      const path = 'settings/notifications/';
      RestApi.getData(path).subscribe((response: any) => {
        const results = response.results.map((notification: any) => ({
          ...notification,
          notification_type: getCategoryFromType(notification.notification_type.notification_type)
        }));
        setAllNotifications(results);
        setFilteredNotifications(results);
        setActivatedIds(results.filter((f: any) => f.status.status === 0).map((notification: any) => notification.id));
        setNotificationsAmount(response.count || 0);
      });
      // PROD: ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
      setIsLoading(false);
    } catch (err) {
      console.log('#ERR: fetch data');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const searchNotifications = useCallback(() => {
    return allNotifications.filter((notification: any) => notification.name.toLowerCase().includes(search));
  }, [allNotifications, search]);

  useEffect(() => {
    if (search === null || search.trim() === '') {
      setFilteredNotifications(allNotifications);
    } else {
      setFilteredNotifications(searchNotifications());
    }
  }, [search, allNotifications, searchNotifications]);

  const onSearch = (pattern: string) => {
    setSearch(pattern);
  };

  const onNotificationClick = (notification: any) => {
    const open = selectedNotification === null || notification.id !== selectedNotification.id;
    setIsOpen(open);
    if (open) {
      setActionMode('view');
      setSelectedNotification(notification);
      setNotificationType(notification.notification_type);
    }
  };

  const onNotificationItemMenuClick = (notification: any, menuId: string) => {
    if (menuId === 'edit') {
      setIsOpen(true);
      setActionMode('edit');
      setSelectedNotification(notification);
      setNotificationType(notification.notification_type);
    }
  };

  useEffect(() => {
    if (!!sortBy.by && !!sortBy.order && !!filteredNotifications?.length) {
      let sorted;
      const dirOrder = sortBy.order === 'asc' ? -1 : 1;

      switch (sortBy.by) {
        case SORT_TYPE.DATE:
          sorted = filteredNotifications.sort(function(a: any, b: any) {
            return dirOrder * (Number(new Date(b.updated_at)) - Number(new Date(a.updated_at)));
          });
          break;
        case SORT_TYPE.TYPE:
          sorted = filteredNotifications.sort(function(a: any, b: any) {
            return dirOrder * a.notification_type.localeCompare(b.notification_type);
          });
          break;
        case SORT_TYPE.AZ:
          sorted = filteredNotifications.sort(function(a: any, b: any) {
            return dirOrder * b.name.localeCompare(a.name);
          });
          break;
      }

      setFilteredNotifications([...sorted]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  const onSort = (_sortBy: TSort) => {
    if (_sortBy !== sortBy.by) {
      setSortBy({ by: _sortBy, order: 'desc' });
    } else {
      setSortBy({ by: sortBy.by, order: sortBy.order === 'desc' ? 'asc' : 'desc' });
    }
  };

  const removeByValue = (arr: any, id: any) => {
    return arr.filter((item: any) => item !== id);
  };

  const onSwitchNotificationItem = (id: string, isActivate: boolean) => {
    if (isActivate) {
      setActivatedIds([...activatedIds, id]);
    } else {
      setActivatedIds(removeByValue(activatedIds, id));
    }
    patchNotification(id, { status: isActivate ? 0 : 1 });
  };

  const onDeleteNotification = async (id: string) => {
    RestApi.setData(
      'settings/notifications/',
      {
        ids: [id]
      },
      'DELETE'
    ).subscribe(
      (response: any) => {
        fetchNotifications();
        dispatch(showSuccess('Notification was Deleted Successfully'));
        setIsOpen(false);
      },
      error => {
        dispatch(showError('Notification Delete Error'));
      }
    );
  };

  const patchNotification = async (id: string, data: any) => {
    const path = `${env.REACT_APP_BASE_URL}settings/notifications/${id}/`;
    const resPatch = await fetch(path, {
      method: 'PATCH',
      mode: 'cors',
      headers: addHeaders('PATCH'),
      credentials: 'include',
      body: JSON.stringify(data)
    });
    if (resPatch.ok) {
      dispatch(showSuccess(`Notification ${data.status === 0 ? 'activated' : 'deactivated'} successfully`));
    }
    const response = await resPatch.json();
    return response;
  };

  return (
    <NotificationsContext.Provider
      value={{
        allNotifications,
        filteredNotifications,
        isOpen,
        setIsOpen,
        search,
        onSearch,
        setActionMode,
        actionMode,
        setSelectedNotification,
        selectedNotification,
        setNotificationType,
        onNotificationClick,
        onNotificationItemMenuClick,
        notificationType,
        onSort,
        isLoading,
        onSwitchNotificationItem,
        onDeleteNotification,
        activatedIds,
        patchNotification,
        fetchNotifications,
        notificationsAmount,
        sortBy
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => {
  return useContext(NotificationsContext);
};
