import { useState, useEffect, useRef } from 'react';
import { ActionItemsRouting } from './ActionItemsRouting';
import { updateHeader } from 'common/services/common.service';
import { Links } from 'common/components/links/Links';
import { TABSET } from './config';
import { Paths } from 'common/constants/paths';
import { PathNames } from 'common/constants/pathNames';
import { RestApi } from 'common/services/rest-api.service';
import { useDispatch, useSelector } from 'react-redux';
import { updateRescan } from 'features/GridSlice';
import { AppDispatch } from 'app/store';
import { SCAN_STATUS } from 'common/components/ScanStatus/ScanStatus';

const PAGE_NAME = 'Action Items';

const ActionItems = (props: any) => {
  const [metaData, setMetaData] = useState<any>({});
  const { isLoggedIn } = useSelector((state: any) => state.login);
  const { globalFilter } = useSelector((state: any) => state.general);
  const { rows } = useSelector((state: any) => state.grid);
  const dispatch: AppDispatch = useDispatch();
  const intervalRef: any = useRef(null);

  useEffect(() => {
    if (isLoggedIn) {
      updateHeader.sendHeaderText(PAGE_NAME);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    getMetaData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      const scanInProgress = rows
        .filter((r: any) => r.rescan_status === SCAN_STATUS.IN_PROGRESS)
        .map((r: any) => {
          return r.id;
        });
      if (scanInProgress.length) {
        getMetaData();
        RestApi.getData(
          'remediation/action-items/all/?fields=id,rescan_status,last_rescan_time&rescan_status__in=2'
        ).subscribe((response: any) => {
          const indexes: any[] = [];
          const completedIds = response.results.map((row: any) => {
            return { id: row.id, time: row.last_rescan_time };
          });
          completedIds.forEach((obj: any) => {
            if (scanInProgress.includes(obj.id)) {
              let index = rows.findIndex((row: any) => row.id === obj.id);
              indexes.push({ index: index, time: obj.time });
            }
          });
          dispatch(updateRescan({ indexes: indexes, status: SCAN_STATUS.COMPLETED }));
        });
      }
    }, 60000);

    return () => {
      clearInterval(intervalRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const getMetaDataUrl = () => {
    return {
      metaData: `${Paths[PathNames.actionItemsMetaData]}${globalFilter ? `?global_filter=${globalFilter}` : ''}`
    };
  };

  const getMetaData = () => {
    RestApi.getDataPage(getMetaDataUrl()).subscribe((response: any) => {
      setMetaData({ ...response.metaData });
    });
  };

  return (
    <div className="sub-layout-pages">
      <div>
        <Links page={'Action Items'} selectTab={props.match.params.tab} tabs={TABSET} metaData={metaData} />
      </div>
      <ActionItemsRouting page={PAGE_NAME} selectTab={props.match.params.tab} />
    </div>
  );
};

export default ActionItems;
