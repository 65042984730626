import React, { useState } from 'react';
import { Text } from '@cyberpion/cyberpion-ui';
import { Popover } from '@mui/material';
import { useSelector } from 'react-redux';
import { IReduxState } from 'app/store';
import classNames from 'classnames';

import './DiscoveryCommentsColumn.scss';

const optionsTime: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true
};

const DiscoveryCommentsColumn = (props: any) => {
  const { supportMultiLineColumn } = useSelector((state: any) => state.grid);
  const { columnPicker } = useSelector((state: IReduxState) => state.columnPicker);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const isMultilineField = columnPicker.find((item: any) => item.name === props.field)?.is_multiline_support;

  const handlePopoverOpen = (event: any) => {
    if (!anchorEl) setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  let firstDate = '';
  if (props.value && props.value.length > 0) {
    const y = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(new Date(props.value[0].date));
    const m = new Intl.DateTimeFormat('en', { month: 'short' }).format(new Date(props.value[0].date));
    const d = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(new Date(props.value[0].date));
    firstDate = `${m} ${d}, ${y} ${new Intl.DateTimeFormat('en-US', optionsTime).format(
      new Date(props.value[0].date)
    )}`;
  }
  return !!props.value && !!props.value?.length ? (
    <div className={classNames('DiscoveryCommentsColumn', { 'multiline-column': isMultilineField })}>
      {!supportMultiLineColumn || !isMultilineField ? (
        <div style={{ whiteSpace: 'nowrap' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Text textColor="#6D6D7A" isText>
              {firstDate}
            </Text>
            <div style={{ backgroundColor: '#BEC0C5', width: 1, height: 10, margin: 8 }} />
            <Text textColor="#6D6D7A" isText style={{ marginRight: 4 }}>
              {props.value[0].user}
            </Text>
            {!!props.value?.length && props.value?.length > 1 && (
              <div className="more-chip info-panel-count" onClick={handlePopoverOpen}>
                <Text textColor="#4A79E7">+{`${props.value?.length - 1}`}</Text>
              </div>
            )}
          </div>
          <div>
            <Text textColor="#6D6D7A" isText>
              {props.value[0].content}
            </Text>
          </div>
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', padding: '8px 0', whiteSpace: 'nowrap' }}>
          {props.value.slice(0, 3).map(({ date, user, content }: { date: string; user: string; content: string }) => {
            const y = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(new Date(date));
            const m = new Intl.DateTimeFormat('en', { month: 'short' }).format(new Date(date));
            const d = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(new Date(date));
            const dateStr = `${m} ${d}, ${y} ${new Intl.DateTimeFormat('en-US', optionsTime).format(new Date(date))}`;
            return (
              <div style={{ marginBottom: 4 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Text textColor="#6D6D7A" isText>
                    {dateStr}
                  </Text>
                  <div style={{ backgroundColor: '#BEC0C5', width: 1, height: 10, margin: '0 8px' }} />
                  <Text textColor="#6D6D7A" isText>
                    {user}
                  </Text>
                </div>
                <div>
                  <Text textColor="#6D6D7A" isText>
                    {content}
                  </Text>
                </div>
              </div>
            );
          })}
          {!!props.value?.length && props.value?.length > 3 && (
            <div className="more-chip info-panel-count" onClick={handlePopoverOpen}>
              <Text textColor="#4A79E7">+{`${props.value?.length - 3}`}</Text>
            </div>
          )}
        </div>
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        disableScrollLock={true}
        className="discovery-integration-comments-popover"
      >
        <div className="options-wrapper">
          {props.value.map(({ date, user, content }: { date: string; user: string; content: string }) => {
            const y = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(new Date(date));
            const m = new Intl.DateTimeFormat('en', { month: 'short' }).format(new Date(date));
            const d = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(new Date(date));
            const dateStr = `${m} ${d}, ${y} ${new Intl.DateTimeFormat('en-US', optionsTime).format(new Date(date))}`;
            return (
              <div className="comment-item">
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 4 }}>
                  <div
                    style={{
                      borderRadius: '50%',
                      backgroundColor: '#6285F4',
                      minWidth: 8,
                      minHeight: 8,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginRight: 8
                    }}
                  />
                  <Text textColor="#6D6D7A">{dateStr}</Text>
                  <div style={{ backgroundColor: '#BEC0C5', width: 1, height: 10, margin: '0 8px' }} />
                  <Text textColor="#6D6D7A">{user}</Text>
                </div>
                <div>
                  <Text textColor="#6D6D7A">{content}</Text>
                </div>
              </div>
            );
          })}
        </div>
      </Popover>
    </div>
  ) : null;
};

export default DiscoveryCommentsColumn;
