import './AffectedAssets.scss';
import { Search } from 'common/components/Search/Search';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDebounce } from 'hooks/useDebounce';
import { RestApi } from 'common/services/rest-api.service';
import { LinkNewTab } from 'common/components/LinkNewTab/LinkNewTab';
import { useDispatch } from 'react-redux';
import { addDownloadFileData, setNewDownloads } from 'features/DownloadsSlice';
import { ManageNotData } from 'common/components/manageNotData/ManageNotData';

export function AffectedAssets(props: any) {
  const [type, setType] = useState<string>('org');
  const [search, setSearch] = useState<string>('');
  const [assets, setAssets] = useState<[]>([]);
  const [csvEndpoint, setCsvEndpoint] = useState<string>('');
  const debouncedValue = useDebounce(search);
  const dispatch = useDispatch<any>();

  const _search = (value: string, _type: string) => {
    setAssets([]);
    let endpoint = '';
    if (value) {
      endpoint = `assessments/${
        _type === 'org' ? 'org-assets' : 'digital-supply-chain'
      }/?asset__contains=${value}&cves__intext=${props.cve}&fields=asset`;
    } else {
      endpoint = `assessments/${_type === 'org' ? 'org-assets' : 'digital-supply-chain'}/?cves__intext=${
        props.cve
      }&fields=asset`;
    }
    RestApi.getData(endpoint).subscribe((response: any) => {
      setCsvEndpoint(endpoint + '&format=csv');
      setAssets(response.results.map((obj: any) => obj.asset));
    });
  };

  useEffect(() => {
    _search(debouncedValue, type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const exportToCsv = async () => {
    const response = await RestApi.simpleAsyncGet(csvEndpoint);
    dispatch(addDownloadFileData(response));
    dispatch(setNewDownloads(response?.id));
  };

  return (
    <div className="AffectedAssets">
      <h4>Affected Assets</h4>
      <div className="AffectedAssets-types">
        <span className={classNames({ active: type === 'org' })}>
          <span
            onClick={() => {
              setAssets([]);
              setType('org');
              _search(debouncedValue, 'org');
            }}
          >
            Organizational ({props.numOrgAssets})
          </span>
          <LinkNewTab
            to={`/pages/assessments/internalAssets?filters=%7B%22cves__intext%22%3A%22${props.cve}%22%7D`}
            text={<img src="/assets/images/link.svg" alt="Link" />}
          />
        </span>
        <span className={classNames({ active: type === 'ext' })}>
          <span
            onClick={() => {
              setAssets([]);
              setType('ext');
              _search(debouncedValue, 'ext');
            }}
          >
            Digital supply chain ({props.numExtAssets})
          </span>
          <LinkNewTab
            to={`/pages/assessments/digitalSupplyChain?filters=%7B%22cves__intext%22%3A%22${props.cve}%22%7D`}
            text={<img src="/assets/images/link.svg" alt="Link" />}
          />
        </span>
      </div>
      <div className="search">
        <Search
          onChange={(value: string) => {
            setSearch(value);
          }}
        />
        <img src="/assets/images/csv.svg" alt="Export" onClick={exportToCsv} />
      </div>
      {!!assets.length ? (
        <ul>
          {assets.map((asset: string) => {
            return (
              <li>
                <LinkNewTab
                  to={`/pages/singleAsset/${
                    type === 'org' ? 'internalAssets' : 'digitalSupplyChain'
                  }/${asset}/technologies`}
                  text={asset}
                />
              </li>
            );
          })}
        </ul>
      ) : (
        <div className="empty">
          <ManageNotData type="org_score" />
        </div>
      )}
    </div>
  );
}
