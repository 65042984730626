import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showSuccess } from 'features/AlertSlice';
import { AppDispatch } from 'app/store';

import './DiscoveryInvestigationDomain.scss';

const iconClipboard = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <rect
      x="1.125"
      y="3.375"
      width="7.5"
      height="7.5"
      rx="0.75"
      stroke="#5D6576"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.375 3.375V1.875C3.375 1.46079 3.71079 1.125 4.125 1.125H10.125C10.5392 1.125 10.875 1.46079 10.875 1.875V7.875C10.875 8.28921 10.5392 8.625 10.125 8.625H8.625"
      stroke="#5D6576"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const iconNewTab = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path d="M7.52499 2.5H10.075V5.05" stroke="#5D6576" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10.075 2.5L6.24998 6.325" stroke="#5D6576" stroke-linecap="round" stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.82499 2H3.42499C2.59656 2 1.92499 2.67157 1.92499 3.5V9.15C1.92499 9.97843 2.59656 10.65 3.42499 10.65H9.07499C9.90342 10.65 10.575 9.97843 10.575 9.15V6.75H9.57499V9.15C9.57499 9.42614 9.35113 9.65 9.07499 9.65H3.42499C3.14885 9.65 2.92499 9.42614 2.92499 9.15V3.5C2.92499 3.22386 3.14885 3 3.42499 3H5.82499V2Z"
      fill="#5D6576"
    />
  </svg>
);

const DiscoveryInvestigationDomain = (props: any) => {
  const [showIcon, setShowIcon] = useState(false);
  const dispatch: AppDispatch = useDispatch();

  const handleCopyClipboard = () => {
    navigator.clipboard.writeText(props.value);
    dispatch(showSuccess('Copied to clipboard'));
  };

  const handleOpenNewTab = () => {
    window.open(`https://www.${props.value}`, '_blank');
  };

  return (
    <div
      className="DiscoveryInvestigationDomain"
      onMouseEnter={() => setShowIcon(true)}
      onMouseLeave={() => setShowIcon(false)}
    >
      <div className="grid-link-field">{props.value}</div>
      {showIcon && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="icon-clipboard" onClick={handleCopyClipboard}>
            {iconClipboard}
          </div>
          <div className="icon-open" onClick={handleOpenNewTab}>
            {iconNewTab}
          </div>
        </div>
      )}
    </div>
  );
};

export default DiscoveryInvestigationDomain;
