import { Button, Text } from '@cyberpion/cyberpion-ui';

import './ErrorDiscoveryPopup.scss';

const ErrorDiscoveryPopup = (props: any) => {
  const handleCancelOnClick = () => {
    props.setVisible(false);
  };

  return (
    <div className="ErrorDiscoveryPopup popup-inner-wrapper">
      <div className="popup-inner">
        <Text textSize={18} weight={500} style={{ marginBottom: 16 }}>
          {props.errorData?.status === 400 ? 'Conflicts Error' : 'Error'}
        </Text>
        <ul>
          {!!props.errorData?.message &&
            props.errorData?.message.map((msg: string) => (
              // <Text textSize={13} textColor="#8C909B" style={{ lineHeight: 1.35, marginBottom: 6 }}>
              //   {msg}
              // </Text>

              <li>
                <Text textSize={13} textColor="#8C909B" style={{ lineHeight: 1.35, marginBottom: 8 }}>
                  {msg}
                </Text>
              </li>
            ))}
        </ul>
        <div className="buttons-wrapper">
          <Button
            buttonStyle="secondary"
            disabled={false}
            type="button"
            size="small"
            onClick={handleCancelOnClick}
            text="Close"
          />
        </div>
      </div>
    </div>
  );
};

export default ErrorDiscoveryPopup;
