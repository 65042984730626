import { useSelector } from 'react-redux';
import InnerRule from './InnerRule/InnerRule';
import './RuleWrapper.scss';
import { IReduxState } from 'app/store';

const RuleWrapper = (props: any) => {
  const { rules } = useSelector((state: IReduxState) => state.groups);

  return (
    <div className="RuleWrapper" key={props.key}>
      <div className="line"></div>
      <div className="line-2"></div>
      <div
        className="add-group"
        onClick={() => {
          props.onAdd();
        }}
      >
        +
      </div>
      <div className="or">OR</div>
      {Object.keys(rules[props.id]).map((rule: any, index: number) => {
        return <InnerRule groupId={props.id} ruleId={rule} key={`rule-${index}`} />;
      })}
    </div>
  );
};

export default RuleWrapper;
