import { useDispatch, useSelector } from 'react-redux';
import './InnerRule.scss';
import { addInnerRule, editRule, removeRule } from 'features/GroupsSlice';
import { Button, DropdownList, FiltersDropdown } from '@cyberpion/cyberpion-ui';
import { useEffect, useState } from 'react';
import { IReduxState } from 'app/store';
import {
  FILTER_ARRAY_IP,
  FILTER_ARRAY_NUMBER,
  FILTER_ARRAY_STRING,
  FILTER_IP,
  FILTER_NUMBER,
  FILTER_STRING
} from 'common/components/grid/components/gridUtilities/filtersWrapper/config';
import { Common } from 'common/services/common.service';
import { IFilterGridOption } from 'common/components/grid/components/gridUtilities/interface';
import AddBulk from '../../AddBulk/AddBulk';
import { ToolTip } from 'common/components/toolTip/toolTip';

const InnerRule = (props: any) => {
  const { filters, rules } = useSelector((state: IReduxState) => state.groups);
  const [selectedParameter, setSelectedParameter] = useState<any>(null);
  const [selectedOperator, setSelectedOperator] = useState<any>(null);
  const [addBulkVisible, setAddBulkVisible] = useState<boolean>(false);
  const [list, setList] = useState<any[]>([]);
  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (!filters.length) {
      return;
    }

    const rule = rules[props.groupId][props.ruleId];

    const parameter = filters.filter((f: any) => f.name === rule.parameter)[0];

    const operator = getOperatorOptions(parameter)?.filter((o: any) => o.value === rule.operator)[0];

    setSelectedParameter(parameter);
    setSelectedOperator(operator);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, rules]);

  useEffect(() => {
    const _list: any = [
      {
        'Asset Attributes': filters
          .filter((f: any) =>
            [
              'Asset Name',
              'Asset Type',
              'Cloud Service (Cloud Provider)',
              'Cloud Service Description',
              'Confidence Level',
              'Dev Assets',
              'Dev Asset Of',
              'Hosting Provider',
              'Importance',
              'Majestic Rank',
              'Origin IPs',
              'Operated By',
              'Resource ID',
              'Service Type',
              'Service',
              'Subscription ID',
              'Tags',
              'Technologies',
              'Umbrella Rank'
              // 'First Seen'
            ].includes(f.label)
          )
          .map((item: any) => {
            return { ...item, value: item.label };
          })
          .sort(Common.sortByLabel)
      },
      {
        'Related Assets': filters
          .filter((f: any) => ['FQDNs', 'IPs'].includes(f.label))
          .map((item: any) => {
            return { ...item, value: item.label };
          })
          .sort(Common.sortByLabel)
      },
      {
        'IP Info': filters
          .filter((f: any) =>
            [
              'IP',
              'IP Network',
              'IP Network Country',
              'IP Network Org ID',
              'IP Network Org Name',
              'IP Network Name',
              'IP Network Source'
            ].includes(f.label)
          )
          .map((item: any) => {
            return { ...item, value: item.label };
          })
          .sort(Common.sortByLabel)
      },
      {
        WHOIS: filters
          .filter((f: any) =>
            [
              'Registrar',
              'Registrant Contact Name',
              'Registrant Organization',
              'WHOIS Emails',
              'WHOIS Name Server',
              'WHOIS Status'
            ].includes(f.label)
          )
          .map((item: any) => {
            return { ...item, value: item.label };
          })
          .sort(Common.sortByLabel)
      },
      {
        Assessment: filters
          .filter((f: any) =>
            [
              'Bad Maintenance Indicators',
              'Certificates Failed Tests',
              'Cloud Failed Tests',
              'Cloud Risk Grade',
              'CVEs',
              'Domain Expiration Date',
              'DNS Failed Tests',
              'DNS Risk Grade',
              'Email Risk Grade',
              'Email Server Failed Tests',
              'Maintenance Grade',
              'Network Failed Tests',
              'Network Risk Grade',
              'Open Ports',
              'Protocols',
              'Risk Score',
              'TLS Risk Grade',
              'TLS Failed Tests',
              'Urgent Action Items',
              'Web Failed Tests',
              'Web Risk Grade'
            ].includes(f.label)
          )
          .map((item: any) => {
            return { ...item, value: item.label };
          })
          .sort(Common.sortByLabel)
      }
    ];
    setList(_list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const getOperatorOptions = (filter: any) => {
    if (!filter) {
      return null;
    }
    if (filter.choices_type === 'multi_choice') {
      if (['array', 'tags', 'array_string', 'array_number'].includes(filter.type)) {
        return [
          { value: '__overlap', label: 'Is' },
          { value: '__overlap_ne', label: 'Is Not' }
        ];
      } else {
        return [{ value: '__in', label: 'Is' }];
      }
    } else if (filter.type === 'toggle_text_choices') {
      return [
        { value: '__overlap', label: 'Is' },
        { value: '__overlap_ne', label: 'Is Not' }
      ];
    } else if (filter.type === 'string') {
      return Common.cloneData(FILTER_STRING).map((option: IFilterGridOption, key: number) => {
        return { value: option.name, label: option.label };
      });
    } else if (filter.type === 'ip') {
      return Common.cloneData(FILTER_IP)
        .filter((option: IFilterGridOption) => option.label !== 'Starts With')
        .map((option: IFilterGridOption, key: number) => {
          return { value: option.name, label: option.label };
        });
    } else if (filter.type === 'array_ip') {
      return Common.cloneData(FILTER_ARRAY_IP).map((option: IFilterGridOption, key: number) => {
        return { value: option.name, label: option.label };
      });
    } else if (['array_string', 'count_info_panel'].includes(filter.type)) {
      return Common.cloneData(FILTER_ARRAY_STRING)
        .filter((option: IFilterGridOption) => !option.label.includes('Blank') && option.label !== 'Contains')
        .map((option: IFilterGridOption, key: number) => {
          return { value: option.name, label: option.label };
        });
    } else if (filter.type === 'array_number') {
      return Common.cloneData(FILTER_ARRAY_NUMBER)
        .filter((option: IFilterGridOption) => !option.label.includes('Blank'))
        .map((option: IFilterGridOption, key: number) => {
          return { value: option.name, label: option.label };
        });
    } else if (filter.type === 'number') {
      return Common.cloneData(FILTER_NUMBER).map((option: IFilterGridOption, key: number) => {
        return { value: option.name, label: option.label };
      });
    }
  };

  const getValuesOptions = () => {
    let values: any[] = [];
    list.forEach((obj: any) => {
      const res: any = Object.values(obj)
        .flat()
        .filter((filter: any) => {
          return filter.name === selectedParameter.name;
        })[0];
      if (res) {
        values.push(
          ...res.choices.map((choice: any) => {
            return { value: choice.name, label: choice.label };
          })
        );
      }
    });
    return values;
  };

  if (!list.length) {
    return null;
  }

  return (
    <div className="InnerRule" key={props.key}>
      <div>
        <div className="rule-items-wrapper">
          <FiltersDropdown
            filters={list}
            onChange={(param: any) => {
              dispatch(
                editRule({
                  groupId: props.groupId,
                  ruleId: props.ruleId,
                  key: 'operator',
                  val: null
                })
              );
              dispatch(
                editRule({
                  groupId: props.groupId,
                  ruleId: props.ruleId,
                  key: 'values',
                  val: []
                })
              );
              dispatch(
                editRule({
                  groupId: props.groupId,
                  ruleId: props.ruleId,
                  key: 'parameter',
                  val: param.name
                })
              );
            }}
            title={selectedParameter?.label || 'Add Parameter'}
          />

          {!!selectedParameter && (
            <DropdownList
              onChange={(operator: any) => {
                // setSelectedOperator(operator);
                dispatch(
                  editRule({
                    groupId: props.groupId,
                    ruleId: props.ruleId,
                    key: 'values',
                    val: []
                  })
                );
                dispatch(
                  editRule({
                    groupId: props.groupId,
                    ruleId: props.ruleId,
                    key: 'operator',
                    val: operator.value
                  })
                );
              }}
              placeholder={selectedOperator?.label || 'Choose Operator'}
              options={getOperatorOptions(selectedParameter)}
              title=""
              className="dropdown"
            />
          )}
          {!!selectedOperator &&
            (selectedParameter?.choices_type === 'multi_choice' ||
              selectedParameter?.type === 'toggle_text_choices') && (
              <DropdownList
                onChange={(value: any) => {
                  const copy = [...rules[props.groupId][props.ruleId].values];
                  if (!copy.filter((v: any) => v.label === value.label).length) {
                    copy.push(value);
                  }
                  dispatch(
                    editRule({
                      groupId: props.groupId,
                      ruleId: props.ruleId,
                      key: 'values',
                      val: copy
                    })
                  );
                }}
                placeholder="Add Value"
                options={getValuesOptions()}
                title=""
                className="dropdown"
                permanentPlaceholder={true}
              />
            )}
          {!!selectedOperator &&
            selectedParameter?.type !== 'toggle_text_choices' &&
            selectedParameter?.choices_type !== 'multi_choice' &&
            !selectedOperator.label.includes('Blank') && (
              <div style={{ position: 'relative' }}>
                <Button
                  text={
                    !rules[props.groupId][props.ruleId].values.length
                      ? 'Add Value'
                      : rules[props.groupId][props.ruleId].values.length + ' Items (edit)'
                  }
                  onClick={() => {
                    setAddBulkVisible(!addBulkVisible);
                  }}
                  buttonStyle="secondary"
                  size="small"
                  type="button"
                  className="add-value"
                />
                {addBulkVisible && (
                  <AddBulk
                    values={rules[props.groupId][props.ruleId]?.values?.map((v: any) => v.label).join('\n')}
                    onAdd={(input: string) => {
                      const _values = input
                        .split('\n')
                        .filter((row: string) => !!row.length)
                        .map((row: string) => row.trim());
                      const uniqueValues = [...new Set(_values)].map((val: string) => {
                        return { label: val, value: val };
                      });
                      dispatch(
                        editRule({
                          groupId: props.groupId,
                          ruleId: props.ruleId,
                          key: 'values',
                          val: uniqueValues
                        })
                      );
                      setAddBulkVisible(false);
                    }}
                    onClose={() => setAddBulkVisible(false)}
                  />
                )}
              </div>
            )}
          {!!rules[props.groupId][props.ruleId].values.length && <span className="seperator">:</span>}
          {!!rules[props.groupId][props.ruleId].values.length &&
            rules[props.groupId][props.ruleId].values.map((value: any, index: number) => {
              return (
                <span className="value">
                  <span data-tip={value.label} data-for={`VALUE-${index}`}>
                    {value.label}
                  </span>
                  <ToolTip id={`VALUE-${index}`} place="bottom" theme="dark" effect="solid" />
                  <span
                    onClick={() => {
                      const copy = [...rules[props.groupId][props.ruleId].values];
                      copy.splice(copy.indexOf(value), 1);
                      dispatch(
                        editRule({
                          groupId: props.groupId,
                          ruleId: props.ruleId,
                          key: 'values',
                          val: copy
                        })
                      );
                    }}
                  >
                    <img src="/assets/images/x.svg" alt="Remove" />
                  </span>
                </span>
              );
            })}
        </div>
        <img
          src="/assets/images/trash_gray.svg"
          alt="Remove"
          onClick={() => {
            dispatch(removeRule({ groupId: props.groupId, ruleId: props.ruleId }));
          }}
        />
      </div>
      <div
        className="add-rule"
        onClick={() => {
          dispatch(addInnerRule(props.groupId));
        }}
      >
        +
      </div>
      <div className="and">AND</div>
    </div>
  );
};

export default InnerRule;
