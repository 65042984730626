import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Common } from 'common/services/common.service';
import { setDeletePromptParams } from 'features/GridSlice';
import { setEditMode, setSubsidiaryMode, togglePopup, setGroupId } from 'features/GroupsSlice';
import RowActionMenu from 'common/components/RowActionMenu/RowActionMenu';

import './FilterGroupsDropdown.scss';

export function FilterGroupsDropdown(props: any) {
  const ref = useRef<HTMLDivElement>(null);
  const [id, setId] = useState<string>(props.id);
  const dispatch = useDispatch<any>();

  useEffect(() => {
    const popup = Common.getParamFromUrl('popup');
    if (popup === props.row.name) {
      Common.removeParamFromURL('popup');
      setId(id);
      dispatch(setSubsidiaryMode(true));
      editOnClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteOnClick = () => {
    const id = props.id;
    const index = props.api.getRowIndex(id);

    dispatch(
      setDeletePromptParams({
        category: 'groups',
        index: index,
        ids: [id],
        message: 'Are you sure you would like to remove this group?'
      })
    );
  };

  const editOnClick = () => {
    dispatch(setGroupId(props.id));
    dispatch(setEditMode(true));
    dispatch(togglePopup(true));
  };

  const shouldShowDropDown = (): boolean => {
    if (props.row.actions?.is_deletable || props.row.actions?.is_editable) {
      return true;
    }
    return false;
  };

  if (!shouldShowDropDown()) {
    return null;
  }

  const handleMenuItemOnClick = (rowData: any, option: any, type: string, event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (option.id === 'delete') {
      deleteOnClick();
    }
    if (option.id === 'edit') {
      editOnClick();
    }
  };

  return (
    <div className="FilterGroupsDropdown" ref={ref}>
      <RowActionMenu rowData={props.row} itemOnClick={handleMenuItemOnClick} type="groups" status={props.row.actions} />
      {/* <header className={visible ? 'visible' : ''} onClick={() => setVisible(!visible)}>
        <span>...</span>
      </header>
      <ul className={visible ? 'visible' : ''}>
        {props.row.actions?.is_editable && <li onClick={editOnClick}>Edit</li>}
        {props.row.actions?.is_deletable && <li onClick={deleteOnClick}>Remove</li>}
      </ul> */}
    </div>
  );
}
