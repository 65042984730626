// @ts-nocheck
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addHeaders } from 'common/services/rest-api.service';
import { queryBuilder } from 'common/components/grid/query-builder.service';
import { toggleAllowPopupClose } from './GeneralSlice';

export const getRows = createAsyncThunk('grid/getRows', async (_, thunkAPI) => {
  const state = thunkAPI.getState();
  queryBuilder.setBaseURL(state.grid.initialPath);
  queryBuilder.buildQuery(state.grid, state.general.globalFilter);
  thunkAPI.dispatch(toggleAllowPopupClose(false));

  let query = queryBuilder.getQuery();
  if (!!state.grid.addFiltersGridRows) {
    // IN CASE CUSTOM_GROUPS, ADDING FILTER type__in=1 (the API returns groups + subsidiaries, \
    // need to get only the groups without subsidiaries)
    query += `&${state.grid.addFiltersGridRows}`;
  }
  // IN CASE INVESTIGATION RULES - in settings > discovery investigation rules
  // ADDING FILTER investigation_rules
  if (state.grid.investigation_rules !== '') {
    query += `&rules=${state.grid.investigation_rules}`;
  }

  const res = await fetch(query, {
    method: 'GET',
    mode: 'cors',
    headers: addHeaders('GET'),
    credentials: 'include'
  }).then(data => {
    thunkAPI.dispatch(toggleAllowPopupClose(true));
    return data.json();
  });
  return res;
});

export const getNextPage = createAsyncThunk('grid/getNextPage', async (_, { getState }) => {
  const state = getState();
  const res = await fetch(state.grid.nextPath, {
    method: 'GET',
    mode: 'cors',
    headers: addHeaders('GET'),
    credentials: 'include'
  }).then(data => data.json());

  return res;
});

const gridSlice = createSlice({
  name: 'grid',
  initialState: {
    rows: [],
    nextPageRows: [],
    additionalFields: ['id'],
    loading: false,
    nextPath: '',
    pendingNextPath: '',
    search: '',
    filters: {},
    selectFiltersHard: [],
    initialPath: '',
    clusterType: '',
    clusterSort: '',
    order: '',
    fields: [],
    investigation_rules: '',
    count: 0,
    clusterCount: 0,
    deletePromptParams: {},
    confirmationParams: {},
    queryString: {},
    allowURLChange: true,
    capturedState: {},
    clusters: {},
    filtersResetDefault: false,
    isRowMenuActive: false,
    addFiltersGridRows: '',
    showAddCommentPopup: false,
    showAddCommentPopupDiscoveryPending: false,
    supportMultiLineColumn: false,
    dropDownWithAction: {
      listLastStatusOption: null,
      nextStatusOption: null
    },
    discoveryVerdictDropdown: {
      listLastStatusOption: null,
      nextStatusOption: null
    },
    discoveryInvestigationCommentIds: [],
    shouldOpenPopsideCommentsTab: { isOpen: false, id: '' },
    showUpdatedFields: null,
    discoveryInvestigationToolReportStatus: null
  },
  reducers: {
    returnToLastState(state) {
      state.rows = state.capturedState.rows;
      state.nextPageRows = state.capturedState.nextPageRows;
      state.nextPath = state.capturedState.nextPath;
      state.pendingNextPath = state.capturedState.pendingNextPath;
      state.search = state.capturedState.search;
      state.filters = state.capturedState.filters;
      state.initialPath = state.capturedState.initialPath;
      state.order = state.capturedState.order;
      state.fields = state.capturedState.fields;
      state.count = state.capturedState.count;
      state.capturedState = {};
    },
    captureState(state) {
      state.capturedState = {
        rows: state.rows,
        nextPageRows: state.nextPageRows,
        nextPath: state.nextPath,
        pendingNextPath: state.pendingNextPath,
        search: state.search,
        filters: state.filters,
        initialPath: state.initialPath,
        order: state.order,
        fields: state.fields,
        count: state.count
      };
    },
    removeParams(state) {
      state.filters = {};
      state.search = '';
      state.order = '';
      state.fields = [];
    },
    resetCount(state) {
      state.count = 0;
    },
    disableURLChange(state) {
      state.allowURLChange = false;
    },
    enableURLChange(state) {
      state.allowURLChange = true;
    },
    setQueryString(state, action) {
      state.queryString[action.payload.tab] = action.payload.qs;
    },
    resetQueryString(state) {
      state.queryString = {};
    },
    setClusterType(state, action) {
      state.clusterType = action.payload || '';
    },
    setClusterSort(state, action) {
      state.clusterSort = action.payload || '';
    },
    setClusterCount(state, action) {
      state.clusterCount = action.payload;
    },
    addNextPageRows(state) {
      state.rows = [...state.rows, ...state.nextPageRows];
      state.nextPageRows = [];
      state.nextPath = state.pendingNextPath;
    },
    inputSearch(state, action) {
      state.rows = [];
      state.nextPath = '';
      state.nextPageRows = [];
      state.search = action.payload;
    },
    setInitialPath(state, action) {
      state.initialPath = action.payload;
    },
    addFilter(state, action) {
      state.rows = [];
      state.nextPath = '';
      state.nextPageRows = [];
      const newFilters = { ...state.filters, ...action.payload };
      state.filters = newFilters;
    },
    setInvestigationRules(state, { payload }) {
      state.investigation_rules = payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    clearAllFilters(state) {
      state.filters = {};
      state.nextPageRows = [];
      state.nextPath = '';
    },
    setSelectFiltersHard(state, action) {
      state.selectFiltersHard = action.payload;
    },
    removeFilter(state, action) {
      const filterToRemove = action.payload;
      delete state.filters[filterToRemove];
    },
    sort(state, action) {
      state.rows = [];
      state.nextPath = '';
      state.nextPageRows = [];
      state.order = action.payload;
    },
    setFields(state, action) {
      // console.log('XXX: setFields SLICE', action.payload);
      state.fields = action.payload;
    },
    setAdditionalFields(state, action) {
      state.additionalFields = [...action.payload, 'id'];
    },
    addFields(state, action) {
      action.payload.forEach(item => {
        if (!state.fields.includes(item)) {
          if (!Array.isArray(state.fields)) {
            state.fields = state.fields.split(',');
          }
          state.fields.push(item);
        }
      });
    },
    removeFields(state, action) {
      action.payload.forEach((field: string) => {
        state.fields.splice(state.fields.indexOf(field), 1);
      });
    },
    removeAllRows(state) {
      state.search = '';
      state.filters = {};
      state.nextPageRows = [];
      state.nextPath = '';
      state.count = 0;
      state.rows = [];
      state.pendingNextPath = '';
      state.order = '';
      state.fields = [];
      state.additionalFields = ['id'];
      state.addFiltersGridRows = '';
    },
    removeRowsOnly(state) {
      state.rows = [];
    },
    updateRescan(state, action) {
      const rows = [...state.rows];
      action.payload.indexes.forEach((row: any) => {
        rows[row.index].rescan_status = action.payload.status;
        if (row.time) {
          rows[row.index].last_rescan_time = row.time;
        }
      });
      state.rows = rows;
    },
    updateRescanInClusters(state, action) {
      const clusters = { ...state.clusters };
      for (let cluster in clusters) {
        clusters[cluster].forEach((row: any) => {
          if (row.id === action.payload.id) {
            row.rescan_status = action.payload.status;
            if (row.time) {
              row.last_rescan_time = row.time;
            }
          }
        });
      }
      state.clusters = clusters;
    },
    updateRows(state, action) {
      const rows = [...state.rows];
      // action.payload = Common.setGridRowsClientIds(action.payload)
      action.payload.rows.forEach((row: any) => {
        const id = action.payload.identifier;
        let index = rows.findIndex(r => r[id] === row[id]);
        rows[index] = row;
      });
      state.rows = rows;
    },
    updateRowsKeys(state, { payload }) {
      const rows = [...state.rows];

      payload.rows.forEach((row: any) => {
        const id = payload.identifier;
        let index = rows.findIndex(r => r[id] === row[id]);
        rows[index] = { ...rows[index], ...payload.updatedKeys };
      });
      state.rows = rows;
    },
    removeRowsByIndex(state, action) {
      let rows = [...state.rows];
      rows = rows.filter((r, index) => {
        return !action.payload.includes(index);
      });
      state.rows = [];
      state.rows = rows;
    },
    setDeletePromptParams(state, action) {
      state.deletePromptParams = action.payload;
    },
    setConfirmationParams(state, action) {
      state.confirmationParams = action.payload;
    },
    setfiltersResetDefault(state, action) {
      state.filtersResetDefault = action.payload;
    },
    setIsRowMenuActive(state, action) {
      state.isRowMenuActive = action.payload;
    },
    setAddFiltersGridRows(state, action) {
      state.addFiltersGridRows = action.payload;
    },
    setShowAddCommentPopup(state, { payload }) {
      state.showAddCommentPopup = payload;
    },
    setShowAddCommentPopupDiscoveryPending(state, { payload }) {
      state.showAddCommentPopupDiscoveryPending = payload;
    },
    setDropDownWithAction(state, { payload }) {
      state.dropDownWithAction = {
        listLastStatusOption: !!payload.listLastStatusOption ? payload.listLastStatusOption : null,
        nextStatusOption: !!payload.nextStatusOption ? payload.nextStatusOption : null
      };
    },
    setDiscoveryVerdictDropdown(state, { payload }) {
      state.discoveryVerdictDropdown = {
        listLastStatusOption: !!payload.listLastStatusOption ? payload.listLastStatusOption : null,
        nextStatusOption: !!payload.nextStatusOption ? payload.nextStatusOption : null
      };
    },
    setShouldOpenPopsideCommentsTab(state, { payload }) {
      state.shouldOpenPopsideCommentsTab = { isOpen: payload.isOpen, id: payload.id };
    },
    updateCurrentState(state, { payload }) {
      const rows = [...state.rows];
      payload.indexes.forEach((row: any) => {
        rows[row.index].current_state = payload.currentState;
      });
      state.rows = rows;
      state.dropDownWithAction = {
        ...state.dropDownWithAction
      };
    },
    addRowsToCluster(state, { payload }) {
      state.clusters[payload.clusterId] = state.clusters[payload.clusterId] || [];
      state.clusters[payload.clusterId] = state.clusters[payload.clusterId].concat(payload.rows);
    },
    emptyClusterTable(state, { payload }) {
      delete state.clusters[payload];
    },
    resetNext(state) {
      state.nextPageRows = [];
      state.pendingNextPath = '';
    },
    setSupportMultiLineColumn(state, { payload }) {
      state.supportMultiLineColumn = payload;
    },
    setShowUpdatedFields(state, { payload }) {
      state.showUpdatedFields = payload;
    },
    setDiscoveryInvestigationToolReportStatus(state, { payload }) {
      state.discoveryInvestigationToolReportStatus = payload;
    },
    setDiscoveryInvestigationCommentIds(state, { payload }) {
      state.discoveryInvestigationCommentIds = payload;
    },
    resetShowUpdatedFields(state) {
      state.showUpdatedFields = null;
    }
  },
  extraReducers: {
    [getRows.pending]: state => {
      state.loading = true;
    },
    [getRows.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.nextPath = payload.next;
      state.rows = payload.results;
      state.count = payload.count;
      // console.log('XXX: fields: ', payload.updated_fields);
      // state.showUpdatedFields = !!payload.updated_fields ? payload.updated_fields.split(',') : null;
    },
    [getRows.rejected]: state => {
      state.loading = false;
    },
    [getNextPage.fulfilled]: (state, { payload }) => {
      state.pendingNextPath = payload.next;
      state.nextPageRows = payload.results;
    }
  }
});

const { actions, reducer } = gridSlice;
export const {
  addNextPageRows,
  captureState,
  returnToLastState,
  inputSearch,
  removeParams,
  setInitialPath,
  addFilter,
  setFilters,
  sort,
  setFields,
  addFields,
  removeFields,
  clearAllFilters,
  removeAllRows,
  removeFilter,
  updateRescan,
  updateRows,
  updateRowsKeys,
  removeRowsByIndex,
  setDeletePromptParams,
  setConfirmationParams,
  setfiltersResetDefault,
  setInvestigationRules,
  removeRowsOnly,
  setQueryString,
  resetQueryString,
  enableURLChange,
  disableURLChange,
  resetCount,
  setIsRowMenuActive,
  setAddFiltersGridRows,
  setSelectFiltersHard,
  setAdditionalFields,
  setShowAddCommentPopup,
  setShowAddCommentPopupDiscoveryPending,
  setDropDownWithAction,
  setDiscoveryVerdictDropdown,
  setShouldOpenPopsideCommentsTab,
  updateCommentsCount,
  updateCurrentState,
  setRevertActionItemStatus,
  setClusterType,
  setClusterSort,
  setClusterCount,
  addRowsToCluster,
  updateRescanInClusters,
  emptyClusterTable,
  resetNext,
  setSupportMultiLineColumn,
  setShowUpdatedFields,
  resetShowUpdatedFields,
  setDiscoveryInvestigationToolReportStatus,
  setDiscoveryInvestigationCommentIds
} = actions;
export default reducer;
