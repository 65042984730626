import { useState } from 'react';
import './ToggleButtons.scss';
import classNames from 'classnames';

interface IToggleButtons {
  options: [string, string];
  onToggle: (selectedOption: string) => void;
  isToggle?: boolean;
}

export function ToggleButtons(props: IToggleButtons) {
  const [toggled, setToggled] = useState<boolean>(!!props.isToggle);

  return (
    <div
      className="ToggleButtons"
      onClick={() => {
        props.onToggle(!toggled ? props.options[1] : props.options[0]);
        setToggled(!toggled);
      }}
    >
      {props.options.map((option: string, index) => {
        return (
          <span
            key={`${option}_${index}`}
            className={classNames({ chosen: (!toggled && index === 0) || (toggled && index === 1) })}
          >
            {option}
          </span>
        );
      })}
      <div className={classNames('selector', { toggled: toggled })}></div>
    </div>
  );
}
